import React, { useState, useMemo } from "react";
import styled, { css } from "styled-components";
import { InvoiceItem, Service, InvoiceData } from "../../types";
import ServiceSelector from "../Services/ServiceSelector";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { Close12, Move12, Checkmark8} from "../Icon";

// Update the safeNumber function
function safeNumber(value: number | null | undefined): number {
  if (value === null || value === undefined) return 0;
  return isNaN(value) ? 0 : value;
}

const DraggableInvoiceItemBox = styled.div<{ $isDragging: boolean }>`
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 32px 1fr;
  gap: 10px;
  align-items: center;
  background-color: transparent;
  padding: 8px 12px;
  border-radius: 8px;
  position: relative;
  font-variant-numeric: tabular-nums;

  ${({ $isDragging }) =>
    $isDragging &&
    css`
      z-index: 1000;
      position: relative;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
      background-color: #fff;
    `}

  &:hover {
    .hover-button {
      opacity: 1;
    }
  }
`;

const DragHandle = styled.div`
  cursor: move;
  display: flex;
  align-items: center;
  width: 28px;
  height: 28px;
  justify-content: center;
  position: absolute;
  left: -24px;
  opacity: 0;

  svg path {
      fill: rgba(0, 0, 0, 0.5);
    }

  &:hover {
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }

`;

const InvoiceItemHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 32px 1fr;
  gap: 10px;
  padding: 0 12px;
`;

const HeaderCell = styled.div<{ $align?: string }>`
  text-align: ${(props) => props.$align || "left"};
  font-size: 14px;
  font-weight: 600;
`;

const InvoiceItemField = styled.input`
  width: 100%;
  border: 1px solid transparent;
  padding: 4px 8px;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  background: transparent;
  font-family: inherit;
  color: inherit;
  font-variant-numeric: inherit;
  margin-left: -8px;

  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const InvoiceItemButton = styled.button`
  width: auto;
  position: absolute;
  right: -24px;
  opacity: 0;
  height: 28px;
  width: 28px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);

    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const CustomCheckbox = styled.div<{ $checked: boolean }>`
  width: 14px;
  height: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;

  svg path {
    fill: ${props => props.$checked ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.0)'};
  }

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
`;

interface InvoiceItemsProps {
  invoice: InvoiceData;
  services: Service[];
  handleServiceSelect: (service: Service, itemId: string) => void;
  handleDescriptionChange: (value: string, itemId: string) => void;
  updateItem: (
    itemId: string,
    field: keyof InvoiceItem,
    value: string | number | boolean
  ) => void;
  deleteItem: (itemId: string) => void;
  reorderItems?: (items: InvoiceItem[]) => void;
  isReadOnly?: boolean;
  onCreateNewService: () => void;
}

interface SortableItemProps {
  item: InvoiceItem;
  services: Service[];
  handleServiceSelect: (service: Service, itemId: string) => void;
  handleDescriptionChange: (value: string, itemId: string) => void;
  updateItem: (
    itemId: string,
    field: keyof InvoiceItem,
    value: string | number | boolean
  ) => void;
  deleteItem: (itemId: string) => void;
  isReadOnly?: boolean;
  onCreateNewService: () => void;
}

const SortableItem: React.FC<SortableItemProps> = ({
  item,
  services,
  handleServiceSelect,
  handleDescriptionChange,
  updateItem,
  deleteItem,
  isReadOnly,
  onCreateNewService,
}) => {
  const [localItem, setLocalItem] = useState(item);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id! });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleLocalUpdate = (
    field: keyof InvoiceItem,
    value: string | number | boolean
  ) => {
    if (isReadOnly) return;
    let safeValue: string | number | boolean;
    
    if (field === 'quantity' || field === 'price') {
      safeValue = value === '' ? 0 : safeNumber(value as number | null | undefined);
    } else {
      safeValue = value;
    }
    
    setLocalItem((prev) => ({ ...prev, [field]: safeValue }));
    updateItem(item.id!, field, safeValue);
  };

  const handleLocalServiceSelect = (service: Service) => {
    if (isReadOnly) return;
    const updatedItem = {
      ...localItem,
      description: service.name,
      price: service.price ?? 0,
      taxable: service.taxable ?? false,
    };
    setLocalItem(updatedItem);
    handleServiceSelect(service, item.id!);
    // Update all changed fields
    Object.entries(updatedItem).forEach(([key, value]) => {
      if (key !== 'id' && value !== item[key as keyof InvoiceItem]) {
        // Check if the value is of the correct type before updating
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
          updateItem(item.id!, key as keyof InvoiceItem, value);
        }
      }
    });
  };

  const itemTotal = (safeNumber(localItem.quantity) ?? 0) * (safeNumber(localItem.price) ?? 0);

  return (
    <DraggableInvoiceItemBox
      ref={setNodeRef}
      style={style}
      $isDragging={isDragging}
    >
      {!isReadOnly && (
        <DragHandle className="hover-button" {...attributes} {...listeners}>
          <span>
            <Move12 />
          </span>
        </DragHandle>
      )}
      <ServiceSelector
        services={services}
        onSelect={handleLocalServiceSelect}
        onInputChange={(value) => handleDescriptionChange(value, item.id)}
        initialValue={localItem.description || ""}
        onCreateNewService={onCreateNewService}
        disabled={isReadOnly}
      />
      <InvoiceItemField
        type="text"
        value={localItem.quantity === 0 ? '' : localItem.quantity}
        onChange={(e) =>
          handleLocalUpdate("quantity", e.target.value)
        }
        placeholder="0"
        readOnly={isReadOnly}
      />
      <InvoiceItemField
        type="text"
        value={localItem.price === 0 ? '' : localItem.price}
        onChange={(e) => handleLocalUpdate("price", e.target.value)}
        placeholder="0"
        readOnly={isReadOnly}
      />
      <div style={{ textAlign: "right" }}>
        {isReadOnly ? (
          <span>{localItem.taxable ? '✓' : '✗'}</span>
        ) : (
          <CustomCheckbox
            $checked={localItem.taxable ?? false}
            onClick={() => handleLocalUpdate("taxable", !localItem.taxable)}
          >
            <Checkmark8 />
          </CustomCheckbox>
        )}
      </div>
      <div style={{ textAlign: "right" }}>
        ${itemTotal.toFixed(2)}
      </div>
      {!isDragging && !isReadOnly && (
        <InvoiceItemButton
          className="hover-button"
          onClick={() => item.id && deleteItem(item.id)}
        >
          <Close12 />
        </InvoiceItemButton>
      )}
    </DraggableInvoiceItemBox>
  );
};

const InvoiceItems: React.FC<InvoiceItemsProps> = ({
  invoice,
  services,
  handleServiceSelect,
  handleDescriptionChange,
  updateItem,
  deleteItem,
  reorderItems,
  isReadOnly,
  onCreateNewService,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor)
  );

  const handleDragEnd = (event: DragEndEvent) => {
    if (!reorderItems || isReadOnly) return;

    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = invoice.items.findIndex((item) => item.id === active.id);
      const newIndex = invoice.items.findIndex((item) => item.id === over?.id);

      const newItems = [...invoice.items];
      const [reorderedItem] = newItems.splice(oldIndex, 1);
      newItems.splice(newIndex, 0, reorderedItem);

      // Reassign order values
      const updatedItems = newItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      reorderItems(updatedItems);
    }
  };

  const sortedItems = useMemo(() => {
    return [...invoice.items].sort((a, b) => a.order - b.order);
  }, [invoice.items]);

  const content = (
    <div>
      <InvoiceItemHeader>
        <HeaderCell>Description</HeaderCell>
        <HeaderCell>Quantity</HeaderCell>
        <HeaderCell>Unit Price</HeaderCell>
        <HeaderCell>Tax</HeaderCell>
        <HeaderCell $align="right">Amount</HeaderCell>
      </InvoiceItemHeader>
      {sortedItems.map((item) => (
        <SortableItem
          key={item.id}
          item={item}
          services={services}
          handleServiceSelect={handleServiceSelect}
          handleDescriptionChange={handleDescriptionChange}
          updateItem={updateItem}
          deleteItem={deleteItem}
          isReadOnly={isReadOnly}
          onCreateNewService={onCreateNewService}
        />
      ))}
    </div>
  );

  return reorderItems && !isReadOnly ? (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext
        items={invoice.items.map((item) => item.id!)}
        strategy={verticalListSortingStrategy}
      >
        {content}
      </SortableContext>
    </DndContext>
  ) : (
    content
  );
};

export default InvoiceItems;