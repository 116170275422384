import React, { useState, useEffect } from 'react';
import { useUser } from './hooks/useUser';
import { supabase } from './supabaseClient';
import styled, { keyframes } from 'styled-components';
import Button from './components/Button';
import PageSkeleton from './components/PageSkeleton';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Profile12 } from './components/Icon';
import Compressor from 'compressorjs';
import { useNavigate } from '@tanstack/react-router';

const PageWrapper = styled.div`
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }
  }
`;

const ProfilePicContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ProfilePic = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfilePicPlaceholder = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  font-weight: bold;
  color: #333;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const ShimmerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
`;

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const ShimmerEffect = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 8%, rgba(255, 255, 255, 0.5) 18%, rgba(255, 255, 255, 0.1) 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.5s infinite linear;
`;

const PageTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 32px 0;
  padding-bottom: 16px;
  color: #333;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const SignOutButton = styled(Button)`
  margin-top: 20px;
  background-color: #f44336;
  color: white;

  &:hover {
    background-color: #d32f2f;
  }
`;

const UserProfilePage: React.FC = () => {
  const { data: user, isLoading, error, refetch } = useUser();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      await refetch();
      if (user && user.profile) {
        setFullName(user.profile.full_name || '');
        setEmail(user.email || '');
        setAvatarUrl(user.profile.avatar_url || null);
      }
    };

    fetchUserData();
  }, [user, refetch]);

  const updateProfileMutation = useMutation({
    mutationFn: async (updatedProfile: { full_name: string, avatar_url?: string | null }) => {
      if (!user) throw new Error('No user found');

      const { data, error } = await supabase
        .from('profiles')
        .update({ 
          full_name: updatedProfile.full_name,
          avatar_url: updatedProfile.avatar_url
        })
        .eq('id', user.id)
        .select()
        .single();

      if (error) throw error;

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
  });

  const compressImage = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 800,
        maxHeight: 800,
        success(result) {
          resolve(result as File);
        },
        error(err) {
          console.error('Error compressing image:', err);
          reject(err);
        },
      });
    });
  };

  const generateUniqueId = () => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 8);
    return `${timestamp}-${randomString}`;
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        setIsUploading(true);
        const compressedFile = await compressImage(file);
        const fileExt = file.name.split('.').pop();
        const fileName = `${generateUniqueId()}.${fileExt}`;
        const filePath = `${user?.id}/${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from('avatars')
          .upload(filePath, compressedFile, {
            cacheControl: '3600',
            upsert: false
          });

        if (uploadError) {
          throw uploadError;
        }

        const { data } = supabase.storage
          .from('avatars')
          .getPublicUrl(filePath);

        const publicUrl = data.publicUrl;

        setAvatarUrl(publicUrl);
        
        // Immediately update the profile
        await updateProfileMutation.mutateAsync({ 
          full_name: fullName, 
          avatar_url: publicUrl
        });

        // Force a refresh of the user data
        await queryClient.invalidateQueries({ queryKey: ['user'] });
      } catch (error) {
        console.error('Error uploading avatar:', error);
        alert('Failed to upload avatar');
      } finally {
        setIsUploading(false);
      }
    }
  };

  const removeAvatar = async () => {
    try {
      await updateProfileMutation.mutateAsync({ 
        full_name: fullName, 
        avatar_url: null
      });

      setAvatarUrl(null);

      // Force a refresh of the user data
      await queryClient.invalidateQueries({ queryKey: ['user'] });

      alert('Avatar removed successfully');
    } catch (error) {
      console.error('Error removing avatar:', error);
      alert('Failed to remove avatar');
    }
  };

  const getInitial = (name: string) => {
    return name.charAt(0).toUpperCase();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updateProfileMutation.mutateAsync({ 
        full_name: fullName, 
        avatar_url: avatarUrl
      });
      await refetch(); // Refetch user data after update
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile');
    }
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      // Clear the React Query cache
      queryClient.clear();
      
      // Navigate to the home page or login page
      navigate({ to: '/' });
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Failed to sign out');
    }
  };

  if (isLoading) return <PageSkeleton title="User Profile" />;
  if (error) return <div>Error loading user profile: {error.message}</div>;

  return (
    <PageWrapper>
      <PageTitle>User Profile</PageTitle>
      <Form onSubmit={handleSubmit}>
        <ProfilePicContainer>
          {avatarUrl ? (
            <div style={{ position: 'relative' }}>
              <ProfilePic src={avatarUrl} alt="Profile" />
              {isUploading && (
                <ShimmerContainer>
                  <ShimmerEffect />
                </ShimmerContainer>
              )}
            </div>
          ) : (
            <ProfilePicPlaceholder>
              {fullName ? getInitial(fullName) : <Profile12 />}
            </ProfilePicPlaceholder>
          )}
          <ButtonGroup>
            <FileInput
              type="file"
              id="avatar"
              accept="image/*"
              onChange={handleFileChange}
              disabled={isUploading}
            />
            <FileInputLabel htmlFor="avatar">
              <Button as="span" buttonType="secondary" disabled={isUploading}>
                {avatarUrl ? 'Change Picture' : 'Upload Picture'}
              </Button>
            </FileInputLabel>
            {avatarUrl && (
              <Button type="button" onClick={removeAvatar} disabled={isUploading}>
                Remove Picture
              </Button>
            )}
          </ButtonGroup>
        </ProfilePicContainer>
        <InputField>
          <label htmlFor="fullName">Full Name</label>
          <input
            id="fullName"
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Full Name"
          />
        </InputField>
        <InputField>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            disabled
            placeholder="Email"
          />
        </InputField>
        <Button type="submit" disabled={updateProfileMutation.isPending}>
          {updateProfileMutation.isPending ? 'Updating...' : 'Update Profile'}
        </Button>
      </Form>
      <SignOutButton onClick={handleSignOut}>
        Sign Out
      </SignOutButton>
    </PageWrapper>
  );
};

export default UserProfilePage;