import styled from 'styled-components';
import Button from '../../Button';

export const TimeInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
`;

export const TimeInputField = styled.input`
  width: 3ch;
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 14px;
  font-family: inherit;
  text-align: center;
  font-variant-numeric: tabular-nums;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TimeInputSeparator = styled.span`
  font-size: 14px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px;
`;

export const LogTimeButton = styled(Button)`
  flex: 1;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 8px;
  gap: 8px;
`;

export const DeleteButton = styled(Button)`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0;
  background-color: transparent;
  &:hover {
    background-color: #b62927;
    path {
      fill: #FFFFFF;
    }
  }

  svg path {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
  }
`;