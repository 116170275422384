import React, { useState, useCallback } from "react";
import styled from "styled-components";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { useUserOrganizations } from "../../hooks/useUserOrganization";
import { useOrganization } from "../../hooks/useOrganization";
import { useQueryClient } from "@tanstack/react-query";
import { supabase } from "../../supabaseClient";
import { Link } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { ReactComponent as CheckmarkIcon } from "../../assets/svg/12px/Checkmark.svg";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../hooks/useAuth";
import { useCreateOrganization } from "../../hooks/useCreateOrganization";

const PopoverContainer = styled.div`
  z-index: 1000;
  position: relative;
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: top left;

`;

const OrganizationButton = styled.button`
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  padding: 0;
  text-align: left;
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  gap: 6px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const OrganizationLogo = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  object-fit: contain;
`;

const OrganizationInitial = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: rgba(0,0,0,0.1);
  color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;

const PopoverWrapper = styled.div`
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
`;

const OrganizationList = styled.ul<{ $transformOrigin: string }>`
  position: relative;
  transition: transform 0.1s ease, opacity 0.1s ease;
  z-index: 1000;
  margin: 0;
  list-style-type: none;
  overflow-y: auto;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  font-size: 14px;
`;

const OrganizationSelectorFooter = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  margin: 0;
  list-style-type: none;
  gap: 80px;
  font-size: 14px;
  justify-content: center; // Center the items
  border-radius: 0 0 12px 12px;
`;

const OrganizationListItem = styled.li<{ $isSelected: boolean; $isSignOut?: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(0,0,0,0.05)" : "transparent"};
  color: ${(props) => (props.$isSignOut ? "red" : "inherit")};
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
    background-color: ${(props) =>
      props.$isSelected ? "rgba(0,0,0,0.1)" : "rgba(0,0,0,0.05)"};
  }
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  width: 12px;
  height: 12px;
  margin-left: 8px;
`;

const FooterItem = styled(OrganizationListItem)`
  flex: 0 0 auto; // Don't grow or shrink, keep content width
  padding: 4px 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
`;

const OrganizationListItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PendingInvitationsLink = styled(Link)`
  display: block;
  padding: 8px 12px;
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    background-color: rgba(0, 123, 255, 0.1);
  }
`;

interface Organization {
  organization_id: string;
  organizations:
    | {
        name: string;
        logo_url?: string;
      }
    | { name: string; logo_url?: string }[];
}

// Add these new styled components for the skeleton loader
const SkeletonButton = styled.div`
  background: transparent;
  width: 180px;
  height: 17px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

const SkeletonInitial = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: #e0e0e0;
  margin-right: 6px;
`;

const SkeletonText = styled.div`
  height: 14px;
  width: 120px;
  background-color: #e0e0e0;
  border-radius: 4px;
`;

const OrganizationSelector: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { data: organizations, isLoading, error } = useUserOrganizations();
  const { data: currentOrganizationId, error: organizationError, isLoading: isOrganizationLoading } = useOrganization();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isChangingOrganization, setIsChangingOrganization] = useState(false);
  const { data: user } = useAuth();

  const createOrganization = useCreateOrganization();

  const handleCreateOrganization = () => {
    const name = prompt("Enter new organization name:");
    if (name) {
      createOrganization.mutate({ name }, {
        onSuccess: (newOrg) => {
          handleOrganizationChange(newOrg.id);
        },
        onError: (error) => {
          console.error("Error creating organization:", error);
          alert("Failed to create organization. Please try again.");
        }
      });
    }
  };

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setIsOpen(false);
    navigate({ to: "/" }); // Navigate to the root route after sign out
  };

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
      case "top-start":
      case "top-end":
        return "bottom start";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "top start";
      case "left":
      case "left-start":
      case "left-end":
        return "right start";
      case "right":
      case "right-start":
      case "right-end":
        return "left start";
      default:
        return "top left";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleOrganizationChange = async (organizationId: string) => {
    console.log('handleOrganizationChange called with organizationId:', organizationId);
    setIsChangingOrganization(true);
    try {
      console.log('Updating user data with new organization ID');
      const { data, error } = await supabase.auth.updateUser({
        data: { current_organization_id: organizationId },
      });
      
      if (error) {
        console.error('Error updating user data:', error);
        throw error;
      }
      
      console.log('User data updated successfully:', data);

      console.log('Invalidating queries');
      queryClient.invalidateQueries({ queryKey: ['organization'] });
      queryClient.invalidateQueries({ queryKey: ['userOrganizations'] });
      
      console.log('Closing organization selector');
      setIsOpen(false);
      
      console.log('Navigating to home page');
      navigate({ to: "/" });
      
      console.log('Organization change completed');
    } catch (error) {
      console.error("Error changing organization:", error);
    } finally {
      setIsChangingOrganization(false);
    }
  };

  const { data: organization } = useQuery({
    queryKey: ['organization', currentOrganizationId],
    queryFn: async () => {
      if (!currentOrganizationId) return null;
      const { data, error } = await supabase
        .from("organizations")
        .select("*")
        .eq("id", currentOrganizationId)
        .single();
      if (error) throw error;
      return data;
    },
    enabled: !!currentOrganizationId,
  });

  const { data: pendingInvitations } = useQuery({
    queryKey: ['pendingInvitations', user?.email],
    queryFn: async () => {
      if (!user?.email) return [];
      const { data, error } = await supabase
        .from('pending_invitations')
        .select('*')
        .eq('email', user.email);
      if (error) throw error;
      return data;
    },
    enabled: !!user?.email,
  });

  const handlePendingInvitationsClick = useCallback(() => {
    setIsOpen(false); // Close the popover
    navigate({ to: "/pending-invitations" });
  }, [navigate, setIsOpen]);

  if (isLoading || isOrganizationLoading) {
    return (
      <OrganizationButton as="div" style={{ pointerEvents: 'none' }}>
        <SkeletonButton>
          <SkeletonInitial />
          <SkeletonText />
        </SkeletonButton>
      </OrganizationButton>
    );
  }
  if (error) {
    console.error("Error fetching organizations:", error);
    return <div>Error loading organizations</div>;
  }
  if (organizationError) {
    console.error("Error fetching current organization:", organizationError);
    // If the error is due to no organization found, we can still show the selector
    if (organizationError.message === 'No organization found') {
      // Continue rendering the component
    } else {
      return <div>Error loading current organization</div>;
    }
  }

  if (!organizations || organizations.length === 0) {
    return (
      <div>
        No organizations found.{" "}
        <Link to="/create-organization">Create one</Link>
      </div>
    );
  }

  const selectedOrganization = organizations?.find(
    (org) => org.organization_id === currentOrganizationId
  ) as Organization | undefined;

  const getOrganizationName = (org: Organization | undefined) => {
    if (!org) return "Select Organization";
    const orgs = org.organizations;
    return (
      (Array.isArray(orgs) ? orgs[0]?.name : orgs.name) ||
      "Unnamed Organization"
    );
  };

  const getOrganizationInitial = (name: string) => {
    return name.charAt(0).toUpperCase();
  };

  return (
    <>
      <OrganizationButton ref={refs.setReference} {...getReferenceProps()} disabled={isChangingOrganization}>
        {organization?.logo_url ? (
          <OrganizationLogo src={organization.logo_url} alt="Organization Logo" />
        ) : (
          <OrganizationInitial>
            {getOrganizationInitial(getOrganizationName(selectedOrganization))}
          </OrganizationInitial>
        )}
        {getOrganizationName(selectedOrganization)}
      </OrganizationButton>
      <FloatingPortal>
        {isMounted && (
          <PopoverContainer
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <PopoverContent style={styles} $transformOrigin={transformOrigin}>
              <PopoverWrapper>
                <OrganizationList $transformOrigin={transformOrigin}>
                  {organizations?.map((org: Organization) => {
                    const orgName = Array.isArray(org.organizations)
                      ? org.organizations[0]?.name
                      : org.organizations.name;
                    const logoUrl = Array.isArray(org.organizations)
                      ? org.organizations[0]?.logo_url
                      : org.organizations.logo_url;
                    return (
                      <OrganizationListItem
                        key={org.organization_id}
                        onClick={() =>
                          handleOrganizationChange(org.organization_id)
                        }
                        $isSelected={org.organization_id === currentOrganizationId}
                      >
                        <OrganizationListItemContent>
                          {logoUrl ? (
                            <OrganizationLogo src={logoUrl} alt={`${orgName} Logo`} />
                          ) : (
                            <OrganizationInitial>
                              {getOrganizationInitial(orgName || "Unnamed Organization")}
                            </OrganizationInitial>
                          )}
                          {orgName || "Unnamed Organization"}
                        </OrganizationListItemContent>
                        {org.organization_id === currentOrganizationId && (
                          <StyledCheckmarkIcon />
                        )}
                      </OrganizationListItem>
                    );
                  })}
                </OrganizationList>
                {pendingInvitations && pendingInvitations.length > 0 && (
                  <PendingInvitationsLink 
                    to="/pending-invitations" 
                    onClick={handlePendingInvitationsClick}
                  >
                    You have {pendingInvitations.length} pending invitation{pendingInvitations.length > 1 ? 's' : ''}
                  </PendingInvitationsLink>
                )}
                <OrganizationSelectorFooter>
                  <FooterItem
                    onClick={handleCreateOrganization}
                    $isSelected={false}
                  >
                    New Organization
                  </FooterItem>
                  <FooterItem
                    onClick={handleSignOut}
                    $isSelected={false}
                    $isSignOut={true}
                  >
                    Sign Out
                  </FooterItem>
                </OrganizationSelectorFooter>
              </PopoverWrapper>
            </PopoverContent>
          </PopoverContainer>
        )}
      </FloatingPortal>
    </>
  );
};

export default OrganizationSelector;