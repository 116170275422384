import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import EntityPicker from './EntityPicker';

const TabsContainer = styled.div`
  display: flex;
  border-bottom: 1.5px solid rgba(0,0,0,0.1);
  padding: 4px 32px 0 40px;
  position: relative;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #F8F7F6;

  @media (max-width: 768px) {
    padding: 8px 40px 8px;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const Tab = styled.button<{ $active: boolean }>`
  padding: 16px 0;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.$active ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)'};
  font-family: inherit;
  transition: color 0.1s ease-in-out;
  user-select: none;

  &:hover {
    color: rgba(0,0,0,0.8);
  }
`;

const TabCount = styled.span<{ $active: boolean }>`
  margin-left: 8px;
  font-size: 12px;
  color: ${props => props.$active ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)'};
  font-weight: 400;
  font-feature-settings: 'tnum';

  ${Tab}:hover & {
    color: rgba(0,0,0,0.8);
  }
`;

const AnimatedBorder = styled(motion.div)`
  position: absolute;
  bottom: -1.5px;
  left: 40px;
  height: 1.5px;
  background-color: #000;
`;

const TabLabel = styled.span`
  display: flex;
  align-items: center;
`;

const SkeletonTab = styled.div`
  width: 64px;
  height: 17px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;

  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

const SkeletonTabsWrapper = styled(TabsWrapper)`
  padding: 16px 0px; // To match the regular tabs padding
  margin-top: 0px;
`;

const SkeletonTabWithCount = styled.div`
  display: flex;
  align-items: center;
`;

const SkeletonBorder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.5px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const SkeletonTabs: React.FC = () => {
  return (
    <TabsContainer>
      <SkeletonTabsWrapper>
        {[1, 2].map((_, index) => (
          <SkeletonTabWithCount key={index}>
            <SkeletonTab />
          </SkeletonTabWithCount>
        ))}
      </SkeletonTabsWrapper>
      <SkeletonBorder />
    </TabsContainer>
  );
};

interface TabsProps {
  tabs: { id: string; label: string; count?: number }[];
  activeTab: string;
  onTabChange: (tabId: string) => void;
  actionButtons?: React.ReactNode;
  isLoading?: boolean; // New prop for loading state
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onTabChange, actionButtons, isLoading }) => {
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [tabWidths, setTabWidths] = useState<number[]>([]);
  const [tabOffsets, setTabOffsets] = useState<number[]>([]);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const widths = tabRefs.current.map(ref => ref?.offsetWidth || 0);
    setTabWidths(widths);

    let accumulatedOffset = 0;
    const offsets = tabRefs.current.map(ref => {
      const offset = accumulatedOffset;
      accumulatedOffset += (ref?.offsetWidth || 0) + 20; // 24px is the gap
      return offset;
    });
    setTabOffsets(offsets);
  }, [tabs]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const activeIndex = tabs.findIndex(tab => tab.id === activeTab);

  if (isLoading) {
    return <SkeletonTabs />;
  }

  if (isMobile) {
    return (
      <TabsContainer>
        <EntityPicker
          selectedId={activeTab}
          onChange={(id) => id && onTabChange(id)}
          entities={tabs.map(tab => ({ id: tab.id, name: tab.label }))}
          label="Tab"
          placement="bottom-start"
          noBorderRadius
        />
        {actionButtons && <ActionButtons>{actionButtons}</ActionButtons>}
      </TabsContainer>
    );
  }

  return (
    <TabsContainer>
      <TabsWrapper>
        {tabs.map((tab, index) => (
          <Tab
            key={tab.id}
            $active={activeTab === tab.id}
            onClick={() => onTabChange(tab.id)}
            ref={el => tabRefs.current[index] = el}
          >
            <TabLabel>
              {tab.label}
              {tab.id !== 'all' && tab.count !== undefined && (
                <TabCount $active={activeTab === tab.id}>{tab.count}</TabCount>
              )}
            </TabLabel>
          </Tab>
        ))}
        <AnimatedBorder
          initial={false}
          animate={{
            width: tabWidths[activeIndex],
            x: tabOffsets[activeIndex],
          }}
          transition={{ type: 'spring', stiffness: 500, damping: 30 }}
        />
      </TabsWrapper>
      {actionButtons && <ActionButtons>{actionButtons}</ActionButtons>}
    </TabsContainer>
  );
};

export default Tabs;