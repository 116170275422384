import React from 'react';
import styled from 'styled-components';
import PageHeader from './PageHeader';

const PageWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

interface PageSkeletonProps {
  parentPath?: string;
  parentName?: string;
  title: string;
}

const PageSkeleton: React.FC<PageSkeletonProps> = ({ parentPath, parentName, title }) => (
  <PageWrapper>
    <PageHeader
      parentPath={parentPath}
      parentName={parentName}
      title={title}
    />
  </PageWrapper>
);

export default PageSkeleton;