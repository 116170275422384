import Button from '../Button';
import { useInviteUser } from '../../hooks/useInviteUser';
import styled from 'styled-components';
import { useState } from 'react';
import { PendingInvitation } from '../../types';

const Form = styled.form`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  padding: 8px 16px;
`;

const Input = styled.input`
  flex: 1;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: rgba(0, 0, 0, 0.5);
  }
`;

interface InviteUserFormProps {
  existingEmails: string[];
  onInviteSent: (newInvitation: PendingInvitation) => void;
}

const InviteUserForm: React.FC<InviteUserFormProps> = ({ existingEmails, onInviteSent }) => {
  const [email, setEmail] = useState('');
  const inviteUser = useInviteUser();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (existingEmails.includes(email.toLowerCase())) {
      alert('This user is already a member or has a pending invitation.');
      return;
    }
    inviteUser.mutate(email, {
      onSuccess: (data) => {
        setEmail('');
        alert('Invitation sent successfully!');
        onInviteSent(data);
      },
      onError: (error) => {
        alert(`Error sending invitation: ${error.message}`);
      },
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email to invite"
        required
      />
      <StyledButton type="submit" disabled={inviteUser.isPending}>
        {inviteUser.isPending ? 'Sending...' : 'Send Invite'}
      </StyledButton>
    </Form>
  );
};

export default InviteUserForm;