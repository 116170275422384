import React, { useCallback, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { InvoiceData, Project, Client } from "../types";
import { debounce } from "lodash";
import ColorPickerWithPopover from "./ColorPicker";
import FontPicker from "./FontPicker";
import HeaderStyleSelector from "./Invoices/HeaderStyleSelector";
import { motion } from "framer-motion";
import Button from "./Button";
import EntityPicker from "./EntityPicker";
import DatePicker from "./DatePicker";
import { addDays } from "date-fns";
import {
  Calendar12,
  Client12,
  DueDate12,
  Project12,
  Money12,
  Percent12,
  Number12,
} from "./Icon";

interface SidebarRightContainerProps {
  $isVisible: boolean;
}

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
  gap: 16px;
  position: relative;
`;

const Tab = styled.button<{ $active: boolean }>`
  padding: 12px 0;
  border: none;
  background-color: transparent;
  color: ${({ $active }) => ($active ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.5)")};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: inherit;
  transition: color 0.1s ease-in-out;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }
`;

const AnimatedBorder = styled(motion.div)`
  position: absolute;
  bottom: -1.5px;
  left: 0;
  height: 1.5px;
  background-color: #000;
`;

const SidebarWrapper = styled.div<SidebarRightContainerProps>`
  position: relative;
  height: calc(100vh - 45px);
  overflow-x: hidden;
  width: ${({ $isVisible }) => ($isVisible ? "266px" : "0")};
  flex-shrink: 0;
  transition: width 0.2s ease-in-out;
  position: sticky;
  top: 60px;
  padding-left: 8px;
`;

const SidebarRightContainer = styled.div<SidebarRightContainerProps>`
  position: relative;
  right: ${({ $isVisible }) => ($isVisible ? "0" : "-266px")};
  min-width: 266px;
  width: 100%;
  padding: 20px 40px 20px 0;
  box-sizing: border-box;
  transition: right 0.2s ease-in-out;
  z-index: 0;
`;

const ContentWrapper = styled.div<{ $isVisible: boolean }>`
  opacity: ${({ $isVisible }) => ($isVisible ? "1" : "0")};
  transition: opacity 0.2s ease-in-out;
`;

const TaxRateContainer = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 0 0 0 8px;
`;

const TaxRateInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: -8px;
  width: 24px;
  padding: 4px 8px 4px 28px;
  font-size: 14px;
  height: 20px;
  border-radius: 8px;
  text-align: left;
  border: 1px solid transparent;
  outline: none;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const TaxRateIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
`;

const GroupHeader = styled.h3`
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
  padding: 0 0 8px;
  margin: 32px 0 12px 0;
  font-weight: 500;
  user-select: none;
`;

const PickerGroup = styled.div`
  display: flex;
  gap: 24px;
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 4px;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const DetailValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  width: 100%; // Ensure it takes full width
`;

const DetailRow = styled.div`
  margin: 0 0 0 -8px;
`;

const DetailDate = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

const InvoiceNumberContainer = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 4px 0 0 8px;
`;

const InvoiceNumberInput = styled.input`
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  margin-left: -8px;
  width: 100%;
  padding: 4px 8px 4px 28px;
  font-size: 14px;
  height: 20px;
  border-radius: 8px;
  text-align: left;
  border: 1px solid transparent;
  outline: none;
  &:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const InvoiceNumberIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
`;

const InvoiceNumberWrapper = styled.div``;

const GroupItem = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px 0 12px 0;
  margin: 12px 0;
`;

const HeaderStyleWrapper = styled.div`
  margin-top: 24px;
`;

interface SidebarRightProps {
  invoiceTemplate: "simple" | "detailed" | "modern";
  headerColor: string;
  headerTextColor: string;
  backgroundColor: string;
  bodyTextColor: string;
  taxRate: number;
  font: string;
  currency: string;
  isVisible: boolean;
  updateInvoice: (
    field: keyof InvoiceData,
    value: InvoiceData[keyof InvoiceData]
  ) => void;
  resetToDefaultSettings: (organizationId: string) => void;
  organizationId: string;
  projects: Project[];
  selectedProjectId: string | null;
  onSelectProject: (projectId: string | null) => void; // Updated type
  clients: Client[];
  selectedClientId: string | null;
  onSelectClient: (clientId: string | null) => void;
  invoiceDate: string | null;
  dueDate: string | null;
  dueDays: number | null;
  emailTrackingData?: {
    open_count: number;
    open_history: string[];
  } | null;
  invoiceNumber: string;
  onInvoiceNumberChange: (value: string) => void;
}

const SidebarRight: React.FC<SidebarRightProps> = ({
  invoiceTemplate,
  font,
  headerColor,
  headerTextColor,
  backgroundColor,
  bodyTextColor,
  taxRate,
  currency,
  updateInvoice,
  isVisible,
  projects,
  selectedProjectId,
  onSelectProject,
  organizationId,
  resetToDefaultSettings,
  clients,
  selectedClientId,
  onSelectClient,
  invoiceDate,
  dueDate,
  dueDays,
  invoiceNumber,
  onInvoiceNumberChange,
}) => {
  const [localHeaderColor, setLocalHeaderColor] = useState(headerColor);
  const [localHeaderTextColor, setLocalHeaderTextColor] =
    useState(headerTextColor);
  const [localBodyTextColor, setLocalBodyTextColor] = useState(bodyTextColor);
  const [localBackgroundColor, setLocalBackgroundColor] =
    useState(backgroundColor);
  const [isContentVisible, setIsContentVisible] = useState(isVisible);
  const [activeTab, setActiveTab] = useState<"details" | "style">("details");

  const fonts = [
    "Inter",
    "Montserrat",
    "IBM Plex Mono",
    "Merriweather",
    "Playfair Display",
    "Lora",
    "Barlow",
    "PT Serif",
    "Noto Serif",
    "Space Grotesk",
  ];

  const currencies = [
    { id: "USD", name: "US Dollar ($)" },
    { id: "EUR", name: "Euro (€)" },
    { id: "GBP", name: "British Pound (£)" },
    { id: "CAD", name: "Canadian Dollar (C$)" },
    { id: "AUD", name: "Australian Dollar (A$)" },
    { id: "JPY", name: "Japanese Yen (¥)" },
    { id: "CNY", name: "Chinese Yuan (¥)" },
    { id: "INR", name: "Indian Rupee (₹)" },
  ];

  useEffect(() => {
    setLocalHeaderColor(headerColor);
    setLocalHeaderTextColor(headerTextColor);
    setLocalBackgroundColor(backgroundColor);
  }, [headerColor, headerTextColor, backgroundColor]);

  const handleCurrencyChange = useCallback(
    (selectedCurrencyId: string | null) => {
      if (selectedCurrencyId) {
        updateInvoice("currency", selectedCurrencyId);
      }
    },
    [updateInvoice]
  );

  const debouncedUpdateHeaderColor = useMemo(
    () =>
      debounce((color: string) => {
        updateInvoice("header_color", color);
      }, 300),
    [updateInvoice]
  );

  const debouncedUpdateHeaderTextColor = useMemo(
    () =>
      debounce((color: string) => {
        updateInvoice("header_text_color", color);
      }, 300),
    [updateInvoice]
  );

  const debouncedUpdateBackgroundColor = useMemo(
    () =>
      debounce((color: string) => {
        updateInvoice("background_color", color);
      }, 300),
    [updateInvoice]
  );

  const handleHeaderColorChange = useCallback(
    (color: string) => {
      setLocalHeaderColor(color);
      debouncedUpdateHeaderColor(color);
    },
    [debouncedUpdateHeaderColor]
  );

  const handleHeaderTextColorChange = useCallback(
    (color: string) => {
      setLocalHeaderTextColor(color);
      debouncedUpdateHeaderTextColor(color);
    },
    [debouncedUpdateHeaderTextColor]
  );

  useEffect(() => {
    setLocalBodyTextColor(bodyTextColor);
  }, [bodyTextColor]);

  const debouncedUpdateBodyTextColor = useMemo(
    () =>
      debounce((color: string) => {
        updateInvoice("body_text_color", color);
      }, 300),
    [updateInvoice]
  );

  const handleBodyTextColorChange = useCallback(
    (color: string) => {
      setLocalBodyTextColor(color);
      debouncedUpdateBodyTextColor(color);
    },
    [debouncedUpdateBodyTextColor]
  );

  const handleBackgroundColorChange = useCallback(
    (color: string) => {
      setLocalBackgroundColor(color);
      debouncedUpdateBackgroundColor(color);
    },
    [debouncedUpdateBackgroundColor]
  );

  const handleTemplateChange = useCallback(
    (newTemplate: string) => {
      // Optimistically update the local state
      updateInvoice("invoice_template", newTemplate);
    },
    [updateInvoice]
  );

  useEffect(() => {
    if (isVisible) {
      setIsContentVisible(true);
    } else {
      const timer = setTimeout(() => setIsContentVisible(false), 200); // 200ms matches the transition duration
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  useEffect(() => {
    console.log("Selected Project ID:", selectedProjectId);
  }, [selectedProjectId]);

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice("invoice_date", date ? date.toISOString() : null);
    if (date && dueDays != null) {
      const newDueDate = addDays(date, dueDays);
      updateInvoice("due_date", newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice("due_days", days);
    if (invoiceDate) {
      const newDueDate = addDays(new Date(invoiceDate), days);
      updateInvoice("due_date", newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoiceDate) {
      const invoiceDateObj = new Date(invoiceDate);
      const diffDays = Math.round(
        (date.getTime() - invoiceDateObj.getTime()) / (1000 * 60 * 60 * 24)
      );
      handleDueDaysChange(diffDays);
      updateInvoice("due_date", date.toISOString());
    }
  };

  const handlePopoverOpenChange = (isOpen: boolean) => {
    // You can add any logic here if needed when the popover opens or closes
    console.log("Popover open state changed:", isOpen);
  };

  const filteredClients = useMemo(() => {
    return clients.filter(
      (client) => client.organization_id === organizationId
    );
  }, [clients, organizationId]);

  const filteredProjects = useMemo(() => {
    return projects.filter(
      (project) => project.organization_id === organizationId
    );
  }, [projects, organizationId]);

  const handleInvoiceNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onInvoiceNumberChange(e.target.value);
  };

  return (
    <SidebarWrapper $isVisible={isVisible}>
      <SidebarRightContainer $isVisible={isVisible}>
        <ContentWrapper $isVisible={isContentVisible}>
          {isContentVisible && (
            <>
              <TabContainer>
                <Tab
                  $active={activeTab === "details"}
                  onClick={() => setActiveTab("details")}
                >
                  Details
                </Tab>
                <Tab
                  $active={activeTab === "style"}
                  onClick={() => setActiveTab("style")}
                >
                  Style
                </Tab>
                <AnimatedBorder
                  initial={false}
                  animate={{
                    width: activeTab === "details" ? "48px" : "34px",
                    x: activeTab === "details" ? 0 : "64px",
                  }}
                  transition={{ type: "spring", stiffness: 500, damping: 30 }}
                />
              </TabContainer>

              {activeTab === "details" && (
                <>
                  <GroupItem>
                    <DetailRow>
                      <DetailValue>
                        <EntityPicker
                          selectedId={selectedClientId}
                          onChange={onSelectClient}
                          entities={filteredClients.map((client) => ({
                            id: client.id,
                            name:
                              client.full_name ||
                              client.email ||
                              "Unknown Client",
                          }))}
                          label="Client"
                          allowUnassigned={true}
                          placement="left-start"
                          icon={<Client12 />}
                        />
                      </DetailValue>
                      </DetailRow>
                      <DetailRow>
                      <DetailValue>
                        <EntityPicker
                          selectedId={selectedProjectId}
                          onChange={onSelectProject}
                          entities={filteredProjects}
                          label="Project"
                          allowUnassigned={true}
                          placement="left-start"
                          icon={<Project12 />}
                        />
                      </DetailValue>
                    </DetailRow>
                  </GroupItem>
                  <GroupItem>
                    <InvoiceNumberWrapper>
                      <DetailRow>
                        <DetailLabel>Invoice Number</DetailLabel>
                        <DetailValue>
                          <InvoiceNumberContainer>
                            <InvoiceNumberIcon>
                              <Number12 />
                            </InvoiceNumberIcon>
                            <InvoiceNumberInput
                              type="text"
                              value={invoiceNumber}
                              onChange={handleInvoiceNumberChange}
                              placeholder="Enter Invoice Number"
                            />
                          </InvoiceNumberContainer>
                        </DetailValue>
                      </DetailRow>
                    </InvoiceNumberWrapper>
                  </GroupItem>
                  <GroupItem>
                    <DetailDate>
                      <DetailRow>
                        <DetailLabel>Invoice Date</DetailLabel>
                        <DetailValue>
                          <DatePicker
                            id="invoice-date"
                            label="Invoice Date"
                            selectedDate={
                              invoiceDate ? new Date(invoiceDate) : null
                            }
                            onChange={handleInvoiceDateChange}
                            isInvoiceDate={true}
                            placeholder="Select Invoice Date"
                            placement="left-start"
                            onOpenChange={handlePopoverOpenChange}
                            icon={<Calendar12 />}
                          />
                        </DetailValue>
                      </DetailRow>
                      <DetailRow>
                        <DetailLabel>Due Date</DetailLabel>
                        <DetailValue>
                          <DatePicker
                            id="due-date"
                            label="Due Date"
                            selectedDate={dueDate ? new Date(dueDate) : null}
                            onChange={handleDueDateChange}
                            onSetDueDate={handleDueDaysChange}
                            dueDays={dueDays}
                            invoiceDate={
                              invoiceDate ? new Date(invoiceDate) : null
                            }
                            placeholder="Select Due Date"
                            placement="left-start"
                            onOpenChange={handlePopoverOpenChange}
                            icon={<DueDate12 />}
                          />
                        </DetailValue>
                      </DetailRow>
                    </DetailDate>
                  </GroupItem>
                  <GroupItem>
                    <DetailRow>
                      <DetailLabel>Tax Rate</DetailLabel>
                      <TaxRateContainer>
                        <TaxRateIcon>
                          <Percent12 />
                        </TaxRateIcon>
                        <TaxRateInput
                          type="text"
                          value={taxRate === 0 ? "" : taxRate}
                          placeholder="0"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (inputValue === "") {
                              updateInvoice("tax_rate", 0);
                            } else {
                              const value = Math.min(
                                100,
                                parseFloat(inputValue)
                              );
                              if (!isNaN(value)) {
                                updateInvoice("tax_rate", value);
                              }
                            }
                          }}
                          onBlur={(e) => {
                            const value = parseFloat(e.target.value);
                            updateInvoice("tax_rate", isNaN(value) ? 0 : value);
                          }}
                        />
                      </TaxRateContainer>
                    </DetailRow>
                  </GroupItem>
                  <GroupItem>
                    <DetailRow>
                      <DetailLabel>Currency</DetailLabel>
                      <EntityPicker
                        selectedId={currency}
                        onChange={handleCurrencyChange}
                        entities={currencies}
                        label="Currency"
                        allowUnassigned={false}
                        placement="left-start"
                        icon={<Money12 />}
                      />
                    </DetailRow>
                  </GroupItem>
                </>
              )}

              {activeTab === "style" && (
                <>
                <HeaderStyleWrapper>
                  <HeaderStyleSelector
                    selectedTemplate={invoiceTemplate}
                    onTemplateChange={handleTemplateChange}
                  />
                  </HeaderStyleWrapper>
                  <GroupHeader>Header</GroupHeader>
                  <PickerGroup>
                    <ColorPickerWithPopover
                      color={localHeaderColor}
                      onChange={handleHeaderColorChange}
                      label="Background"
                      id="default-header-color"
                    />
                    <ColorPickerWithPopover
                      color={localHeaderTextColor}
                      onChange={handleHeaderTextColorChange}
                      label="Text"
                      id="default-header-text-color"
                    />
                  </PickerGroup>
                  <GroupHeader>Body</GroupHeader>
                  <PickerGroup>
                    <ColorPickerWithPopover
                      color={localBackgroundColor}
                      onChange={handleBackgroundColorChange}
                      label="Background"
                      id="default-background-color"
                    />
                    <ColorPickerWithPopover
                      color={localBodyTextColor}
                      onChange={handleBodyTextColorChange}
                      label="Text"
                      id="default-body-text-color"
                    />
                  </PickerGroup>
                  <GroupHeader>Font</GroupHeader>
                  <FontPicker
                    font={font}
                    onChange={(selectedFont) =>
                      updateInvoice("font", selectedFont)
                    }
                    label="Font"
                    id="font-picker"
                    fonts={fonts}
                  />
                  <GroupHeader>Reset</GroupHeader>
                  <Button
                    onClick={() => {
                      resetToDefaultSettings(organizationId);
                    }}
                  >
                    Reset to Brand Default
                  </Button>
                </>
              )}
            </>
          )}
        </ContentWrapper>
      </SidebarRightContainer>
    </SidebarWrapper>
  );
};

export default SidebarRight;
