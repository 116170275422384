import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Client } from '../types';
import { useOrganization } from './useOrganization';

export const useClients = () => {
  const { data: organizationId } = useOrganization();
  const queryClient = useQueryClient();

  const clientsQuery = useQuery<Client[], Error>({
    queryKey: ['clients', organizationId],
    queryFn: async () => {
      if (!organizationId) return [];
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .eq('organization_id', organizationId)
        .order('full_name', { ascending: true });
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });

  const createClientMutation = useMutation<Client, Error, Omit<Client, 'id' | 'organization_id'>>({
    mutationFn: async (newClient) => {
      if (!organizationId) throw new Error('Organization ID is not available');
      const { data, error } = await supabase
        .from('clients')
        .insert({ ...newClient, organization_id: organizationId })
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clients', organizationId] });
    },
  });

  return {
    ...clientsQuery,
    createClient: createClientMutation,
  };
};