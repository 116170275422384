import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InvoiceTemplatesProps } from './types';
import { ClientSelector } from '../components/Clients/ClientSelector';
import DatePicker from '../components/DatePicker';
import { addDays } from 'date-fns';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import AnimatedAmount from '../components/AnimatedAmount';

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div.attrs<{ $headerColor: string }>(props => ({
  style: {
    backgroundColor: props.$headerColor || '#f8f8f8',
  },
}))`
  padding: 60px;
`;

const HeaderContent = styled.div<{ $headerTextColor: string }>`
  color: ${props => props.$headerTextColor || 'inherit'};
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const InvoiceTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
`;

const AmountDue = styled.div`
  text-align: right;
`;

const AmountDueLabel = styled.div`
  font-size: 0.9rem;
  opacity: 0.66;
`;

const AmountDueValue = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
  text-align: right;
`;

const BilledToSection = styled.div`
  margin-top: 1rem;
`;

const BilledToLabel = styled.h3`
  font-size: 0.9rem;
  opacity: 0.66;
  font-weight: normal;
  margin: 0 0 0.5rem 0;
`;

const DetailRow = styled.div`
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DetailLabel = styled.span`
  opacity: 0.66;
  margin-right: 1rem;
`;

const Input = styled.input`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  margin: 0;
  text-align: right;
  color: inherit;
  &::placeholder {
    color: inherit;
  }
`;

const Logo = styled.img`
  max-width: 200px;
  max-height: 100px;
  object-fit: contain;
  margin-bottom: 20px;
`;

export const ModernHeader: React.FC<InvoiceTemplatesProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
  }, [invoice.invoice_number]);

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const totals = calculateInvoiceTotals(invoice as InvoiceData);

  return (
    <FontWrapper $font={invoice.font || 'Arial'}>
      <HeaderContainer $headerColor={invoice.header_color}>
        <HeaderContent $headerTextColor={invoice.header_text_color}>
          <TopSection>
            {invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
            <InvoiceTitle>INVOICE</InvoiceTitle>
            <AmountDue>
              <AmountDueLabel>Amount Due ({invoice.currency})</AmountDueLabel>
              <AmountDueValue>
                <AnimatedAmount value={totals.amountDue} />
              </AmountDueValue>
            </AmountDue>
          </TopSection>
          <BottomSection>
            <LeftColumn>
              <BilledToSection>
                <BilledToLabel>Billed to</BilledToLabel>
                <ClientSelectorComponent
                  clients={clients}
                  selectedClientId={invoice.client_id || null}
                  onSelectClient={(clientId) => updateInvoice('client_id', clientId)}
                  disabled={isReadOnly}
                  onCreateNewClient={onCreateNewClient}
                />
              </BilledToSection>
            </LeftColumn>
            <RightColumn>
              <DetailRow>
                <DetailLabel>Invoice #</DetailLabel>
                <Input
                  type="text"
                  value={localInvoiceNumber}
                  onChange={handleInvoiceNumberChange}
                  placeholder="N/A"
                  readOnly={isReadOnly}
                />
              </DetailRow>
              <DetailRow>
                <DetailLabel>Invoice Date</DetailLabel>
                <DatePicker
                  id="invoice-date"
                  label="Invoice Date"
                  selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                  onChange={handleInvoiceDateChange}
                  disabled={isReadOnly}
                  isInvoiceDate={true}
                />
              </DetailRow>
              <DetailRow>
                <DetailLabel>Due Date</DetailLabel>
                <DatePicker
                  id="due-date"
                  label="Due Date"
                  selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
                  onChange={handleDueDateChange}
                  disabled={isReadOnly}
                  onSetDueDate={handleDueDaysChange}
                  dueDays={invoice.due_days ?? 0}
                  invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                />
              </DetailRow>
            </RightColumn>
          </BottomSection>
        </HeaderContent>
      </HeaderContainer>
    </FontWrapper>
  );
};