import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from '@tanstack/react-router';
import { supabase } from './supabaseClient';
import { invoiceTemplates } from './templates/InvoiceTemplates';
import styled from 'styled-components';
import { Client, InvoiceData, Payment } from './types';
import InvoiceItems from './components/Invoices/InvoiceItems';
import InvoiceTotals from './components/Invoices/InvoiceTotals';
import AnimatedHeader from './components/AnimatedHeader';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import RightDrawerComponent from './RightDrawer';
import { calculateInvoiceTotals } from './utils/invoiceCalculations';
import Button from './components/Button';

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  justify-content: center;
  overflow-y: auto; // Changed from scroll to auto
  height: 100vh;
  background-color: #F5F4F1;


  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;

  }
`;

const InvoiceDetailsWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  padding: 80px 32px;
  box-sizing: border-box;
  position: sticky;
  top: 0;

  @media (max-width: 1000px) {
    max-width: 100%;
    position: static;
    padding: 32px;
    order: -1;
  }
`;

const InvoiceDetail = styled.div`
  margin-bottom: 16px;
`;

const DetailLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: rgba(0,0,0,0.5);
  margin-bottom: 4px;
`;

const DetailValue = styled.div`
  font-size: 16px;
  line-height: 24px;
`;

const InvoiceTitle = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
`;

const MainContent = styled.div<{ $backgroundColor: string; $bodyTextColor: string; $font: string }>`
  flex: 1;
  width: 100%;
  margin: 0 auto;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$bodyTextColor};
  font-family: ${(props) => props.$font};
  box-shadow: 0 4px 80px rgba(0, 0, 0, 0.1);

  @media (max-width: 1000px) {
  }
`;

const InvoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 32px;
  box-sizing: border-box;
  max-width: 960px;
  width: 100%;

  @media (max-width: 1000px) {
    padding: 32px 16px;
    width: 100%; // Ensure full width on mobile
  }
`;

const InvoiceDetailsContainer = styled.div`
  padding: 64px 64px 80px;
`;

const PaymentFormContainer = styled.div`
  padding: 20px;
`;

const InvoiceAmount = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const PaymentButton = styled(Button)<{ $isPaid: boolean }>`
  background-color: ${props => props.$isPaid ? '#4CAF50' : '#007bff'};
  padding: 8px 16px;
  text-align: center;
  justify-content: center;
  display: flex;
  border: none;
  border-radius: 8px;
  cursor: ${props => props.$isPaid ? 'default' : 'pointer'};
  font-size: 14px;
  margin-top: 24px;
  transition: background-color 0.3s ease;
  width: 100%;
  font-weight: 500;
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  transition: box-shadow 0.1s ease, transform 0.1s ease;
  color: rgba(0,0,0,0.8);

  &:hover {
    box-shadow: 0px 2px 1px hsla(0, 0%, 0%, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
    background-color: #FFFFFF;
    transform: translateY(-1px);
  }

  &:active {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 1px rgba(0, 0, 0, 0.05);
    transform: translateY(0) scale(0.99);
  }

  &:disabled {
    background-color: #4F846A;
    cursor: not-allowed;
    color: white;
    box-shadow: none;

    &:hover {
    transform: translateY(0);
  }
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const PaymentsList = styled.div`
  padding: 32px 32px 80px;
  width: 100%;
  max-width: 960px
`;

const PaymentItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const PaymentForm = ({ onClose, amount, invoiceId, organizationId }: { onClose: () => void; amount: number; invoiceId: string; organizationId: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [paymentElementReady, setPaymentElementReady] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements || !paymentElementReady) {
      return;
    }

    setProcessing(true);

    try {
      const { error: submitError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
        },
        redirect: 'if_required',
      });

      if (submitError) {
        setError(submitError.message ?? 'An unknown error occurred');
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        // Record the payment
        const response = await fetch('/api/record-payment', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            invoiceId,
            amount: paymentIntent.amount / 100, // Convert cents to dollars
            paymentDate: new Date().toISOString().split('T')[0], // Format as YYYY-MM-DD
            notes: 'Payment processed through Stripe',
            userId: null, // Set this if you have user authentication
            organizationId,
            paymentMethod: paymentIntent.payment_method_types[0]
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to record payment');
        }

        onClose(); // Call onClose after successful payment
      }
    } catch (err) {
      setError('An unexpected error occurred while processing your payment');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InvoiceAmount>Amount Due: ${amount.toFixed(2)}</InvoiceAmount>
      {stripe && elements ? (
        <>
          <PaymentElement 
            onReady={() => setPaymentElementReady(true)}
            onChange={(event) => {
              if (event.complete) {
                setError(null);
              } else if (event.empty) {
                setError('Please fill in your payment details');
              } else {
                setError(null);
              }
            }}
          />
          <PaymentButton 
            type="submit" 
            disabled={!stripe || !paymentElementReady || processing}
            $isPaid={false}  // Add this line
          >
            {processing ? 'Processing...' : `Pay $${amount.toFixed(2)}`}
          </PaymentButton>
        </>
      ) : (
        <div>Loading payment form...</div>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </form>
  );
};

const PublicInvoicePage: React.FC = () => {
  const { public_id } = useParams({ from: '/share/$public_id' });
  const [invoice, setInvoice] = useState<InvoiceData | null>(null);
  const [client, setClient] = useState<Client | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [isPaymentDrawerOpen, setIsPaymentDrawerOpen] = useState(false);
  const [payments, setPayments] = useState<Payment[]>([]);

  const fetchInvoiceAndPayments = useCallback(async () => {
    if (!public_id) {
      console.error('No public ID provided in URL params');
      setError("No public ID provided");
      return;
    }

    console.log('Fetching invoice with public_id:', public_id);
    try {
      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .select('*, organizations(business_name, logo_url)') // logo_url is now directly on organizations
        .eq('public_id', public_id)
        .single();

      if (invoiceError) {
        console.error('Error fetching invoice:', invoiceError);
        setError(`Error fetching invoice: ${invoiceError.message}`);
        setInvoice(null);
        return;
      }

      if (!invoiceData) {
        console.error('No invoice found with public_id:', public_id);
        setError(`No invoice found with ID: ${public_id}`);
        setInvoice(null);
        return;
      }

      console.log('Fetched invoice data:', invoiceData);

      const { data: invoiceItemsData, error: itemsError } = await supabase
        .from('invoice_items')
        .select('*')
        .eq('invoice_id', invoiceData.id);

      if (itemsError) {
        console.error('Error fetching invoice items:', itemsError);
        setError(`Error fetching invoice items: ${itemsError.message}`);
      } else {
        console.log('Fetched invoice items:', invoiceItemsData);
      }

      const { data: paymentsData, error: paymentsError } = await supabase
        .from('payments')
        .select('*')
        .eq('invoice_id', invoiceData.id);

      if (paymentsError) {
        console.error('Error fetching payments:', paymentsError);
        setError(`Error fetching payments: ${paymentsError.message}`);
      } else {
        console.log('Fetched payments:', paymentsData);
        setPayments(paymentsData || []);
      }

      const { data: clientData, error: clientError } = await supabase
        .from('clients')
        .select('*')
        .eq('id', invoiceData.client_id)
        .single();

      if (clientError) {
        console.error('Error fetching client:', clientError);
      } else {
        setClient(clientData);
      }

      setInvoice({
        ...invoiceData,
        business_name: invoiceData.organizations?.business_name || undefined,
        logo_url: invoiceData.organizations?.logo_url || undefined,
        items: invoiceItemsData || [],
        payments: paymentsData || []
      });
    } catch (error) {
      console.error('Unexpected error:', error);
      setError(`An unexpected error occurred: ${error instanceof Error ? error.message : String(error)}`);
      setInvoice(null);
    }
  }, [public_id]);

  useEffect(() => {
    fetchInvoiceAndPayments();
  }, [fetchInvoiceAndPayments]);

  const handlePaymentSuccess = useCallback(() => {
    setIsPaymentDrawerOpen(false);
    fetchInvoiceAndPayments();
  }, [fetchInvoiceAndPayments]);

  const handlePayButtonClick = useCallback(async () => {
    if (!invoice) return;

    try {
      console.log('Sending request to create payment intent for invoice:', invoice.id);
      const response = await fetch('/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ invoiceId: invoice.id }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server response:', response.status, errorText);
        throw new Error(`Failed to create payment intent: ${response.status} ${errorText}`);
      }

      const data = await response.json();
      console.log('Received response:', data);
      
      if (data.isPaid) {
        console.log('Invoice is already paid');
        // Update UI to reflect paid status
      } else {
        setClientSecret(data.clientSecret);
        setStripePromise(loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, { stripeAccount: data.stripeAccountId }));
        setIsPaymentDrawerOpen(true);
      }
    } catch (error) {
      console.error('Error creating payment intent:', error);
      console.error('Full error details:', JSON.stringify(error, null, 2));
      setError('Failed to initialize payment. Please try again later.');
    }
  }, [invoice]);

  console.log('Current invoice state:', invoice);

  if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
        <p>Public ID: {public_id || 'Not provided'}</p>
      </div>
    );
  }

  if (!invoice) {
    return <div>Loading...</div>;
  }

  const SelectedTemplate = invoiceTemplates[invoice.invoice_template as keyof typeof invoiceTemplates];

  const totals = invoice ? calculateInvoiceTotals(invoice) : { subtotal: 0, taxAmount: 0, total: 0, totalPaid: 0, amountDue: 0 };

  console.log('Rendering with invoice:', invoice);
  console.log('Invoice items:', invoice.items);

  const mockOnCreateNewClient = () => {
    console.log('Create new client functionality is not available in public view');
  };

  return (
    <PageWrapper>
        <InvoiceWrapper>
          <MainContent
            $backgroundColor={invoice.background_color || '#ffffff'}
            $bodyTextColor={invoice.body_text_color || '#000000'}
            $font={invoice.font || 'Arial'}
          >
            <AnimatedHeader headerColor={invoice.header_color || '#ffffff'}>
              {SelectedTemplate && (
                <SelectedTemplate
                  invoice={{
                    ...invoice,
                    logo_url: invoice.logo_url, // Ensure logo_url is passed to the template
                  }}
                  updateInvoice={() => {}}
                  isReadOnly={true}
                  clients={client ? [client] : []}
                  onCreateNewClient={mockOnCreateNewClient}
                  selectedClient={client}
                />
              )}
            </AnimatedHeader>
            <InvoiceDetailsContainer>
              <InvoiceItems
                invoice={{ ...invoice, items: invoice.items || [] }}
                services={[]}
                handleServiceSelect={() => {}}
                handleDescriptionChange={() => {}}
                updateItem={() => {}}
                deleteItem={() => {}}
                reorderItems={() => {}}
                isReadOnly={true}
                onCreateNewService={() => {}}
              />
              <InvoiceTotals invoice={{
                ...invoice,
                subtotal: totals.subtotal,
                amount_due: invoice.amount_due
              }} />
  
            </InvoiceDetailsContainer>
          </MainContent>
          <PaymentsList>
                <h3>Payments</h3>
                {payments.length > 0 ? (
                  payments.map((payment) => (
                    <PaymentItem key={payment.id}>
                      <span>{new Date(payment.payment_date).toLocaleDateString()}</span>
                      <span>${payment.amount.toFixed(2)}</span>
                      <span>{payment.payment_method || 'N/A'}</span>
                    </PaymentItem>
                  ))
                ) : (
                  <p>No payments recorded yet.</p>
                )}
              </PaymentsList>
        </InvoiceWrapper>
        <InvoiceDetailsWrapper>
          <InvoiceDetail>
            <InvoiceTitle>
              Invoice #{invoice.invoice_number} from
            </InvoiceTitle>
            <InvoiceTitle>
              {invoice.business_name || 'Not specified'}
            </InvoiceTitle>
          </InvoiceDetail>
          <InvoiceDetail>
            <DetailLabel>Amount Due</DetailLabel>
            <DetailValue>${invoice.amount_due.toFixed(2)}</DetailValue>
          </InvoiceDetail>
          <InvoiceDetail>
            <DetailLabel>Due Date</DetailLabel>
            <DetailValue>{new Date(invoice.due_date).toLocaleDateString()}</DetailValue>
          </InvoiceDetail>
          <PaymentButton 
            onClick={handlePayButtonClick}
            disabled={!invoice || invoice.amount_due <= 0}
            $isPaid={!invoice || invoice.amount_due <= 0}
          >
            {!invoice || invoice.amount_due <= 0 ? 'Invoice Paid' : 'Pay Invoice'}
          </PaymentButton>
          {invoice.notes && (
            <div dangerouslySetInnerHTML={{ __html: invoice.notes }} />
          )}
        </InvoiceDetailsWrapper>
      <RightDrawerComponent
        isOpen={isPaymentDrawerOpen}
        setIsOpen={setIsPaymentDrawerOpen}
        title="Pay Invoice"
      >
        <PaymentFormContainer>
          {clientSecret && stripePromise && invoice ? (
            <Elements 
              stripe={stripePromise} 
              options={{ 
                clientSecret,
                appearance: { theme: 'stripe' },
              }}
            >
              <PaymentForm 
                onClose={handlePaymentSuccess}
                amount={invoice.amount_due}
                invoiceId={invoice.id}
                organizationId={invoice.organization_id || ''}
              />
            </Elements>
          ) : (
            <div>Loading payment details...</div>
          )}
        </PaymentFormContainer>
      </RightDrawerComponent>
    </PageWrapper>
  );
};

export default PublicInvoicePage;