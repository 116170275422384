import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';

export const useTimeEntries = () => {
  const { data: organizationId } = useOrganization();

  return useQuery({
    queryKey: ['timeEntries', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization ID is required');

      const { data, error } = await supabase
        .from('time_entries')
        .select(`
          *,
          client:clients(id, full_name),
          project:projects(id, name),
          service:services(id, name),
          time_entry_invoice_items(
            invoice_item:invoice_items(
              invoice:invoices(id)
            )
          )
        `)
        .eq('organization_id', organizationId)
        .order('start_time', { ascending: false });

      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });
};