import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { InvoiceData, InvoiceItem, Payment } from '../types';
import { recalculateInvoice, calculateInvoiceTotals } from '../utils/invoiceCalculations';

export const useInvoiceMutations = (id: string | undefined) => {
  const queryClient = useQueryClient();

  const updateInvoiceMutation = useMutation({
    mutationFn: async (updatedInvoice: Partial<InvoiceData>) => {
      if (!id) throw new Error('Invoice ID is required');
      
      // Fetch the current invoice data including items
      const { data: currentInvoice, error: fetchError } = await supabase
        .from('invoices')
        .select('*, items:invoice_items(*)')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;

      // Recalculate totals using current items and updated invoice data
      const recalculatedInvoice = recalculateInvoice({
        ...currentInvoice,
        ...updatedInvoice,
      });

      // Remove any properties that are not columns in the invoices table
      const { items, payments, ...updateData } = recalculatedInvoice;

      const { data, error } = await supabase
        .from('invoices')
        .update(updateData)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      queryClient.setQueryData<InvoiceData>(['invoice', id], old => old ? { ...old, ...data } : data);
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return data;
    },
  });

  const updateInvoiceItemsMutation = useMutation({
    mutationFn: async (items: Partial<InvoiceItem>[]) => {
      if (!id) throw new Error('Invoice ID is required');
      const { data, error } = await supabase
        .from('invoice_items')
        .upsert(items.map(item => ({ ...item, invoice_id: id })))
        .select();
      if (error) throw error;

      // Fetch all invoice items
      const { data: allItems, error: fetchItemsError } = await supabase
        .from('invoice_items')
        .select('*')
        .eq('invoice_id', id);

      if (fetchItemsError) throw fetchItemsError;

      // Fetch the current invoice to get the tax_rate and payments
      const { data: invoice, error: invoiceError } = await supabase
        .from('invoices')
        .select('*, payments(*)')
        .eq('id', id)
        .single();

      if (invoiceError) throw invoiceError;

      // Calculate new totals
      const totals = calculateInvoiceTotals({ ...invoice, items: allItems });

      // Calculate total payments
      const totalPaid = invoice.payments.reduce((sum: number, payment: Payment) => sum + payment.amount, 0);

      // Calculate new amount due
      const newAmountDue = Math.max(0, totals.total - totalPaid);

      // Update the invoice with new totals
      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({ 
          subtotal: totals.subtotal, 
          total: totals.total, 
          amount_due: newAmountDue,
          // Only change status if it's not already 'draft'
          ...(invoice.status !== 'draft' && { status: newAmountDue === 0 && totalPaid > 0 ? 'paid' : 'unpaid' })
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) throw updateError;

      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return { updatedItems: data, updatedInvoice };
    },
    onSuccess: ({ updatedItems, updatedInvoice }) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) {
          console.warn('Invoice data not found in cache. Fetching fresh data.');
          queryClient.invalidateQueries({ queryKey: ['invoice', id] });
          return updatedInvoice;
        }
        return {
          ...old,
          ...updatedInvoice,
          items: old.items ? old.items.map(item => {
            const updatedItem = updatedItems.find(i => i.id === item.id);
            return updatedItem ? { ...item, ...updatedItem } : item;
          }) : updatedItems,
        };
      });
    },
  });
  
  const addInvoiceItemMutation = useMutation({
    mutationFn: async (newItem: Omit<InvoiceItem, 'id'>) => {
      console.log("Adding new item:", newItem);
      if (!id) throw new Error('Invoice ID is required');

      // Insert the new item
      const { data: insertedItem, error: insertError } = await supabase
        .from('invoice_items')
        .insert(newItem)
        .select()
        .single();

      if (insertError) throw insertError;
      console.log("Inserted item:", insertedItem);

      // Fetch the current invoice data including items and payments
      const { data: invoice, error: invoiceError } = await supabase
        .from('invoices')
        .select('*, items:invoice_items(*), payments(*)')
        .eq('id', id)
        .single();

      if (invoiceError) throw invoiceError;
      console.log("Fetched invoice data:", invoice);

      // Ensure invoice.items is an array and includes the new item
      const currentItems = Array.isArray(invoice.items) ? invoice.items : [];
      const updatedItems = [...currentItems, insertedItem];

      // Calculate new totals
      const totals = calculateInvoiceTotals({
        ...invoice,
        items: updatedItems,
      });
      console.log("Calculated totals:", totals);

      // Calculate total payments
      const totalPaid = invoice.payments.reduce((sum: number, payment: Payment) => sum + payment.amount, 0);
      console.log("Total paid:", totalPaid);

      // Calculate new amount due
      const newAmountDue = Math.max(0, totals.total - totalPaid);
      console.log("New amount due:", newAmountDue);

      // Update the invoice with new totals
      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({ 
          subtotal: totals.subtotal, 
          total: totals.total, 
          amount_due: newAmountDue,
          // Only change status if it's not already 'draft'
          ...(invoice.status !== 'draft' && { status: newAmountDue === 0 && totalPaid > 0 ? 'paid' : 'unpaid' })
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) throw updateError;
      console.log("Updated invoice:", updatedInvoice);

      return { newItem: insertedItem, updatedInvoice: { ...updatedInvoice, items: updatedItems } };
    },
    onSuccess: ({ newItem, updatedInvoice }) => {
      console.log("Mutation success. New item:", newItem, "Updated invoice:", updatedInvoice);
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return updatedInvoice;
        const updatedData = {
          ...old,
          ...updatedInvoice,
          items: Array.isArray(updatedInvoice.items) ? updatedInvoice.items : [newItem],
        };
        console.log("Updated query data:", updatedData);
        return updatedData;
      });
    },
    onError: (error) => {
      console.error("Error adding new item:", error);
    }
  });

  const reorderInvoiceItemsMutation = useMutation({
    mutationFn: async (newItems: InvoiceItem[]) => {
      if (!id) throw new Error('Invoice ID is required');
  
      const updatedItems = newItems.map((item, index) => ({
        id: item.id,
        invoice_id: id,
        description: item.description,
        quantity: item.quantity,
        price: item.price,
        taxable: item.taxable,
        order: index
      }));
  
      const { data, error } = await supabase
        .from('invoice_items')
        .upsert(updatedItems, { onConflict: 'id' })
        .select();
  
      if (error) throw error;
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return old;
        return {
          ...old,
          items: data,
        };
      });
    },
  });

  const deleteInvoiceItemMutation = useMutation({
    mutationFn: async (itemId: string) => {
      if (!id) throw new Error('Invoice ID is required');

      // Delete the invoice item
      const { error: deleteError } = await supabase
        .from('invoice_items')
        .delete()
        .eq('id', itemId);

      if (deleteError) throw deleteError;

      // Fetch the updated invoice data including items and payments
      const { data: updatedInvoice, error: fetchError } = await supabase
        .from('invoices')
        .select('*, items:invoice_items(*), payments(*)')
        .eq('id', id)
        .single();

      if (fetchError) throw fetchError;

      // Calculate new totals
      const totals = calculateInvoiceTotals(updatedInvoice);

      // Calculate total payments
      const totalPaid = updatedInvoice.payments.reduce((sum: number, payment: Payment) => sum + payment.amount, 0);

      // Calculate new amount due
      const newAmountDue = Math.max(0, totals.total - totalPaid);

      // Update the invoice with new totals
      const { data: finalInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({
          subtotal: totals.subtotal,
          total: totals.total,
          amount_due: newAmountDue,
          // Only change status if it's not already 'draft'
          ...(updatedInvoice.status !== 'draft' && { status: newAmountDue === 0 && totalPaid > 0 ? 'paid' : 'unpaid' })
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) throw updateError;

      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return { itemId, updatedInvoice: finalInvoice };
    },
    onSuccess: ({ itemId, updatedInvoice }) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], (oldData) => {
        if (!oldData) return updatedInvoice;
        return {
          ...oldData,
          ...updatedInvoice,
          items: (oldData.items || []).filter((item) => item.id !== itemId),
        };
      });
    },
  });

  const addPaymentMutation = useMutation({
    mutationFn: async (payment: Omit<Payment, "id">) => {
      if (!id) throw new Error('Invoice ID is required');

      const { data: paymentData, error: paymentError } = await supabase
        .from('payments')
        .insert([{ ...payment, invoice_id: id }])
        .select()
        .single();

      if (paymentError) throw paymentError;

      // Fetch the current invoice data
      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .select('total, amount_due')
        .eq('id', id)
        .single();

      if (invoiceError) {
        console.error('Error fetching invoice data:', invoiceError);
        throw new Error(`Failed to fetch invoice data: ${invoiceError.message}`);
      }

      if (!invoiceData) {
        throw new Error(`No invoice found with id: ${id}`);
      }

      // Calculate new amount due
      const newAmountDue = Math.max(0, invoiceData.amount_due - paymentData.amount);

      // Determine the new status
      const newStatus = newAmountDue === 0 && paymentData.amount > 0 ? 'paid' : 'unpaid';

      // Update the invoice with new amount due and status
      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({
          amount_due: newAmountDue,
          status: newStatus
        })
        .eq('id', id)
        .select()
        .single();

      if (updateError) throw updateError;

      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return { paymentData, updatedInvoice };
    },
    onSuccess: ({ paymentData, updatedInvoice }) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return updatedInvoice;
        return {
          ...old,
          ...updatedInvoice,
          payments: Array.isArray(old.payments) ? [...old.payments, paymentData] : [paymentData],
          amount_due: updatedInvoice.amount_due,
          status: updatedInvoice.status,
        };
      });
    },
    onError: (error) => {
      console.error('Error adding payment:', error);
      // You can add additional error handling here, such as showing a notification to the user
    },
  });

  const removePaymentMutation = useMutation({
    mutationFn: async (paymentId: string) => {
      if (!id) throw new Error('Invoice ID is required');

      // First, fetch the payment amount
      const { data: paymentData, error: paymentError } = await supabase
        .from('payments')
        .select('amount')
        .eq('id', paymentId)
        .single();
      if (paymentError) throw paymentError;

      // Delete the payment
      const { error: deleteError } = await supabase
        .from('payments')
        .delete()
        .eq('id', paymentId);
      if (deleteError) throw deleteError;

      // Fetch the current invoice data
      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .select('amount_due, total')
        .eq('id', id)
        .single();
      if (invoiceError) throw invoiceError;

      // Calculate new amount_due
      const newAmountDue = Math.min(invoiceData.total, invoiceData.amount_due + paymentData.amount);

      // Fetch all payments for this invoice
      const { data: payments, error: paymentsError } = await supabase
        .from('payments')
        .select('amount')
        .eq('invoice_id', id);

      if (paymentsError) throw paymentsError;

      const totalPaid = payments.reduce((sum, payment) => sum + payment.amount, 0);

      // Update the invoice
      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .update({
          amount_due: newAmountDue,
          status: newAmountDue === 0 && totalPaid > 0 ? 'paid' : 'unpaid'
        })
        .eq('id', id)
        .select()
        .single();
      if (updateError) throw updateError;

      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return { paymentId, updatedInvoice };
    },
    onSuccess: ({ paymentId, updatedInvoice }) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return old;
        return {
          ...old,
          payments: old.payments.filter(p => p.id !== paymentId),
          amount_due: updatedInvoice.amount_due,
          status: updatedInvoice.status,
        };
      });
    },
  });

  const deleteInvoiceMutation = useMutation({
    mutationFn: async () => {
      if (!id) throw new Error('Invoice ID is required');
  
      // First, fetch the invoice items
      const { data: invoiceItems, error: fetchError } = await supabase
        .from('invoice_items')
        .select('id')
        .eq('invoice_id', id);
  
      if (fetchError) throw fetchError;
  
      if (invoiceItems && invoiceItems.length > 0) {
        // Delete entries from the time_entry_invoice_items junction table
        const { error: timeEntryJunctionDeleteError } = await supabase
          .from('time_entry_invoice_items')
          .delete()
          .in('invoice_item_id', invoiceItems.map(item => item.id));
  
        if (timeEntryJunctionDeleteError) throw timeEntryJunctionDeleteError;
  
        // Delete entries from the expense_invoice_items junction table
        const { error: expenseJunctionDeleteError } = await supabase
          .from('expense_invoice_items')
          .delete()
          .in('invoice_item_id', invoiceItems.map(item => item.id));
  
        if (expenseJunctionDeleteError) throw expenseJunctionDeleteError;
      }
  
      // Delete invoice items
      const { error: itemsDeleteError } = await supabase
        .from('invoice_items')
        .delete()
        .eq('invoice_id', id);
  
      if (itemsDeleteError) throw itemsDeleteError;
  
      // Delete payments associated with the invoice
      const { error: paymentsDeleteError } = await supabase
        .from('payments')
        .delete()
        .eq('invoice_id', id);
  
      if (paymentsDeleteError) throw paymentsDeleteError;
  
      // Finally, delete the invoice
      const { error: invoiceDeleteError } = await supabase
        .from('invoices')
        .delete()
        .eq('id', id);
  
      if (invoiceDeleteError) throw invoiceDeleteError;
  
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return id;
    },
    onSuccess: (deletedInvoiceId) => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.removeQueries({ queryKey: ['invoice', deletedInvoiceId] });
    },
  });
  
  const shareInvoiceMutation = useMutation({
    mutationFn: async () => {
      if (!id) throw new Error('Invoice ID is required');
      const { data: existingInvoice, error: fetchError } = await supabase
        .from('invoices')
        .select('public_id')
        .eq('id', id)
        .single();
  
      if (fetchError) throw fetchError;
  
      if (existingInvoice.public_id) {
        return existingInvoice;
      }
  
      const { data, error } = await supabase
        .from('invoices')
        .update({ public_id: crypto.randomUUID() })
        .eq('id', id)
        .select()
        .single();
  
      if (error) throw error;
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        return old ? { ...old, ...data } : data;
      });
    },
  });

  const updateInvoiceItemDescriptionMutation = useMutation({
    mutationFn: async ({ itemId, description }: { itemId: string; description: string }) => {
      if (!id) throw new Error('Invoice ID is required');
      const { data, error } = await supabase
        .from('invoice_items')
        .update({ description })
        .eq('id', itemId)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: (updatedItem) => {
      queryClient.setQueryData<InvoiceData>(['invoice', id], old => {
        if (!old) return old;
        return {
          ...old,
          items: old.items.map(item => 
            item.id === updatedItem.id ? { ...item, description: updatedItem.description } : item
          ),
        };
      });
    },
  });

  return {
    updateInvoiceMutation,
    updateInvoiceItemsMutation,
    addInvoiceItemMutation,
    deleteInvoiceItemMutation,
    addPaymentMutation,
    removePaymentMutation,
    deleteInvoiceMutation,
    shareInvoiceMutation,
    reorderInvoiceItemsMutation,
    updateInvoiceItemDescriptionMutation
  };
};