import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';
import { TimeEntry, Expense, InvoiceItem, Service } from '../types';

interface CreateInvoiceParams {
  customer?: string;
  client_id?: string | null;
  currency?: string;
  project_id?: string;
  organization_id: string;
  timeEntries?: TimeEntry[];
  expenses?: Expense[];
}

const createInvoiceItems = (timeEntries: TimeEntry[], services: Service[], newInvoiceId: string) => {
  return timeEntries.map((entry, index) => {
    const service = services.find(s => s.id === entry.service_id);
    return {
      invoice_id: newInvoiceId,
      description: `${entry.service?.name || 'Service'} - ${entry.project?.name || 'Project'}`,
      quantity: entry.duration / 3600, // Convert seconds to hours
      price: service?.price || 0, // Use 0 as fallback if price is not available
      taxable: false,
      order: index,
    };
  });
};

export const useCreateInvoiceMutation = () => {
  const queryClient = useQueryClient();
  const { data: organizationId, error: organizationError } = useOrganization();

  return useMutation({
    mutationFn: async (params: CreateInvoiceParams) => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');
      if (organizationError) throw new Error('Failed to fetch organization');
      if (!organizationId) throw new Error('User is not associated with an organization');

      // Fetch brand settings
      const { data: brandSettings, error: brandSettingsError } = await supabase
        .from('brand_settings')
        .select('*')
        .eq('organization_id', organizationId)
        .single();

      if (brandSettingsError) {
        console.error('Error fetching brand settings:', brandSettingsError);
      }

      const newInvoice = {
        customer: params.customer || 'New Customer',
        subtotal: 0,
        amount_due: 0,
        invoice_date: new Date().toISOString(),
        due_date: new Date(new Date().setDate(new Date().getDate() + (brandSettings?.default_due_days || 30))).toISOString(),
        invoice_created_at: new Date().toISOString(), // Add this line
        user_id: user.id,
        organization_id: organizationId,
        client_id: params.client_id || null,
        project_id: params.project_id || null,
        invoice_template: brandSettings?.default_template || 'simple',
        font: brandSettings?.default_font || 'Arial',
        header_color: brandSettings?.default_header_color || '#ffffff',
        header_text_color: brandSettings?.default_header_text_color || '#000000',
        background_color: brandSettings?.default_background_color || '#ffffff',
        body_text_color: brandSettings?.default_body_text_color || '#000000',
        tax_rate: brandSettings?.default_tax_rate || 0,
        status: 'draft',
        due_days: brandSettings?.default_due_days || 30,
        currency: params.currency || brandSettings?.default_currency || 'USD',
      };

      const { data: invoiceData, error: invoiceError } = await supabase
        .from('invoices')
        .insert([newInvoice])
        .select('*')
        .single();

      if (invoiceError) throw invoiceError;

      const newInvoiceId = invoiceData.id;

      // Add an empty invoice item only if there are no time entries or expenses
      if ((!params.timeEntries || params.timeEntries.length === 0) && 
          (!params.expenses || params.expenses.length === 0)) {
        const emptyInvoiceItem = {
          invoice_id: newInvoiceId,
          description: '',
          quantity: 1,
          price: 0,
          taxable: false,
          order: 0,
        };

        const { error: emptyItemError } = await supabase
          .from('invoice_items')
          .insert([emptyInvoiceItem]);

        if (emptyItemError) throw emptyItemError;
      }

      // Fetch services
      const { data: services, error: servicesError } = await supabase
        .from('services')
        .select('*')
        .eq('organization_id', organizationId);

      if (servicesError) throw servicesError;

      // Add invoice items from time entries
      if (params.timeEntries && params.timeEntries.length > 0) {
        const invoiceItems = createInvoiceItems(params.timeEntries, services, newInvoiceId);
        const { data: insertedItems, error: itemsError } = await supabase
          .from('invoice_items')
          .insert(invoiceItems)
          .select();

        if (itemsError) throw itemsError;

        // Create entries in the junction table
        const junctionEntries = params.timeEntries.map((timeEntry, index) => ({
          time_entry_id: timeEntry.id,
          invoice_item_id: insertedItems[index].id,
        }));

        const { error: junctionError } = await supabase
          .from('time_entry_invoice_items')
          .insert(junctionEntries);

        if (junctionError) throw junctionError;
      }

      // Add invoice items from expenses
      if (params.expenses && params.expenses.length > 0) {
        const invoiceItems = params.expenses.map((expense, index) => ({
          invoice_id: newInvoiceId,
          description: expense.description,
          quantity: 1,
          price: expense.amount,
          taxable: false,
          order: index,
        }));

        const { data: insertedItems, error: itemsError } = await supabase
          .from('invoice_items')
          .insert(invoiceItems)
          .select();

        if (itemsError) throw itemsError;

        // Create junction table entries
        const junctionEntries = insertedItems.map((item, index) => ({
          expense_id: params.expenses?.[index]?.id,
          invoice_item_id: item.id,
          amount: params.expenses?.[index]?.amount,
        }));

        const { error: junctionError } = await supabase
          .from('expense_invoice_items')
          .insert(junctionEntries);

        if (junctionError) throw junctionError;
      }

      // Recalculate invoice totals
      const { data: updatedInvoice, error: updateError } = await supabase
        .from('invoices')
        .select('*, invoice_items(quantity, price, taxable)')
        .eq('id', newInvoiceId)
        .single();

      if (updateError) throw updateError;

      const subtotal = updatedInvoice.invoice_items.reduce((sum: number, item: InvoiceItem) => sum + item.quantity * item.price, 0);
      const taxableAmount = updatedInvoice.invoice_items.filter((item: InvoiceItem) => item.taxable).reduce((sum: number, item: InvoiceItem) => sum + item.quantity * item.price, 0);
      const taxAmount = (taxableAmount * updatedInvoice.tax_rate) / 100;
      const total = subtotal + taxAmount;

      const { data: finalInvoice, error: finalUpdateError } = await supabase
        .from('invoices')
        .update({ subtotal, amount_due: total })
        .eq('id', newInvoiceId)
        .select('*')
        .single();

      if (finalUpdateError) throw finalUpdateError;

      return finalInvoice;
    },
    onSuccess: (newInvoice) => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      if (newInvoice.project_id) {
        queryClient.invalidateQueries({ queryKey: ['projectInvoices', newInvoice.project_id] });
      }
      queryClient.invalidateQueries({ queryKey: ['expenses'] });
      return newInvoice;
    },
  });
};