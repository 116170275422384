import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';
import { Service } from "../types"; // Make sure you're using the Service type from types.ts

export const useServices = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  const servicesQuery = useQuery<Service[], Error>({
    queryKey: ['services', organizationId],
    queryFn: async () => {
      if (!organizationId) return [];
      const { data, error } = await supabase
        .from('services')
        .select('*')
        .eq('organization_id', organizationId)
        .eq('is_deleted', false)
        .order('created_at', { ascending: false });
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });

  const createService = useMutation({
    mutationFn: async (newService: Omit<Service, 'id'>) => {
      if (!organizationId) throw new Error('Organization not found');
      const { data, error } = await supabase
        .from('services')
        .insert([{ ...newService, organization_id: organizationId }])
        .select();
      if (error) throw error;
      return data[0];
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['services', organizationId] });
    },
  });

  const updateService = useMutation({
    mutationFn: async (updatedService: Service) => {
      const { data, error } = await supabase
        .from('services')
        .update(updatedService)
        .eq('id', updatedService.id)
        .select();

      if (error) throw error;
      return data[0];
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['services', organizationId] });
    },
  });

  const deleteService = useMutation({
    mutationFn: async (serviceId: string) => {
      const { data, error } = await supabase
        .from('services')
        .update({ is_deleted: true })  // Change this line
        .eq('id', serviceId);

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['services', organizationId] });
    },
  });

  return {
    ...servicesQuery,
    createService,
    updateService,
    deleteService,
  };
};