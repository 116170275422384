import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Task } from '../types';
import { useOrganization } from './useOrganization';

export const useTaskMutations = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  const updateTaskMutation = useMutation({
    mutationFn: async (updatedTask: Partial<Task>) => {
      const { data, error } = await supabase
        .from('tasks')
        .update(updatedTask)
        .eq('id', updatedTask.id)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onMutate: async (updatedTask) => {
      await queryClient.cancelQueries({ queryKey: ['tasks', organizationId] });
      const previousTasks = queryClient.getQueryData<Task[]>(['tasks', organizationId]);
      queryClient.setQueryData<Task[]>(['tasks', organizationId], old => 
        old?.map(task => task.id === updatedTask.id ? { ...task, ...updatedTask } : task) ?? []
      );
      return { previousTasks };
    },
    onError: (_err, _newTask, context) => {
      queryClient.setQueryData(['tasks', organizationId], context?.previousTasks);
    },
    onSettled: () => {
      //queryClient.invalidateQueries({ queryKey: ['tasks', organizationId] });
    },
  });

  const createTaskMutation = useMutation({
    mutationFn: async (newTask: Omit<Task, 'id'>) => {
      const { data, error } = await supabase
        .from('tasks')
        .insert(newTask)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: (createdTask) => {
      queryClient.setQueryData(['tasks'], (oldTasks: Task[] | undefined) => 
        oldTasks ? [...oldTasks, createdTask] : [createdTask]
      );
    },
  });

  const deleteTaskMutation = useMutation({
    mutationFn: async (taskId: string) => {
      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', taskId);

      if (error) throw error;
    },
    onSuccess: () => {
      // Remove optimistic update
      // queryClient.setQueryData(['tasks', organizationId], (oldTasks: Task[] | undefined) =>
      //   oldTasks?.filter(task => task.id !== taskId)
      // );
      
      // Instead, invalidate the query to trigger a refetch
      queryClient.invalidateQueries({ queryKey: ['tasks', organizationId] });
    },
  });

  return {
    updateTaskMutation,
    createTaskMutation,
    deleteTaskMutation,
  };
};