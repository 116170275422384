import React from 'react';
import { PageHeaderProps } from '../components/PageHeader';

interface PageContextType {
  setPageHeaderProps: React.Dispatch<React.SetStateAction<PageHeaderProps>>;
  // Add other shared page elements here if needed
}

export const PageContext = React.createContext<PageContextType>({
  setPageHeaderProps: () => {},
});