import React, { useState, useEffect } from "react";
import { useNavigate } from '@tanstack/react-router'
import styled from "styled-components";
import { useOrganization } from "./hooks/useOrganization";
import Button from "./components/Button";
import PageSkeleton from "./components/PageSkeleton";
import ServicesPage from "./ServicesList";
import Tabs from "./components/Tabs";
import Dashboard from "./StripeDashboard";
import UserProfilePage from "./UserProfilePage";
import OrganizationDetailsPage from "./OrganizationDetailsPage"; // New component for organization details
import OrganizationUsersTab from "./components/Organization/OrganizationUsersTab";
import TaskStatusManager from "./components/Tasks/TaskStatusManager";
import { usePageContext } from "./hooks/usePageContext";

const PageContainer = styled.div``;

const MainContent = styled.div`
  padding: 20px 48px;
`;

const SettingsPage: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const navigate = useNavigate();
  const {
    data: organizationId,
    isLoading,
    error,
  } = useOrganization();

  // Change the initial state to "userProfile"
  const [activeTab, setActiveTab] = useState("userProfile");
  const [isServiceDrawerOpen, setIsServiceDrawerOpen] = useState(false);

  useEffect(() => {
    setPageHeaderProps({
      title: "Settings",
    });
  }, [setPageHeaderProps]);

  if (isLoading) {
    return <PageSkeleton title="Settings" />;
  }

  if (error) {
    return <PageContainer>Error: {error.message}</PageContainer>;
  }

  if (!organizationId) {
    return (
      <PageContainer>
        <MainContent>
          <p>
            Please select an organization to manage its settings or create a new
            one.
          </p>
          <Button onClick={() => navigate({ to: "/settings" })}>
            Select Organization
          </Button>
        </MainContent>
      </PageContainer>
    );
  }

  const handleAddNewService = () => {
    setIsServiceDrawerOpen(true);
  };

  const servicesActionButtons = (
    <Button onClick={handleAddNewService}>
      Add New Service
    </Button>
  );

  return (
    <PageContainer>
      <Tabs
        tabs={[
          { id: "userProfile", label: "Profile" }, // Move this to the first position
          { id: "details", label: "Organization" },
          { id: "users", label: "Users" }, // New tab for Organization Users
          { id: "services", label: "Services" },
          { id: "payments", label: "Payments" },
          { id: "taskStatuses", label: "Task Statuses" },
        ]}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        actionButtons={
          activeTab === "services" ? servicesActionButtons : null
        }
      />
      <MainContent>
        {activeTab === "userProfile" && (
          <UserProfilePage />
        )}
        {activeTab === "details" && (
          <OrganizationDetailsPage />
        )}
        {activeTab === "users" && (
          <OrganizationUsersTab />
        )}
        {activeTab === "services" && (
          <ServicesPage
            isDrawerOpen={isServiceDrawerOpen}
            setIsDrawerOpen={setIsServiceDrawerOpen}
          />
        )}
        {activeTab === "payments" && (
          <Dashboard />
        )}
        {activeTab === "taskStatuses" && (
          <TaskStatusManager />
        )}
      </MainContent>
    </PageContainer>
  );
};

export default SettingsPage;