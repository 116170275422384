import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { InvoiceData, Client } from '../types';
import { useOrganization } from './useOrganization';

export const useInvoice = (id: string | undefined) => {
  const { data: organizationId } = useOrganization();

  return useQuery<InvoiceData>({
    queryKey: ['invoice', id, organizationId],
    queryFn: async () => {
      if (!id || !organizationId) throw new Error('Invoice ID or Organization ID is required');

      console.log(`Fetching invoice data from network for: ${id}`);
      const [
        { data: invoiceData, error: invoiceError },
        { data: invoiceItemsData },
        { data: paymentsData },
        { data: organizationData, error: organizationError }
      ] = await Promise.all([
        supabase.from('invoices').select('*').eq('id', id).single(),
        supabase.from('invoice_items').select('*').eq('invoice_id', id),
        supabase.from('payments').select('*').eq('invoice_id', id),
        supabase.from('organizations').select('business_name, logo_url').eq('id', organizationId).single()
      ]);
    
      if (invoiceError) throw invoiceError;
      if (organizationError) throw organizationError;
      
      console.log('Fetched invoice data:', invoiceData);
      console.log(`Fetched ${invoiceItemsData?.length || 0} invoice items:`, invoiceItemsData);
      console.log(`Fetched ${paymentsData?.length || 0} payments:`, paymentsData);
      console.log('Fetched organization data:', organizationData);
    
      return {
        ...invoiceData,
        items: invoiceItemsData || [],
        payments: paymentsData || [],
        business_name: organizationData?.business_name,
        logo_url: organizationData?.logo_url,
      };
    },
    enabled: !!id && !!organizationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000,
  });
};

export const useClients = () => {
  return useQuery<Client[]>({
    queryKey: ['clients'],
    queryFn: async () => {
      const { data, error } = await supabase.from('clients').select('*');
      if (error) throw error;
      return data;
    },
  });
};

export const useBrandSettings = () => {
  const { data: organizationId } = useOrganization();

  return useQuery({
    queryKey: ['brandSettings', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('Organization not found');

      const { data, error } = await supabase
        .from('brand_settings')
        .select('*')
        .eq('organization_id', organizationId)
        .single();

      if (error) throw error;
      return data || { default_template: 'simple', default_font: 'Arial', default_header_color: '#ffffff' };
    },
    enabled: !!organizationId,
  });
};