import React from 'react';
import { EditorContent, Editor } from '@tiptap/react';
import { Play8, Trash12, Pause8 } from '../../Icon';
import { ButtonGroup, LogTimeButton, DeleteButton } from './TimeTrackerStyles';
import styled from 'styled-components';

const ModernWrapper = styled.div`
  width: 100%;
`;

const EditorWrapper = styled.div`

  .ProseMirror {
    padding: 16px 24px;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    
    p.is-editor-empty:first-child::before {
      content: attr(data-placeholder);
      float: left;
      color: rgba(0, 0, 0, 0.5);
      pointer-events: none;
      height: 0;
    }

    p {
      margin: 0;
    }
  }
`;

const TimeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TimeInputField = styled.input`
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  border: none;
  text-align: center;
  font-size: 40px;
  color: white;
  height: 248px;
  font-family: 'PP Mori';
  outline: none;
  font-feature-settings: 'tnum';
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Column = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  background-color: ${props => props.color};
  text-align: center;
`;

const TimeInputLabel = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  bottom: 16px;
  position: absolute;
`;

interface TimeTrackerLayoutProps {
  manualTime: string;
  handleManualTimeChange: (field: 'hours' | 'minutes' | 'seconds', value: string) => void;
  editor: Editor | null;
  isBillable: boolean;
  handleBillableToggle: (checked: boolean) => void;
  isRunning: boolean;
  isPaused: boolean;
  handleStart: () => void;
  handlePause: () => void;
  handleLogTime: () => void;
  handleDelete: () => void;
}

const ModernTimeTracker: React.FC<TimeTrackerLayoutProps> = ({
  manualTime,
  handleManualTimeChange,
  editor,
  handlePause,
  isRunning,
  isPaused,
  handleStart,
  handleLogTime,
  handleDelete,
}) => {
  return (
    <ModernWrapper>
        <TimeInputWrapper>
          <Column color="#D04085">
          <TimeInputField
            type="number"
            value={manualTime.split(':')[0]}
            onChange={(e) => handleManualTimeChange('hours', e.target.value)}
          />
          <TimeInputLabel>Hours</TimeInputLabel>
          </Column>
          <Column color="#E61111">
          <TimeInputField
            type="number"
            value={manualTime.split(':')[1]}
            onChange={(e) => handleManualTimeChange('minutes', e.target.value)}
          />
          <TimeInputLabel>Minutes</TimeInputLabel>
          </Column>
          <Column color="#E96329">
          <TimeInputField
            type="number"
            value={manualTime.split(':')[2]}
            onChange={(e) => handleManualTimeChange('seconds', e.target.value)}
          />
          <TimeInputLabel>Seconds</TimeInputLabel>
          </Column>
        </TimeInputWrapper>
        <EditorWrapper>
          <EditorContent editor={editor} />
        </EditorWrapper>
        <ButtonGroup>
          {!isRunning ? (
            <LogTimeButton onClick={handleStart}>
              <Play8/> {isPaused ? 'Resume' : 'Start'}
            </LogTimeButton>
          ) : (
            <LogTimeButton onClick={handlePause}>
              <Pause8/> Pause
            </LogTimeButton>
          )}
          {(isRunning || isPaused) && (
            <LogTimeButton onClick={handleLogTime}>
              Log Time
            </LogTimeButton>
          )}
          {(isRunning || isPaused) && (
            <DeleteButton onClick={handleDelete}><Trash12 /></DeleteButton>
          )}
        </ButtonGroup>
    </ModernWrapper>
  );
};

export default ModernTimeTracker;