import React from 'react';
import styled from 'styled-components';
import { CardTitle, CardItem } from './Card';

const StyledEmptyCard = styled.div`
  padding: 20px 24px 16px 24px;
  min-height: 128px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s ease, transform 0.1s ease;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  background-color: transparent;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;
  user-select: none;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.8);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const EmptyCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const EmptyCardTitle = styled(CardTitle)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;

const EmptyCardText = styled(CardItem)`
`;

interface EmptyStateCardProps {
  title: string;
  description: string;
  actionText: string;
  onClick: () => void;
}

const EmptyStateCard: React.FC<EmptyStateCardProps> = ({ title, description, actionText, onClick }) => (
  <StyledEmptyCard onClick={onClick}>
    <EmptyCardContent>
      <EmptyCardTitle>{title}</EmptyCardTitle>
      <EmptyCardText>{description}</EmptyCardText>
      <EmptyCardText>{actionText}</EmptyCardText>
    </EmptyCardContent>
  </StyledEmptyCard>
);

export default EmptyStateCard;