import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SimpleHeader } from '../../templates/SimpleHeader';
import { DetailedHeader } from '../../templates/DetailedHeader';
import { ModernHeader } from '../../templates/ModernHeader';
import { MinimalistHeader } from '../../templates/MinimalistHeader';
import { GradientHeader } from '../../templates/GradientHeader';
import { BoxedHeader } from '../../templates/BoxedHeader';
import { SplitHeader } from '../../templates/SplitHeader';
import { CircularHeader } from '../../templates/CircularHeader';
import { CompactHeader } from '../../templates/CompactHeader';
import { InvoiceTemplatesProps } from '../../templates/types';
import { Client, InvoiceData } from '../../types';

const PreviewContainer = styled.div.attrs<{ $headerColor: string; $height: number; $shouldAnimate: boolean }>(props => ({
  style: {
    height: `${props.$height}px`,
    transition: props.$shouldAnimate ? 'height 0.3s ease-in-out' : 'none',
    backgroundColor: props.$headerColor,
  },
}))`
  overflow: hidden;
`;

interface HeaderPreviewProps {
  template: string;
  font: string;
  headerColor: string;
  headerTextColor: string;
  logoUrl: string | null;
}

const HeaderPreview: React.FC<HeaderPreviewProps> = ({ template, font, headerColor, headerTextColor, logoUrl }) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const mockInvoice: InvoiceData = {
    id: 'mock-id',
    customer: 'Mock Customer',
    font,
    header_color: headerColor,
    header_text_color: headerTextColor,
    amount_due: 1000,
    invoice_date: '2025-11-05',
    due_date: '2025-12-05',
    invoice_number: '0001',
    subtotal: 1000,
    tax_rate: 0,
    total: 1000,
    due_days: 30,
    currency: 'USD',
    items: [],
    notes: '',
    invoice_template: 'simple',
    public_id: 'mock-public-id',
    payments: [],
    status: 'draft',
    logo_url: logoUrl,
    invoice_created_at: '2025-12-05',
  };

  const mockUpdateInvoice: InvoiceTemplatesProps['updateInvoice'] = () => {};
  const mockClients: Client[] = [];
  const mockSelectedClient: Client | null = null;

  useEffect(() => {
    const updateHeight = () => {
      if (headerRef.current) {
        setHeight(headerRef.current.scrollHeight);
      }
    };

    updateHeight();

    // Add event listener for image load
    const images = headerRef.current?.getElementsByTagName('img');
    if (images) {
      Array.from(images).forEach(img => {
        img.addEventListener('load', updateHeight);
      });
    }

    if (!shouldAnimate) {
      setTimeout(() => setShouldAnimate(true), 100);
    }

    // Cleanup
    return () => {
      if (images) {
        Array.from(images).forEach(img => {
          img.removeEventListener('load', updateHeight);
        });
      }
    };
  }, [template, font, headerColor, headerTextColor, logoUrl, shouldAnimate]);

  const renderHeader = () => {
    const commonProps: InvoiceTemplatesProps = {
      invoice: mockInvoice,
      updateInvoice: mockUpdateInvoice,
      isReadOnly: true,
      clients: mockClients,
      onCreateNewClient: () => {},
      selectedClient: mockSelectedClient,
      ClientSelectorComponent: () => null // Add a mock ClientSelectorComponent
    };

    switch (template) {
      case 'simple':
        return <SimpleHeader {...commonProps} />;
      case 'detailed':
        return <DetailedHeader {...commonProps} />;
      case 'modern':
        return <ModernHeader {...commonProps} />;
      case 'minimalist':
        return <MinimalistHeader {...commonProps} />;
      case 'gradient':
        return <GradientHeader {...commonProps} />;
      case 'boxed':
        return <BoxedHeader {...commonProps} />;
      case 'split':
        return <SplitHeader {...commonProps} />;
      case 'circular':
        return <CircularHeader {...commonProps} />;
      case 'compact':
        return <CompactHeader {...commonProps} />;
      default:
        return <SimpleHeader {...commonProps} />;
    }
  };

  return (
    <PreviewContainer 
      $height={height} 
      $headerColor={headerColor}
      $shouldAnimate={shouldAnimate}
    >
      <div ref={headerRef}>
        {renderHeader()}
      </div>
    </PreviewContainer>
  );
};

export default HeaderPreview;