import React from "react";
import { More12 } from "../Icon";
import EntityPicker from "../EntityPicker";

interface InvoiceMoreMenuProps {
  onDelete: () => void;
  onDownloadPDF: () => void;
  onPreviewHTML: () => void;
  onShare: () => void;
}

const InvoiceMoreMenu: React.FC<InvoiceMoreMenuProps> = ({
  onDelete,
  onDownloadPDF,
  onPreviewHTML,
}) => {
  const menuOptions = [
    { id: "download", name: "Download PDF" },
    { id: "preview", name: "Preview HTML" },
    { id: "delete", name: "Delete Invoice" },
  ];

  const handleOptionChange = (selectedId: string | null) => {
    if (selectedId === "download") {
      onDownloadPDF();
    } else if (selectedId === "preview") {
      onPreviewHTML();
    } else if (selectedId === "delete") {
      onDelete();
    }
  };

  return (
    <EntityPicker
      selectedId={null}
      onChange={handleOptionChange}
      entities={menuOptions}
      label="More Options"
      icon={<More12 />}
      placement="bottom-end"
      iconOnly={true}
    />
  );
};

export default InvoiceMoreMenu;
