import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Project } from '../types';
import { useOrganization } from './useOrganization';

export const useOrganizationProjects = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<Project[], Error>({
    queryKey: ['organizationProjects', organizationId],
    queryFn: async () => {
      if (!organizationId) return [];
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('organization_id', organizationId);
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });
};