import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { useClients } from "../../hooks/useClients";
import { useOrganization } from "../../hooks/useOrganization";
import { Client } from "../../types";
import { Checkmark12 } from "../Icon";

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const InputPreview = styled.div<{ $noClientSelected: boolean }>`
  padding: 4px 8px;
  display: inline-flex;
  gap: 8px;
  border-radius: 8px;
  margin-left: -8px;
  align-items: center;
  position: relative;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  user-select: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  justify-content: space-between;
  color: ${(props) => props.$noClientSelected ? 'rgba(0,0,0,0.5)' : 'rgba(0, 0, 0, 0.8)'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const PopoverContainer = styled.div`
  z-index: 2000;
  pointer-events: auto;
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: ${(props) => props.$transformOrigin};
  z-index: 2000;
  border-radius: 12px;
  box-shadow: 
    0 2px 2px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 8px 8px rgba(0, 0, 0, 0.05),
    0 16px 16px rgba(0, 0, 0, 0.05),
    0 32px 32px rgba(0, 0, 0, 0.05),
    0 0 0 0.5px rgba(0, 0, 0, 0.1);
  padding: 4px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  pointer-events: auto;
`;

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`;

const ClientOption = styled.div<{ $isSelected: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  pointer-events: auto;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(0, 0, 0, 0.05)" : "transparent"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.05)"};
  }

  &:active {
    transform: scale(0.99);
  }
`;

export interface ClientSelectorProps {
  clients: Client[];
  selectedClientId: string | null;
  onSelectClient: (clientId: string) => void;
  disabled?: boolean;
  className?: string;
  onCreateNewClient: () => void;
}

export const ClientSelector: React.FC<ClientSelectorProps> = ({
  selectedClientId,
  onSelectClient,
  className,
  onCreateNewClient,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { data: organizationId } = useOrganization();
  const { data: clients, isLoading } = useClients();

  const filteredClients = React.useMemo(() => {
    return clients?.filter(
      (client: Client) => client.organization_id === organizationId
    ) || [];
  }, [clients, organizationId]);

  const selectedClient = filteredClients.find(
    (client: Client) => client.id === selectedClientId
  );

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
        return "bottom center";
      case "top-start":
        return "bottom left";
      case "top-end":
        return "bottom right";
      case "bottom":
        return "top center";
      case "bottom-start":
        return "top left";
      case "bottom-end":
        return "top right";
      case "left":
        return "right center";
      case "left-start":
        return "right top";
      case "left-end":
        return "right bottom";
      case "right":
        return "left center";
      case "right-start":
        return "left top";
      case "right-end":
        return "left bottom";
      default:
        return "top left";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const click = useClick(context, { toggle: true });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleSelectClient = (clientId: string) => {
    onSelectClient(clientId);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    } else {
      const timeout = setTimeout(() => setIsMounted(false), 150);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  return (
    <PickerWrapper>
      <InputPreview
        ref={refs.setReference}
        {...getReferenceProps()}
        $noClientSelected={!selectedClientId}
        className={className}
      >
        <span>
          {selectedClient
            ? selectedClient.full_name
            : isLoading
            ? "Loading..."
            : "Select Client"}
        </span>
      </InputPreview>
      <FloatingPortal>
        {isMounted && (
          <PopoverContainer
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <PopoverContent style={styles} $transformOrigin={transformOrigin}>
              {filteredClients.map((client: Client) => (
                <ClientOption
                  key={client.id}
                  onClick={() => handleSelectClient(client.id)}
                  $isSelected={client.id === selectedClientId}
                >
                  <span>{client.full_name}</span>
                  {client.id === selectedClientId && (
                    <CheckIconWrapper>
                      <Checkmark12 />
                    </CheckIconWrapper>
                  )}
                </ClientOption>
              ))}
              <ClientOption
                key="create-new"
                onClick={onCreateNewClient}
                $isSelected={false}
              >
                <span>New Client</span>
              </ClientOption>
            </PopoverContent>
          </PopoverContainer>
        )}
      </FloatingPortal>
    </PickerWrapper>
  );
};