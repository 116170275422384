import React, { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "./supabaseClient";
import styled from "styled-components";
import Button from "./components/Button";
import { Expense, ExpenseInvoiceItem } from "./types";
import AddExpenseDrawer from "./components/Expenses/AddExpenseDrawer";
import { useNavigate, Link } from '@tanstack/react-router';
import { useOrganization } from "./hooks/useOrganization";
import { useExpenseOperations } from "./hooks/useExpenseOperations";
import DataTable from "./components/DataTable";
import { ColumnDef } from '@tanstack/react-table';
import DateRangePicker from "./components/DateRangePicker";
import { Calendar12, Expense32 } from './components/Icon';
import { isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import Tabs from "./components/Tabs";
import FilterDrawer from "./components/FilterDrawer";
import FilterBox from "./components/FilterBox";
import FilterPicker from "./components/FilterPicker";
import { Filter, FilterOption, FilterId } from './types';
import { useMediaQuery } from './hooks/useMediaQuery';
import MultipleEntityPicker from './components/MultipleEntityPicker';
import { Client12 } from './components/Icon';
import { getDateRange } from './utils/dateUtils';
import { usePageContext } from './hooks/usePageContext';

const PageContainer = styled.div`
  padding: 0px;
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

const FilterBoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ExpensesPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [selectedExpenses, setSelectedExpenses] = useState<string[]>([]);
  const navigate = useNavigate();
  const { data: organizationId } = useOrganization();
  const [editingExpense, setEditingExpense] = useState<Expense | null>(null);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [activeTab, setActiveTab] = useState("all");
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<Filter[]>([]);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const { setPageHeaderProps } = usePageContext();

  const {
    isExpenseDrawerOpen,
    setIsExpenseDrawerOpen,
    handleDeleteExpense,
    handleSaveExpense,
    handleCreateExpense: originalHandleCreateExpense,
  } = useExpenseOperations(undefined, undefined, organizationId);

  const handleEditExpense = useCallback((expense: Expense) => {
    setEditingExpense(expense);
    setIsExpenseDrawerOpen(true);
  }, [setIsExpenseDrawerOpen]);

  const handleCreateExpenseWithRefresh = useCallback(async (expenseData: Partial<Expense>): Promise<Expense> => {
    if (!organizationId) {
      throw new Error("No organization selected. Please select an organization and try again.");
    }

    const newExpense = await originalHandleCreateExpense(expenseData);
    
    queryClient.invalidateQueries({ queryKey: ["expenses", organizationId] });
    
    if (newExpense.project_id) {
      queryClient.invalidateQueries({ queryKey: ["projectExpenses", newExpense.project_id] });
    }
    if (newExpense.client_id) {
      queryClient.invalidateQueries({ queryKey: ["clientExpenses", newExpense.client_id] });
    }

    return newExpense;
  }, [organizationId, originalHandleCreateExpense, queryClient]);

  const fetchExpenses = useCallback(async () => {
    if (!organizationId) throw new Error("No organization found");
  
    const { data, error } = await supabase
      .from("expenses")
      .select(`
        *,
        client:clients(id, full_name),
        project:projects(id, name),
        expense_invoice_items(id, invoice_item_id, amount, invoice_item:invoice_items(invoice_id))
      `)
      .eq("organization_id", organizationId)
      .order("date", { ascending: false });
  
    if (error) throw error;
    return data;
  }, [organizationId]);

  const { data: expenses, isLoading } = useQuery<Expense[]>({
    queryKey: ["expenses", organizationId],
    queryFn: fetchExpenses,
    staleTime: 5 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
    enabled: !!organizationId,
  });

  const filterOptions: FilterOption[] = [
    { id: 'status', label: 'Status', type: 'select', options: ['Not Invoiced', 'Invoiced'] },
    { id: 'client', label: 'Client', type: 'multipleEntity' },
    { 
      id: 'date', 
      label: 'Date', 
      type: 'dateRange',
      options: [
        'Last Week',
        'Last 30 Days',
        'This Month',
        'Last Month',
        'Last 6 Months',
        'Last Year'
      ]
    },
  ];

  const getFilterOptions = (filterId: string) => {
    const filter = filterOptions.find(f => f.id === filterId);
    return filter?.options;
  };

  const handleAddFilter = useCallback((filterId: FilterId) => {
    if (!filters.some(filter => filter.id === filterId)) {
      const filterOption = filterOptions.find(option => option.id === filterId);
      if (filterOption) {
        const newFilter: Filter = {
          id: filterId,
          label: filterOption.label,
          value: filterId === 'client' ? [] : 
                 filterId === 'date' ? { startDate: null, endDate: null } : ''
        };
        setFilters(prevFilters => [...prevFilters, newFilter]);
      }
    }
  }, [filters, filterOptions]);

  const handleRemoveFilter = useCallback((filterId: FilterId) => {
    setFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
  }, []);

  const handleFilterChange = useCallback((filterId: FilterId, value: Filter['value']) => {
    setFilters(prevFilters => 
      prevFilters.map(filter => 
        filter.id === filterId ? { ...filter, value } : filter
      )
    );
  }, []);

  const clientEntities = useMemo(() => {
    return expenses?.reduce((acc, expense) => {
      if (expense.client?.id && expense.client?.full_name) {
        acc.push({
          id: expense.client.id,
          name: expense.client.full_name
        });
      }
      return acc;
    }, [] as { id: string; name: string }[]) || [];
  }, [expenses]);

  const handleApplyFilters = () => {
    setIsFilterDrawerOpen(false);
  };

  const memoizedExpenses = useMemo(() => {
    if (!expenses) return [];
    
    let filtered = expenses;

    // Apply filters
    filters.forEach((filter: Filter) => {
      switch (filter.id) {
        case 'status': {
          const statusFilter = filter.value as string;
          filtered = filtered.filter(expense => 
            statusFilter === 'Invoiced' 
              ? expense.expense_invoice_items && expense.expense_invoice_items.length > 0
              : !expense.expense_invoice_items || expense.expense_invoice_items.length === 0
          );
          break;
        }
        case 'client': {
          const clientFilter = filter.value as string[];
          filtered = filtered.filter(expense => 
            expense.client_id && clientFilter.includes(expense.client_id)
          );
          break;
        }
        case 'date': {
          const dateFilter = filter.value as { startDate: Date | null; endDate: Date | null } | string;
          if (typeof dateFilter === 'object' && 'startDate' in dateFilter && 'endDate' in dateFilter) {
            const { startDate, endDate } = dateFilter;
            if (startDate && endDate) {
              filtered = filtered.filter(expense => {
                const expenseDate = new Date(expense.date);
                return isWithinInterval(expenseDate, { 
                  start: startOfDay(startDate), 
                  end: endOfDay(endDate) 
                });
              });
            }
          } else if (typeof dateFilter === 'string') {
            const { start, end } = getDateRange(dateFilter);
            filtered = filtered.filter(expense => {
              const expenseDate = new Date(expense.date);
              return isWithinInterval(expenseDate, { start, end });
            });
          }
          break;
        }
      }
    });

    // Filter by date range
    if (dateRange.startDate && dateRange.endDate) {
      const start = dateRange.startDate;
      const end = dateRange.endDate;
      if (start instanceof Date && end instanceof Date) {
        filtered = filtered.filter(expense => {
          const expenseDate = new Date(expense.date);
          return isWithinInterval(expenseDate, {
            start: startOfDay(start),
            end: endOfDay(end)
          });
        });
      }
    }

    // Filter by tab
    switch (activeTab) {
      case "notInvoiced":
        filtered = filtered.filter(expense => !expense.expense_invoice_items || expense.expense_invoice_items.length === 0);
        break;
      case "invoiced":
        filtered = filtered.filter(expense => expense.expense_invoice_items && expense.expense_invoice_items.length > 0);
        break;
      // "all" tab doesn't need filtering
    }

    return filtered;
  }, [expenses, dateRange, activeTab, filters]);

  const selectedRowsArray = useMemo(() => Array.from(selectedExpenses), [selectedExpenses]);

  const isRowSelectable = useCallback((expense: Expense) => {
    return !(expense.expense_invoice_items && expense.expense_invoice_items.length > 0);
  }, []);

  const handleRowClick = useCallback((expense: Expense) => {
    handleEditExpense(expense);
  }, [handleEditExpense]);

  const getRowKey = useCallback((expense: Expense) => expense.id, []);

  const onSelectionChange = useCallback((selectedIds: string[]) => {
    setSelectedExpenses(prevSelected => {
      if (JSON.stringify(prevSelected) !== JSON.stringify(selectedIds)) {
        return selectedIds;
      }
      return prevSelected;
    });
  }, []);

  const columns: ColumnDef<Expense>[] = useMemo(() => [
    {
      accessorKey: 'date',
      header: 'Date',
      cell: ({ getValue }) => new Date(getValue() as string).toLocaleDateString(),
    },
    {
      accessorKey: 'description',
      header: 'Description',
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: ({ getValue }) => `$${(getValue() as number).toFixed(2)}`,
    },
    {
      accessorKey: 'client',
      header: 'Client',
      cell: ({ getValue }) => {
        const client = getValue() as { full_name: string } | null;
        return client ? client.full_name : 'N/A';
      },
    },
    {
      accessorKey: 'project',
      header: 'Project',
      cell: ({ getValue }) => {
        const project = getValue() as { name: string } | null;
        return project ? project.name : 'N/A';
      },
    },
    {
      accessorKey: 'expense_invoice_items',
      header: 'Invoice',
      cell: ({ getValue }) => {
        const items = getValue() as ExpenseInvoiceItem[] | null | undefined;
        if (items && items.length > 0 && items[0].invoice_item?.invoice_id) {
          return (
            <Link 
              to="/invoice/$id" 
              params={{ id: items[0].invoice_item.invoice_id }}
              search={{ from: '/expenses' }}
            >
              View Invoice
            </Link>
          );
        }
        return 'Not Invoiced';
      },
    },
  ], []);

  const handleCreateInvoice = useCallback(async () => {
    if (!organizationId) {
      console.error('No organization found');
      return;
    }

    const selectedExpenseData = memoizedExpenses?.filter(expense => selectedExpenses.includes(expense.id)) || [];

    if (selectedExpenseData.length === 0) {
      console.error('No expenses selected');
      return;
    }

    try {
      const { data: newInvoice, error: invoiceError } = await supabase
        .from('invoices')
        .insert({
          organization_id: organizationId,
          customer: selectedExpenseData[0]?.client?.full_name,
          client_id: selectedExpenseData[0]?.client_id,
          project_id: selectedExpenseData[0]?.project_id || undefined,
          subtotal: selectedExpenseData.reduce((sum, expense) => sum + expense.amount, 0),
          due_date: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
          invoice_date: new Date(),
          status: 'draft',
        })
        .select()
        .single();

      if (invoiceError) throw invoiceError;

      const { data: invoiceItems, error: itemsError } = await supabase
        .from('invoice_items')
        .insert(selectedExpenseData.map((expense, index) => ({
          invoice_id: newInvoice.id,
          description: expense.description,
          quantity: 1,
          price: expense.amount,
          order: index,
          taxable: false,
        })))
        .select();

      if (itemsError) throw itemsError;

      const expenseInvoiceItems = selectedExpenseData.map((expense, index) => ({
        expense_id: expense.id,
        invoice_item_id: invoiceItems[index].id,
        amount: expense.amount,
      }));

      const { error: junctionError } = await supabase
        .from('expense_invoice_items')
        .insert(expenseInvoiceItems);

      if (junctionError) throw junctionError;

      setSelectedExpenses([]);

      queryClient.invalidateQueries({ queryKey: ["expenses", organizationId] });
      queryClient.invalidateQueries({ queryKey: ["invoices", organizationId] });

      navigate({ to: '/invoice/$id', params: { id: newInvoice.id } });
    } catch (error) {
      console.error('Error creating invoice:', error);
      alert('Failed to create invoice. Please try again.');
    }
  }, [organizationId, memoizedExpenses, selectedExpenses, queryClient, navigate]);

  const handleAddNewExpense = useCallback(() => {
    setEditingExpense(null);
    setIsExpenseDrawerOpen(true);
  }, [setIsExpenseDrawerOpen]);

  const handleSaveExpenseWrapper = useCallback((expense: Expense) => {
    handleSaveExpense(expense);
    setEditingExpense(null);
  }, [handleSaveExpense]);


  const tabCounts = useMemo(() => {
    if (!expenses) return { all: 0, notInvoiced: 0, invoiced: 0 };
    return expenses.reduce((acc, expense) => {
      acc.all++;
      if (expense.expense_invoice_items && expense.expense_invoice_items.length > 0) {
        acc.invoiced++;
      } else {
        acc.notInvoiced++;
      }
      return acc;
    }, { all: 0, notInvoiced: 0, invoiced: 0 });
  }, [expenses]);

  const tabs = useMemo(() => [
    { id: "all", label: "All", count: tabCounts.all },
    { id: "notInvoiced", label: "Not Invoiced", count: tabCounts.notInvoiced },
    { id: "invoiced", label: "Invoiced", count: tabCounts.invoiced },
  ], [tabCounts]);

  const handleTabChange = useCallback((tabId: string) => {
    setActiveTab(tabId);
  }, []);

  const emptyStateConfig = {
    icon: <Expense32 />,
    message: "No expenses found",
    subMessage: "Start by adding a new expense to your list.",
    action: {
      label: "Add New Expense",
      onClick: handleAddNewExpense
    }
  };

  useEffect(() => {
    setPageHeaderProps({
      title: "Expenses",
      right: (
        <ButtonGroup>
          <Button buttonType="primary"onClick={handleAddNewExpense}>
            New Expense
          </Button>
          {selectedExpenses.length > 0 && (
            <Button onClick={handleCreateInvoice}>
              Create Invoice from Selected
            </Button>
          )}
        </ButtonGroup>
      ),
    });
  }, [setPageHeaderProps, handleAddNewExpense, handleCreateInvoice, selectedExpenses.length]);

  if (isLoading) return "";

  return (
    <PageContainer ref={containerRef}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        actionButtons={
          <FilterContainer>
            <FilterBoxesWrapper>
              {filters.map(filter => (
                <FilterBox
                  key={filter.id}
                  filter={filter}
                  onRemove={() => handleRemoveFilter(filter.id)}
                  onChange={(value) => handleFilterChange(filter.id, value)}
                  options={getFilterOptions(filter.id)}
                  entities={filter.id === 'client' ? clientEntities : undefined}
                />
              ))}
            </FilterBoxesWrapper>
            <FilterPicker
              options={filterOptions}
              onAddFilter={handleAddFilter}
              activeFilters={filters.map(filter => filter.id)}
            />
        
          </FilterContainer>
        }
      />
      <TableWrapper>
        <DataTable<Expense>
          columns={columns}
          data={memoizedExpenses}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          getRowKey={getRowKey}
          onSelectionChange={onSelectionChange}
          selectedRows={selectedRowsArray}
          isRowSelectable={isRowSelectable}
          containerRef={containerRef}
          emptyState={emptyStateConfig}
        />
      </TableWrapper>
      <AddExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        setIsOpen={setIsExpenseDrawerOpen}
        expense={editingExpense}
        onSave={handleSaveExpenseWrapper}
        onDelete={handleDeleteExpense}
        onCreate={handleCreateExpenseWithRefresh}
        organizationId={organizationId}
      />
      {isSmallScreen && (
        <FilterDrawer
          isOpen={isFilterDrawerOpen}
          setIsOpen={setIsFilterDrawerOpen}
          onApply={handleApplyFilters}
        >
          <MultipleEntityPicker
            selectedIds={filters.find(filter => filter.id === 'client')?.value as string[] || []}
            onChange={(value) => handleFilterChange('client', value)}
            entities={clientEntities}
            label="Clients"
            icon={<Client12 />}
            placement="bottom-start"
          />
          <DateRangePicker
            selectedRange={dateRange}
            onChange={(newRange) => {
              setDateRange(newRange);
              handleFilterChange('date', newRange);
            }}
            label="Date Range"
            id="expense-date-range"
            variant="preview"
            icon={<Calendar12 />}
          />
        </FilterDrawer>
      )}
    </PageContainer>
  );
};

export default ExpensesPage;