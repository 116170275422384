import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { EmailTrackingData } from '../types';

export const useEmailTrackingData = (invoiceId: string | null) => {
  return useQuery<EmailTrackingData | null>({
    queryKey: ['emailTracking', invoiceId],
    queryFn: async () => {
      if (!invoiceId) {
        return null;
      }

      const { data, error } = await supabase
        .from('email_tracking')
        .select('open_count, open_history, recipient_email, sent_at, opened, opened_at')
        .eq('invoice_id', invoiceId)
        .maybeSingle();

      if (error) {
        console.error('Error fetching email tracking data:', error);
        return null;
      }

      // If no data is found, return null instead of throwing an error
      if (!data) {
        console.log(`No email tracking data found for invoice ID: ${invoiceId}`);
        return null;
      }

      return data as EmailTrackingData;
    },
    enabled: !!invoiceId,
    retry: false, // Disable retries for this query
  });
};