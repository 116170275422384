import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Expense } from '../types';
import { useOrganization } from './useOrganization';

export const useCreateExpenseMutation = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  return useMutation({
    mutationFn: async (expense: Partial<Expense>) => {
      if (!organizationId) throw new Error("No organization found");

      const { data, error } = await supabase
        .from("expenses")
        .insert({ ...expense, organization_id: organizationId })
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expenses", organizationId] });
    },
  });
};