import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, TooltipProps, CartesianGrid } from 'recharts';
import styled from 'styled-components';
import { formatDuration } from '../../utils/timeUtils';

const ChartWrapper = styled.div`
  height: 280px;
  margin: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding: 24px 0;
  //background-color: rgba(0, 0, 0, 0.05);
  // gradient from top to bottom
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
`;

const TooltipContainer = styled.div`
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: 
    0 2px 2px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 8px 8px rgba(0, 0, 0, 0.05),
    0 16px 16px rgba(0, 0, 0, 0.05),
    0 32px 32px rgba(0, 0, 0, 0.05),
    0 0 0 0.5px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
`;

const TooltipLabel = styled.p`
  margin: 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

const TooltipValue = styled.p`
  margin: 4px 0 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
`;

interface ChartData {
  date: string;
  totalSeconds: number;
}

interface CustomTooltipProps extends TooltipProps<number, string> {
  active?: boolean;
  payload?: { value: number }[];
  label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const totalSeconds = payload[0].value;
    return (
      <TooltipContainer>
        <TooltipLabel>{`${label}`}</TooltipLabel>
        <TooltipValue>{`${formatDuration(totalSeconds)}`}</TooltipValue>
      </TooltipContainer>
    );
  }
  return null;
};

interface TimeEntriesChartProps {
  data: ChartData[];
}

const TimeEntriesChart: React.FC<TimeEntriesChartProps> = React.memo(({ data }) => {
  const maxHours = useMemo(() => Math.max(...data.map(entry => Math.ceil(entry.totalSeconds / 3600))), [data]);
  const tickInterval = useMemo(() => Math.ceil(maxHours / 4), [maxHours]);
  const yAxisTicks = useMemo(() => Array.from(
    { length: Math.floor(maxHours / tickInterval) + 1 },
    (_, i) => i * tickInterval * 3600
  ), [maxHours, tickInterval]);

  return (
    <ChartWrapper>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart 
          data={data} 
          margin={{ top: 24, right: 0, left: -1, bottom: 0 }}
        >
          <CartesianGrid 
            horizontal={true}
            vertical={false}
            stroke="rgba(0, 0, 0, 0.05)"
            strokeWidth={1}
          />
          <XAxis 
            dataKey="date" 
            tick={{ fontSize: 12, fill: 'rgba(0, 0, 0, 0.5)', fontWeight: 500, dx: 0, dy: 8 }}
            tickLine={false}
            axisLine={false}
            padding={{ left: 50, right: 100 }}
          />
          <YAxis 
            tickFormatter={(value) => `${value / 3600}h`}
            ticks={yAxisTicks}
            domain={[0, (dataMax: number) => Math.ceil(dataMax / 3600) * 3600]}
            allowDataOverflow={true}
            orientation="right"
            tick={{ 
              fontSize: 12, 
              fill: 'rgba(0, 0, 0, 0.5)',
              fontWeight: 500,
              dx: -60,
              dy: -12,
              textAnchor: 'end',
            }}
            tickLine={false}
            axisLine={false}
            width={1}
          />
          <Tooltip 
            content={<CustomTooltip />} 
            cursor={{ fill: 'transparent' }}
          />
          <Bar 
            dataKey="totalSeconds" 
            fill="#305D50" 
            radius={[4, 4, 0, 0]}
            maxBarSize={50}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
});

export default TimeEntriesChart;