import React from "react";
import styled from "styled-components";

const HeaderStyleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 8px;
  row-gap: 8px;
  margin-bottom: 20px;
  position: relative;
  box-sizing: border-box;
`;

const HeaderStyleOption = styled.div<{ selected: boolean }>`
  border: 1.5px solid ${(props) => (props.selected ? "#eeb830" : "rgba(0,0,0,0.2)")};
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  background-color: ${(props) => (props.selected ? "#eeb8302b" : "white")};
  user-select: none;
  aspect-ratio: 4/3;
  transition: border-color 0.1s ease-in-out, transform 0.1s ease-in-out;

  &:hover {
    border-color: #eeb830;
  }

  &:active {
    transform: scale(0.99);
  }
`;

const BottomGradient = styled.div`
`;

const ComponentWrapper = styled.div`
  position: relative;
`;

interface HeaderStyleSelectorProps {
  selectedTemplate: string;
  onTemplateChange: (template: string) => void;
}

const HeaderStyleSelector: React.FC<HeaderStyleSelectorProps> = ({
  selectedTemplate,
  onTemplateChange,
}) => {
  const templates = [
    "simple",
    "detailed",
    "modern",
    "minimalist",
    "gradient",
    "boxed",
    "split",
    "circular",
    "compact",
  ];

  return (
    <ComponentWrapper>
    <HeaderStyleGrid>
      {templates.map((template) => (
        <div key={template}>
          <HeaderStyleOption
            selected={selectedTemplate === template}
            onClick={() => onTemplateChange(template)}
          />
        </div>
      ))}
    </HeaderStyleGrid>
    <BottomGradient />
    </ComponentWrapper>
  );
};

export default HeaderStyleSelector;