import React, { useState, useEffect } from "react";
import RightDrawerComponent from "../RightDrawer";
import { supabase } from "../supabaseClient";
import { Payment } from "../types";

interface PaymentsDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  payments: Payment[];
  addPayment: (payment: Omit<Payment, "id"> & { user_id: string }) => void;
  removePayment: (paymentId: string) => void;
  amount_due: number;
  organizationId: string;
  invoiceStatus: string;
}

const PaymentsDrawer: React.FC<PaymentsDrawerProps> = ({
  isOpen,
  setIsOpen,
  payments,
  addPayment,
  removePayment,
  amount_due,
  organizationId,
  invoiceStatus,
}) => {
  const [newPayment, setNewPayment] = useState<
    Omit<Payment, "id" | "user_id" | "organization_id">
  >({
    amount: amount_due || 0,
    payment_date: new Date().toISOString().split("T")[0],
    notes: "",
    invoice_id: "",
  });

  useEffect(() => {
    setNewPayment((prev) => ({ ...prev, amount: amount_due || 0 }));
  }, [amount_due]);

  const handleAddPayment = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      console.error("User not authenticated");
      return;
    }
    if (!organizationId) {
      console.error("Organization not found");
      return;
    }

    if (newPayment.amount > 0) {
      addPayment({
        ...newPayment,
        user_id: user.id,
        organization_id: organizationId,
      });
      setNewPayment({
        amount: amount_due,
        payment_date: new Date().toISOString().split("T")[0],
        notes: "",
        invoice_id: "",
      });
    } else {
      alert("Please enter a valid payment amount.");
    }
  };

  return (
    <RightDrawerComponent
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Payments"
      description="Add and modify your invoice payments"
    >
      <div>
        <h2>Payments</h2>
        <p>Invoice Status: {invoiceStatus}</p>
        <div>
          <input
            type="number"
            value={newPayment.amount}
            onChange={(e) =>
              setNewPayment({
                ...newPayment,
                amount: parseFloat(e.target.value) || 0,
              })
            }
            placeholder="Amount"
          />
          <input
            type="date"
            value={newPayment.payment_date ?? ""}
            onChange={(e) =>
              setNewPayment({ ...newPayment, payment_date: e.target.value })
            }
          />
          <input
            type="text"
            value={newPayment.notes ?? ""}
            onChange={(e) =>
              setNewPayment({ ...newPayment, notes: e.target.value })
            }
            placeholder="Notes"
          />
          <button onClick={handleAddPayment}>Save Payment</button>
        </div>
        {payments.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Notes</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{new Date(payment.payment_date).toLocaleDateString()}</td>
                  <td>${payment.amount.toFixed(2)}</td>
                  <td>{payment.notes}</td>
                  <td>
                    <button onClick={() => removePayment(payment.id)}>
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No payments recorded yet.</p>
        )}
      </div>
    </RightDrawerComponent>
  );
};

export default PaymentsDrawer;