import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InvoiceTemplatesProps } from './types';
import { ClientSelector } from '../components/Clients/ClientSelector';
import DatePicker from '../components/DatePicker';
import { addDays } from 'date-fns';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import AnimatedAmount from '../components/AnimatedAmount';

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div.attrs<{ $headerColor: string }>(props => ({
  style: {
    background: `linear-gradient(135deg, ${props.$headerColor}, ${props.$headerColor}aa)`,
  },
}))`
  padding: 50px;
  border-radius: 0 0 30px 30px;
`;

const HeaderContent = styled.div<{ $headerTextColor: string }>`
  color: ${props => props.$headerTextColor || 'inherit'};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  margin: 0 0 20px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const InvoiceDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DetailRow = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`;

const Input = styled.input`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  margin: 0;
  color: inherit;
  &::placeholder {
    color: inherit;
  }
`;

const Logo = styled.img`
  max-width: 200px;
  max-height: 100px;
  object-fit: contain;
  margin-bottom: 20px;
`;

export const GradientHeader: React.FC<InvoiceTemplatesProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
  }, [invoice.invoice_number]);

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const totals = calculateInvoiceTotals(invoice as InvoiceData);

  return (
    <FontWrapper $font={invoice.font || 'Arial'}>
      <HeaderContainer $headerColor={invoice.header_color}>
        <HeaderContent $headerTextColor={invoice.header_text_color}>
          {invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
          <InvoiceTitle>Invoice</InvoiceTitle>
          <ClientSelectorComponent
            clients={clients}
            selectedClientId={invoice.client_id || null}
            onSelectClient={(clientId) => updateInvoice('client_id', clientId)}
            disabled={isReadOnly}
            onCreateNewClient={onCreateNewClient}
          />
          <InvoiceDetails>
            <DetailColumn>
              <DetailRow>
                <DatePicker
                  id="invoice-date"
                  label="Invoice Date"
                  selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                  onChange={handleInvoiceDateChange}
                  disabled={isReadOnly}
                  isInvoiceDate={true}
                />
              </DetailRow>
              <DetailRow>
                <DatePicker
                  id="due-date"
                  label="Due Date"
                  selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
                  onChange={handleDueDateChange}
                  disabled={isReadOnly}
                  onSetDueDate={handleDueDaysChange}
                  dueDays={invoice.due_days ?? 0}
                  invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                />
              </DetailRow>
            </DetailColumn>
            <DetailColumn>
              <DetailRow>
                <Input
                  type="text"
                  value={localInvoiceNumber}
                  onChange={handleInvoiceNumberChange}
                  placeholder="Invoice #"
                  readOnly={isReadOnly}
                />
              </DetailRow>
              <DetailRow>
                <span>Amount Due: </span>
                <AnimatedAmount value={totals.amountDue} />
              </DetailRow>
            </DetailColumn>
          </InvoiceDetails>
        </HeaderContent>
      </HeaderContainer>
    </FontWrapper>
  );
};