import React from 'react';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const LoadingText = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: rgba(0,0,0,0.25);
`;

const LoadingScreen: React.FC = () => {
  return (
    <LoadingWrapper>
      <LoadingText>Loading...</LoadingText>
    </LoadingWrapper>
  );
};

export default LoadingScreen;