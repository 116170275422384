import styled, { css } from 'styled-components';
import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $primary?: boolean;
  $size?: 'small' | 'medium' | 'large' | 'icon';
  $buttonType?: 'primary' | 'secondary' | 'icon';
  $as?: React.ElementType;
}

const StyledButton = styled.button<ButtonProps>`
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: background-color 0.2s ease, color 0.2s ease;
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  ${({ $buttonType }) => {
    switch ($buttonType) {
      case 'primary':
        return css`
          background-color: #FFFFFF;
          color: rgba(0,0,0,0.6);
          box-shadow: 0 1px 0 rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.05), 0 1px 4px rgba(0,0,0,0.05), 0 2px 6px rgba(0,0,0,0.05);
          transition: transform 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
          &:hover {
            color: rgba(0,0,0,0.8);
            box-shadow: 0 1px 0 rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1), 0 2px 4px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.05);
          }
          &:active {
            margin-top: 0px;
            transform: scale(0.98);
            box-shadow: 0 0 0 0.5px rgba(0,0,0,0.05), 0 1px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05);

          }
        `;
      case 'secondary':
        return css`
          background-color: transparent;
          color: rgba(0,0,0,0.5);
          &:hover {
            color: rgba(0,0,0,0.8);
            background-color: rgba(0,0,0,0.1);
          }
        `;
      case 'icon':
        return css`
          width: 28px;
          height: 28px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          color: rgba(0,0,0,0.5);
          &:hover {
            color: rgba(0,0,0,0.8);
            background-color: rgba(0,0,0,0.1);
            svg path {
              fill: rgba(0,0,0,0.8);
            }
          }
          svg path {
            fill: rgba(0,0,0,0.5);
          }
        `;
      default:
        return css`
          background-color: rgba(0,0,0,0.05);
          color: rgba(0,0,0,0.5);
          svg path {
            fill: rgba(0,0,0,0.5);
          }
          &:hover {
            background-color: rgba(0,0,0,0.1);
            color: rgba(0,0,0,0.8);
            svg path {
              fill: rgba(0,0,0,0.8);
            }
          }
        `;
    }
  }}

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return 'padding: 4px 12px; font-size: 14px;';
      case 'large':
        return 'padding: 12px 24px; font-size: 14px;';
      case 'icon':
        return css`
          width: 28px;
          height: 28px;
          padding: 0;
        `;
      default:
        return 'padding: 4px 8px; font-size: 14px;';
    }
  }}
`;

interface ComponentProps extends Omit<ButtonProps, '$primary' | '$size' | '$buttonType' | '$as'> {
  primary?: boolean;
  size?: 'small' | 'medium' | 'large' | 'icon';
  buttonType?: 'primary' | 'secondary' | 'icon';
  as?: React.ElementType;
}

const Button: React.FC<ComponentProps> = ({ primary, size, buttonType, as, children, ...props }) => (
  <StyledButton $primary={primary} $size={size} $buttonType={buttonType} as={as} {...props}>{children}</StyledButton>
);

export default Button;