import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Payment, EmailTrackingData } from '../../types';
import { Drawer } from 'vaul';
import { Close12, Money12, Eye12, Invoice12 } from '../Icon'; // Add necessary icons
import Button from '../Button';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;

  @media (max-width: 768px) {
    max-width: 100%;
    height: 90vh;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  background-color: rgba(248, 247, 246, 1);
  height: 100%;
  overflow-y: scroll;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const TitleSection = styled.div`
  padding: 24px 40px;
`;

const DrawerTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const HistoryContainer = styled.div`
  padding: 0 40px;
`;

const HistoryItem = styled.div`
`;

const HistoryDate = styled.div<{ $isLast: boolean }>`
  font-size: 14px;
  margin-top: 4px;
  border-left: 1px solid ${props => props.$isLast ? 'transparent' : 'rgba(0,0,0,0.1)'};
  padding: 0 0 24px 22px;
  margin-left: 6px;
  color: rgba(0,0,0,0.5);
`;

const HistoryEvent = styled.div`
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;

  svg path {
    fill: rgba(0,0,0,0.5);
  }
`;  

interface InvoiceHistoryDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  payments: Payment[];
  emailTrackingData: EmailTrackingData | null;
  invoice_created_at: string; // Add this line
}

const InvoiceHistoryDrawer: React.FC<InvoiceHistoryDrawerProps> = ({
  isOpen,
  setIsOpen,
  payments,
  emailTrackingData,
  invoice_created_at, // Add this line
}) => {
  const historyItems = [
    {
      date: new Date(invoice_created_at), // Add this line
      event: 'Invoice created',
      type: 'creation'
    },
    ...(payments || []).map(payment => ({
      date: new Date(payment.payment_date),
      event: `Payment received: ${payment.amount}`,
      type: 'payment'
    })),
    ...(emailTrackingData?.open_history || []).map(openEvent => ({
      date: new Date(openEvent),
      event: 'Invoice viewed',
      type: 'view'
    }))
  ].sort((a, b) => b.date.getTime() - a.date.getTime());

  const handleClose = () => setIsOpen(false);

  const getIconForType = (type: string) => {
    switch (type) {
      case 'creation':
        return <Invoice12 />;
      case 'payment':
        return <Money12 />;
      case 'view':
        return <Eye12 />;
      default:
        return null;
    }
  };

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={setIsOpen}
      direction="right"
      modal={true}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent>
            <TitleSection>
              <DrawerTitle>Invoice History</DrawerTitle>
            </TitleSection>
            <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            <HistoryContainer>
              {historyItems.map((item, index) => (
                <HistoryItem key={index}>
                  <HistoryEvent>{getIconForType(item.type)}{item.event}</HistoryEvent>
                  <HistoryDate $isLast={index === historyItems.length - 1}>
                    {format(item.date, 'MMM d, yyyy HH:mm')}
                  </HistoryDate>
                </HistoryItem>
              ))}
              {historyItems.length === 0 && (
                <HistoryItem>No history available</HistoryItem>
              )}
            </HistoryContainer>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default InvoiceHistoryDrawer;