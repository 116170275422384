import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const AnimatedContainer = styled.div`
  display: inline-flex;
  overflow: hidden;
  height: 1.1em;
  line-height: 1.1em;
  justify-content: center;
  font-variant-numeric: tabular-nums;
`;

const AnimatedDigit = styled.div`
  display: inline-block;
  height: 1em;
  transition: transform 0.3s ease-out;
  text-align: center;
`;

interface AnimatedAmountProps {
  value: number;
}

const AnimatedAmount: React.FC<AnimatedAmountProps> = ({ value }) => {
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  const renderDigits = () => {
    const isNegative = displayValue < 0;
    const absValue = Math.abs(displayValue);
    const formattedValue = absValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const digits = (isNegative ? '-' : '') + formattedValue;
    
    return digits.split("").map((digit, index) => (
      <AnimatedContainer key={index} style={{ width: ["-", ".", ",", "$"].includes(digit) ? 'auto' : 'ch' }}>
        {["-", ".", ",", "$"].includes(digit) ? (
          <div>{digit}</div>
        ) : (
          <AnimatedDigit
            style={{
              transform: `translateY(calc(${-Number(digit)} * 1em))`,
              width: '1ch',
            }}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => (
              <div key={n} style={{ height: "1em" }}>{n}</div>
            ))}
          </AnimatedDigit>
        )}
      </AnimatedContainer>
    ));
  };

  return <div style={{ display: 'inline-flex' }}>{renderDigits()}</div>;
};

export default AnimatedAmount;