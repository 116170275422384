import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from './supabaseClient';
import styled from 'styled-components';
import { Payment } from './types';
import { Link } from '@tanstack/react-router';
import { useOrganization } from './hooks/useOrganization';
import DataTable from './components/DataTable';
import { ColumnDef } from '@tanstack/react-table';
import DateRangePicker from './components/DateRangePicker';
import { Calendar12, Payments32 } from './components/Icon';
import { isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import Tabs from './components/Tabs';
import { usePageContext } from './hooks/usePageContext';

const PageContainer = styled.div`
  padding: 0px;
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const PaymentsPage: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const { data: organizationId } = useOrganization();
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [activeTab, setActiveTab] = useState('all');
  const containerRef = useRef<HTMLDivElement>(null);

  const fetchPayments = useCallback(async () => {
    if (!organizationId) throw new Error('No organization found');
  
    const { data, error } = await supabase
      .from('payments')
      .select('*, invoices(id, invoice_number)')
      .eq('organization_id', organizationId)
      .order('payment_date', { ascending: false });
  
    if (error) throw error;
    return data;
  }, [organizationId]);

  const { data: payments, isLoading } = useQuery<(Payment & { invoices: { id: string; invoice_number: string } })[]>({
    queryKey: ['payments', organizationId],
    queryFn: fetchPayments,
    staleTime: 5 * 60 * 1000,
    gcTime: 15 * 60 * 1000,
    enabled: !!organizationId,
  });

  const memoizedPayments = useMemo(() => {
    if (!payments) return [];
    
    let filtered = payments;

    // Filter by date range
    if (dateRange.startDate && dateRange.endDate) {
      filtered = filtered.filter(payment => {
        const paymentDate = new Date(payment.payment_date);
        return dateRange.startDate && dateRange.endDate && isWithinInterval(paymentDate, {
          start: startOfDay(dateRange.startDate),
          end: endOfDay(dateRange.endDate)
        });
      });
    }

    // Filter by tab (if needed in the future)
    switch (activeTab) {
      case 'all':
      default:
        return filtered;
    }
  }, [payments, dateRange, activeTab]);

  const columns = useMemo<ColumnDef<Payment & { invoices: { id: string; invoice_number: string } }>[]>(() => [
    {
      accessorKey: 'payment_date',
      header: 'Date',
      cell: ({ getValue }) => new Date(getValue() as string).toLocaleDateString(),
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: ({ getValue }) => `$${(getValue() as number).toFixed(2)}`,
    },
    {
      accessorKey: 'invoices',
      header: 'Invoice',
      cell: ({ getValue }) => {
        const invoice = getValue() as { id: string; invoice_number: string };
        return invoice && invoice.id ? (
          <Link to="/invoice/$id" params={{ id: invoice.id }}>
            {invoice.invoice_number || 'No number'}
          </Link>
        ) : (
          'N/A'
        );
      },
    },
    {
      accessorKey: 'notes',
      header: 'Notes',
    },
  ], []);

  const handleDateRangeChange = useCallback((newRange: { startDate: Date | null; endDate: Date | null }) => {
    setDateRange(newRange);
  }, []);

  const tabs = useMemo(() => [
    { id: 'all', label: 'All' },
  ], []);

  const handleTabChange = useCallback((tabId: string) => {
    setActiveTab(tabId);
  }, []);

  const emptyState = {
    icon: <Payments32 />,
    message: "No payments recorded",
    subMessage: "Payments will appear here once they are processed.",
    action: {
      label: "Add a payment",
      onClick: () => {
        // Add logic to navigate to payment creation page or open a modal
        console.log("Add payment action clicked");
      }
    }
  };

  useEffect(() => {
    setPageHeaderProps({
      title: "Payments",
    });
  }, [setPageHeaderProps]);

  if (isLoading) return "";

  return (
    <PageContainer ref={containerRef}>
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        actionButtons={
          <DateRangePicker
            selectedRange={dateRange}
            onChange={handleDateRangeChange}
            label="Date Range"
            id="payment-date-range"
            variant="preview"
            icon={<Calendar12 />}
          />
        }
      />
      <TableWrapper>
        <DataTable<Payment & { invoices: { id: string; invoice_number: string } }>
          columns={columns}
          data={memoizedPayments}
          isLoading={isLoading}
          onRowClick={() => {}} // Add appropriate handler if needed
          getRowKey={(row) => row.id}
          onSelectionChange={() => {}} // Add appropriate handler if needed
          containerRef={containerRef}
          emptyState={emptyState}
        />
      </TableWrapper>
    </PageContainer>
  );
};

export default PaymentsPage;