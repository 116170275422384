import { startOfDay, endOfDay, subDays, startOfMonth, endOfMonth, subMonths, subYears } from 'date-fns';

export const getDateRange = (option: string): { start: Date, end: Date } => {
  const now = new Date();
  switch (option) {
    case 'Last Week':
      return { start: startOfDay(subDays(now, 7)), end: endOfDay(now) };
    case 'Last 30 Days':
      return { start: startOfDay(subDays(now, 30)), end: endOfDay(now) };
    case 'This Month':
      return { start: startOfMonth(now), end: endOfDay(now) };
    case 'Last Month': {
      const lastMonth = subMonths(now, 1);
      return { start: startOfMonth(lastMonth), end: endOfMonth(lastMonth) };
    }
    case 'Last 6 Months':
      return { start: startOfDay(subMonths(now, 6)), end: endOfDay(now) };
    case 'Last Year':
      return { start: startOfDay(subYears(now, 1)), end: endOfDay(now) };
    default:
      return { start: startOfDay(now), end: endOfDay(now) };
  }
};

export const formatDate = (dateString: string | null) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
};