import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "./supabaseClient";
import styled from "styled-components";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import HeaderPreview from "./components/Invoices/HeaderPreview";
import InvoicePreview from "./InvoicePreview";
import ColorPickerWithPopover from "./components/ColorPicker";
import FontPicker from "./components/FontPicker";
import HeaderStyleSelector from "./components/Invoices/HeaderStyleSelector";
import Switch from "./components/Switch";
import chroma from "chroma-js";
import { useOrganization } from "./hooks/useOrganization";
import { debounce } from "lodash";
import ImageUploader from "./components/ImageUploader";
import { usePageContext } from "./hooks/usePageContext";
import { useBrandSettings } from "./hooks/useBrandSettings";

const BrandContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const BrandPreview = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  overflow: scroll;
  padding-top: 32px;
`;

const InvoicePreviewContainer = styled.div.attrs<{ $backgroundColor: string }>(
  (props) => ({
    style: {
      backgroundColor: props.$backgroundColor,
    },
  })
)`
  transform: scale(0.85);
  max-width: 880px;
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  box-shadow: 
  0 8px 8px rgba(0, 0, 0, 0.05),
  0 24px 32px rgba(0, 0, 0, 0.05),
  0 40px 64px rgba(0, 0, 0, 0.05),
  0 64px 80px rgba(0, 0, 0, 0.05);
  transform-origin: top center;
`;

const BrandSidebar = styled.div`
  padding: 0 40px 40px 0;
  min-width: 312px;
  box-sizing: border-box;
`;

const StatusUpdate = styled.div`
  position: absolute;
  opacity: 0.5;
  bottom: 16px;
`;

const GroupHeader = styled.h3`
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.5);
  padding: 0 0 8px;
  margin: 32px 0 12px 0;
  font-weight: 500;
  user-select: none;
`;

const PickerGroup = styled.div`
  display: flex;
  gap: 24px;
`;

const debouncedUpdateSettings = debounce((callback: () => void) => {
  callback();
}, 500);

const Brand: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const { data: brandSettings, isLoading: isLoadingBrandSettings } = useBrandSettings();

  const [brandState, setBrandState] = useState({
    defaultTemplate: "",
    defaultFont: "",
    defaultHeaderColor: "",
    defaultBackgroundColor: "",
    defaultHeaderTextColor: "",
    defaultBodyTextColor: "",
    autoColorText: false,
  });
  const [shouldUpdateSettings, setShouldUpdateSettings] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const fonts = [
    "Inter",
    "Montserrat",
    "IBM Plex Mono",
    "Merriweather",
    "Playfair Display",
    "Lora",
    "Barlow",
    "PT Serif",
    "Noto Serif",
    "Space Grotesk",
  ];

  const updateBrandSettingsMutation = useMutation({
    mutationFn: async (newSettings: {
      default_template: string;
      default_font: string;
      default_header_color: string;
      default_background_color: string;
      default_header_text_color: string;
      default_body_text_color: string;
      auto_color_text: boolean;
    }) => {
      if (!organizationId) throw new Error("Organization not found");

      const { data: updatedData, error: updateError } = await supabase
        .from("brand_settings")
        .upsert(
          { organization_id: organizationId, ...newSettings },
          { onConflict: "organization_id", ignoreDuplicates: false }
        )
        .select()
        .single();

      if (updateError) {
        console.error("Supabase error:", updateError);
        throw new Error(
          `Failed to update brand settings: ${updateError.message}`
        );
      }
      return updatedData;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['brandSettings', organizationId],
      });
    },
    onError: (error: Error) => {
      console.error("Mutation error:", error.message);
    },
  });

  const handleLogoUpload = useCallback(async (uploadedLogoUrl: string) => {
    setLogoUrl(uploadedLogoUrl);
    
    try {
      const { error } = await supabase
        .from('organizations')
        .update({ logo_url: uploadedLogoUrl })
        .eq('id', organizationId);

      if (error) throw error;

      // Invalidate the organization query to refetch the updated data
      queryClient.invalidateQueries({
        queryKey: ['organization', organizationId],
      });
    } catch (error) {
      console.error('Error updating logo:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  }, [organizationId, queryClient]);

  useEffect(() => {
    if (brandSettings) {
      setBrandState({
        defaultTemplate: brandSettings.default_template || "simple",
        defaultFont: brandSettings.default_font || "Arial",
        defaultHeaderColor: brandSettings.default_header_color || "#ffffff",
        defaultBackgroundColor: brandSettings.default_background_color || "#ffffff",
        defaultHeaderTextColor: brandSettings.default_header_text_color || "#000000",
        defaultBodyTextColor: brandSettings.default_body_text_color || "#000000",
        autoColorText: brandSettings.auto_color_text || false,
      });
      setLogoUrl(brandSettings.logo_url);
    }
  }, [brandSettings]);

  useEffect(() => {
    if (shouldUpdateSettings && organizationId) {
      updateBrandSettingsMutation.mutate({
        default_template: brandState.defaultTemplate,
        default_font: brandState.defaultFont,
        default_header_color: brandState.defaultHeaderColor,
        default_background_color: brandState.defaultBackgroundColor,
        default_header_text_color: brandState.defaultHeaderTextColor,
        default_body_text_color: brandState.defaultBodyTextColor,
        auto_color_text: brandState.autoColorText,
      });
      setShouldUpdateSettings(false);
    }
  }, [
    shouldUpdateSettings,
    brandState,
    updateBrandSettingsMutation,
    organizationId,
  ]);

  const handleTemplateChange = useCallback((newTemplate: string) => {
    setBrandState(prevState => ({ ...prevState, defaultTemplate: newTemplate }));
    setShouldUpdateSettings(true);
  }, []);

  const calculateTextColor = useCallback((backgroundColor: string): string => {
    const bgColor = chroma(backgroundColor);
    const isDarkBg = bgColor.luminance() < 0.5;

    let textColor = bgColor
      .set("hsl.h", (bgColor.get("hsl.h") + 30) % 360)
      .set("hsl.s", Math.min(bgColor.get("hsl.s") * 1.5, 1));

    textColor = textColor.set("hsl.l", isDarkBg ? 0.8 : 0.2);

    const minContrast = 3.5;
    let contrast = chroma.contrast(bgColor, textColor);

    const step = isDarkBg ? -0.05 : 0.05;
    while (
      contrast < minContrast &&
      textColor.get("hsl.l") > 0 &&
      textColor.get("hsl.l") < 1
    ) {
      textColor = textColor.set("hsl.l", textColor.get("hsl.l") + step);
      contrast = chroma.contrast(bgColor, textColor);
    }

    return textColor.hex();
  }, []);

  const handleColorChange = useCallback(
    (colorType: 'header' | 'background', newColor: string) => {
      setBrandState(prev => ({
        ...prev,
        [colorType === 'header' ? 'defaultHeaderColor' : 'defaultBackgroundColor']: newColor,
      }));
      if (brandState.autoColorText) {
        const newTextColor = calculateTextColor(newColor);
        setBrandState(prev => ({
          ...prev,
          [colorType === 'header' ? 'defaultHeaderTextColor' : 'defaultBodyTextColor']: newTextColor,
        }));
      }
      debouncedUpdateSettings(() => setShouldUpdateSettings(true));
    },
    [brandState.autoColorText, calculateTextColor]
  );

  const handleTextColorChange = useCallback(
    (colorType: 'header' | 'body', newColor: string) => {
      if (!brandState.autoColorText) {
        setBrandState(prev => ({
          ...prev,
          [colorType === 'header' ? 'defaultHeaderTextColor' : 'defaultBodyTextColor']: newColor,
        }));
        debouncedUpdateSettings(() => setShouldUpdateSettings(true));
      }
    },
    [brandState.autoColorText]
  );

  useEffect(() => {
    if (brandState.autoColorText) {
      const newHeaderTextColor = calculateTextColor(brandState.defaultHeaderColor);
      const newBodyTextColor = calculateTextColor(brandState.defaultBackgroundColor);

      if (
        newHeaderTextColor !== brandState.defaultHeaderTextColor ||
        newBodyTextColor !== brandState.defaultBodyTextColor
      ) {
        setBrandState(prevState => ({
          ...prevState,
          defaultHeaderTextColor: newHeaderTextColor,
          defaultBodyTextColor: newBodyTextColor
        }));
        debouncedUpdateSettings(() => setShouldUpdateSettings(true));
      }
    }
  }, [
    brandState.autoColorText,
    brandState.defaultHeaderColor,
    brandState.defaultBackgroundColor,
    calculateTextColor,
    brandState.defaultHeaderTextColor,
    brandState.defaultBodyTextColor,
  ]);

  const handleLogoRemove = useCallback(async (fileName: string | null) => {
    setLogoUrl(null);
    
    try {
      // Update the organization table
      const { error: updateError } = await supabase
        .from('organizations')
        .update({ logo_url: null })
        .eq('id', organizationId);

      if (updateError) throw updateError;

      // Remove the file from storage if fileName is provided
      if (fileName) {
        const { error: storageError } = await supabase.storage
          .from('logos')
          .remove([fileName]);

        if (storageError) throw storageError;
      }

      // Invalidate the organization query to refetch the updated data
      queryClient.invalidateQueries({
        queryKey: ['organization', organizationId],
      });

    } catch (error) {
      console.error('Error removing logo:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  }, [organizationId, queryClient]);

  useEffect(() => {
    setPageHeaderProps({
      title: "Brand",
    });
  }, [setPageHeaderProps]);

  if (isLoadingBrandSettings) return null;

  return (
    <BrandContainer>
      <BrandPreview>
        <InvoicePreviewContainer $backgroundColor={brandState.defaultBackgroundColor}>
          <HeaderPreview
            template={brandState.defaultTemplate}
            font={brandState.defaultFont}
            headerColor={brandState.defaultHeaderColor}
            headerTextColor={brandState.defaultHeaderTextColor}
            logoUrl={logoUrl}
          />
          <InvoicePreview
            font={brandState.defaultFont}
            headerColor={brandState.defaultHeaderColor}
            headerTextColor={brandState.defaultHeaderTextColor}
            backgroundColor={brandState.defaultBackgroundColor}
            bodyTextColor={brandState.defaultBodyTextColor}
          />
        </InvoicePreviewContainer>
        <StatusUpdate>
          {updateBrandSettingsMutation.isPending && <p>Saving...</p>}
          {updateBrandSettingsMutation.isError && <p>Error saving settings</p>}
          {updateBrandSettingsMutation.isSuccess && (
            <p>Settings saved successfully</p>
          )}
        </StatusUpdate>
      </BrandPreview>
      <BrandSidebar>
        <GroupHeader>Logo</GroupHeader>
        <ImageUploader
          currentImage={logoUrl}
          onUpload={handleLogoUpload}
          onRemove={handleLogoRemove}
        />
        <GroupHeader>Font</GroupHeader>
        <FontPicker
          id="defaultInvoiceFont"
          font={brandState.defaultFont}
          onChange={(font) => {
            setBrandState(prevState => ({ ...prevState, defaultFont: font }));
            setShouldUpdateSettings(true);
          }}
          label="Font"
          fonts={fonts}
        />
        <GroupHeader>Header Style</GroupHeader>
        <HeaderStyleSelector
          selectedTemplate={brandState.defaultTemplate}
          onTemplateChange={handleTemplateChange}
        />
        <GroupHeader>Header</GroupHeader>
        <PickerGroup>
          <ColorPickerWithPopover
            color={brandState.defaultHeaderColor}
            onChange={(newColor) => handleColorChange('header', newColor)}
            label="Background"
            id="default-header-color"
          />
          <ColorPickerWithPopover
            color={brandState.defaultHeaderTextColor}
            onChange={(newColor) => handleTextColorChange('header', newColor)}
            label="Text"
            id="default-header-text-color"
            disabled={brandState.autoColorText}
          />
        </PickerGroup>
        <GroupHeader>Body</GroupHeader>
        <PickerGroup>
          <ColorPickerWithPopover
            color={brandState.defaultBackgroundColor}
            onChange={(newColor) => handleColorChange('background', newColor)}
            label="Background"
            id="default-background-color"
          />
          <ColorPickerWithPopover
            color={brandState.defaultBodyTextColor}
            onChange={(newColor) => handleTextColorChange('body', newColor)}
            label="Text"
            id="default-body-text-color"
            disabled={brandState.autoColorText}
          />
        </PickerGroup>
        <GroupHeader>Auto Color</GroupHeader>
        <Switch
          checked={brandState.autoColorText}
          onChange={(checked) => {
            setBrandState(prevState => ({ ...prevState, autoColorText: checked }));
            setShouldUpdateSettings(true);
          }}
          label="Auto color"
        />
      </BrandSidebar>
    </BrandContainer>
  );
};

export default Brand;