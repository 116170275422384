import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useOrganization } from './useOrganization';
import { supabase } from '../supabaseClient';
import { PendingInvitation } from '../types';

export const useInviteUser = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();

  return useMutation({
    mutationFn: async (email: string): Promise<PendingInvitation> => {
      if (!organizationId) throw new Error('No organization selected');
      console.log('Inviting user:', { email, organizationId });

      const response = await fetch('/api/invite-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, organizationId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to invite user');
      }

      // Fetch the newly created invitation
      const { data: invitation, error } = await supabase
        .from('pending_invitations')
        .select('*')
        .eq('email', email)
        .eq('organization_id', organizationId)
        .single();

      if (error) throw error;

      return invitation as PendingInvitation;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pendingInvitations', organizationId] });
    },
  });
};