import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const HeaderContainer = styled.div.attrs<{
  $headerColor: string;
  $height: number;
  $shouldAnimate: boolean;
}>((props) => ({
  style: {
    height: `${props.$height}px`,
    transition: props.$shouldAnimate ? "height 0.3s ease-in-out" : "none",
    backgroundColor: props.$headerColor,
  },
}))`
  overflow: hidden;
`;

interface AnimatedHeaderProps {
  headerColor: string;
  children: React.ReactNode;
}

const AnimatedHeader: React.FC<AnimatedHeaderProps> = ({
  headerColor,
  children,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    const updateHeight = () => {
      if (headerRef.current) {
        setHeight(headerRef.current.scrollHeight);
      }
    };

    updateHeight();

    // Add event listener for image load
    const images = headerRef.current?.getElementsByTagName('img');
    if (images) {
      Array.from(images).forEach(img => {
        img.addEventListener('load', updateHeight);
      });
    }

    if (!shouldAnimate) {
      setTimeout(() => setShouldAnimate(true), 100);
    }

    // Cleanup
    return () => {
      if (images) {
        Array.from(images).forEach(img => {
          img.removeEventListener('load', updateHeight);
        });
      }
    };
  }, [children, headerColor, shouldAnimate]);

  return (
    <HeaderContainer
      $headerColor={headerColor}
      $height={height}
      $shouldAnimate={shouldAnimate}
    >
      <div ref={headerRef}>{children}</div>
    </HeaderContainer>
  );
};

export default AnimatedHeader;
