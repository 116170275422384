import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Payment } from '../types';
import { useOrganization } from './useOrganization';

export const usePayments = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<Payment[]>({
    queryKey: ['payments', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error('No organization selected');
      const { data, error } = await supabase
        .from('payments')
        .select('*')
        .eq('organization_id', organizationId);
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });
};