import React from 'react';
import { useNavigate } from '@tanstack/react-router';
import { supabase } from './supabaseClient';
import { useAuth } from './hooks/useAuth';
import styled from 'styled-components';
import { useQueryClient, useQuery, useQueries } from '@tanstack/react-query';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const InvitationList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const InvitationItem = styled.li`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const AcceptButton = styled(Button)`
  background-color: #4CAF50;
  color: white;
`;

const RejectButton = styled(Button)`
  background-color: #f44336;
  color: white;
`;

// Update the RawInvitation interface


// Update the Invitation interface
interface Invitation {
  id: string;
  organization_id: string;
  organization_name: string;
  organization_logo: string | null;
  email: string;
}

// Add a new styled component for the logo
const Logo = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
`;

interface Organization {
  id: string;
  name: string;
  logo_url: string | null;
}

const PendingInvitationsPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: user, isLoading: isUserLoading } = useAuth();
  const queryClient = useQueryClient();

  const { data: invitations, isLoading: isInvitationsLoading, error: invitationsError } = useQuery({
    queryKey: ['pendingInvitations', user?.email],
    queryFn: async () => {
      if (!user?.email) throw new Error('User email not available');
      const { data, error } = await supabase
        .from('pending_invitations')
        .select('*')
        .eq('email', user.email);
      if (error) throw error;
      return data;
    },
    enabled: !!user?.email,
  });

  const organizationQueries = useQueries({
    queries: (invitations || []).map((invitation) => ({
      queryKey: ['organization', invitation.organization_id],
      queryFn: async () => {
        const { data, error } = await supabase
          .from('organizations')
          .select('id, name, logo_url')
          .eq('id', invitation.organization_id)
          .single();
        if (error) throw error;
        return data as Organization;
      },
    })),
  });

  const isLoading = isUserLoading || isInvitationsLoading || organizationQueries.some(query => query.isLoading);
  const error = invitationsError || organizationQueries.find(query => query.error)?.error;

  const handleAcceptInvite = async (invitation: Invitation) => {
    try {
      // Add the user to the organization
      const { error: insertError } = await supabase
        .from('organization_members')
        .insert({ user_id: user!.id, organization_id: invitation.organization_id });

      if (insertError) throw insertError;

      // Delete the pending invitation
      await supabase
        .from('pending_invitations')
        .delete()
        .eq('id', invitation.id);

      // Update user metadata
      await supabase.auth.updateUser({
        data: { current_organization_id: invitation.organization_id }
      });

      // Invalidate relevant queries to trigger re-fetching of data
      queryClient.invalidateQueries({ queryKey: ['organization'] });
      queryClient.invalidateQueries({ queryKey: ['userOrganizations'] });
      queryClient.invalidateQueries({ queryKey: ['pendingInvitations', user?.email] });

      // Show a success message
      alert(`You have successfully joined ${invitation.organization_name}`);

      // If it was the last invitation, redirect to home
      if (invitations && invitations.length === 1) {
        navigate({ to: '/' });
      }
    } catch (err) {
      console.error('Error accepting invitation:', err);
      alert('Failed to accept invitation');
    }
  };

  const handleRejectInvite = async (invitation: Invitation) => {
    try {
      // Delete the pending invitation
      const { error } = await supabase
        .from('pending_invitations')
        .delete()
        .eq('id', invitation.id);

      if (error) throw error;

      // Invalidate the pending invitations query to trigger re-fetching
      queryClient.invalidateQueries({ queryKey: ['pendingInvitations', user?.email] });

      // Show a success message
      alert(`You have rejected the invitation from ${invitation.organization_name}`);

      // If it was the last invitation, redirect to home
      if (invitations && invitations.length === 1) {
        navigate({ to: '/' });
      }
    } catch (err) {
      console.error('Error rejecting invitation:', err);
      alert('Failed to reject invitation');
    }
  };

  if (isLoading) {
    return <PageWrapper>Loading...</PageWrapper>;
  }

  if (error) {
    return <PageWrapper>Error: {(error as Error).message}</PageWrapper>;
  }

  if (!invitations || invitations.length === 0) {
    return <PageWrapper>No pending invitations.</PageWrapper>;
  }

  return (
    <PageWrapper>
      <h1>Pending Invitations</h1>
      <InvitationList>
        {invitations.map((invitation, index) => {
          const organization = organizationQueries[index].data;
          return (
            <InvitationItem key={invitation.id}>
              {organization?.logo_url && (
                <Logo src={organization.logo_url} alt={`${organization.name} logo`} />
              )}
              Invitation to join <strong>{organization?.name || 'Unknown Organization'}</strong>
              <ButtonGroup>
                <AcceptButton onClick={() => handleAcceptInvite({...invitation, organization_name: organization?.name || 'Unknown Organization'})}>Accept</AcceptButton>
                <RejectButton onClick={() => handleRejectInvite({...invitation, organization_name: organization?.name || 'Unknown Organization'})}>Reject</RejectButton>
              </ButtonGroup>
            </InvitationItem>
          );
        })}
      </InvitationList>
    </PageWrapper>
  );
};

export default PendingInvitationsPage;