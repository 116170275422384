import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';

export const useUser = () => {
  return useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;
      if (!user) return null;

      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;

      return { ...user, profile };
    },
    gcTime: 0, // Don't cache the data
    refetchInterval: 0, // Refetch on every render
  });
};