import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Task, Client, Project, OrganizationUser, TaskStatus } from '../../types';
import DatePicker from '../DatePicker';
import EntityPicker from '../EntityPicker';
import { Calendar12, Client12, Todo12, InProgress12, Done12 } from '../Icon';
import { debounce } from 'lodash';

// Add this type definition at the top of the file, after the imports
type TaskStatusCategory = 'Todo' | 'In Progress' | 'Complete';

const TaskWrapper = styled.div<{ $isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 40px 8px 32px;
  cursor: pointer;
  background-color: ${props => props.$isSelected ? 'rgba(0,0,0,0.05)' : 'transparent'};
  transition: background-color 0.1s ease-in-out;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  box-shadow: ${props => props.$isSelected ? '0 1px 0 rgba(0,0,0,0.1);' : '0 1px 0 rgba(0,0,0,0.05);'};
  width: 100%;
  overflow: hidden;

  &:hover {
    background-color: ${props => props.$isSelected ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0.05)'};
  }
`;

const EntityWrapper = styled.div``;

const Description = styled.span`
  flex-grow: 1;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DatePickerWrapper = styled.div`
  margin-left: 8px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const StatusIconWrapper = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  svg path {
    fill: ${props => props.$color};
  }
`;

interface TaskComponentProps {
  task: Task;
  onImmediateUpdate: (updatedTask: Partial<Task>, isEditing?: boolean) => void;
  isSelected: boolean;
  clients: Client[];
  projects: Project[];
  organizationUsers: OrganizationUser[];
  taskStatuses: TaskStatus[];
  onClick: () => void;
}

const TaskComponent: React.FC<TaskComponentProps> = ({
  task,
  onImmediateUpdate,
  isSelected,
  clients,
  projects,
  taskStatuses,
  organizationUsers,
  onClick,
}) => {
  // Create a debounced version of `onImmediateUpdate`
  const debouncedUpdate = useMemo(
    () =>
      debounce((updatedTask: Partial<Task>, isEditing: boolean) => {
        onImmediateUpdate(updatedTask, isEditing);
      }, 500),
    [onImmediateUpdate]
  );

  const handleChange = (changes: Partial<Task>) => {
    // Immediate update for instant feedback
    onImmediateUpdate({ id: task.id, ...changes }, true);
    // Debounced update to sync with the server
    debouncedUpdate({ id: task.id, ...changes }, false);
  };

  const handleDateChange = (date: Date | null) => {
    handleChange({ due_date: date ? date.toISOString().split('T')[0] : null });
  };

  const handleClientChange = (clientId: string | null) => {
    handleChange({ client_id: clientId });
  };

  const handleProjectChange = (projectId: string | null) => {
    handleChange({ project_id: projectId });
  };

  const handleUserChange = (userId: string | null) => {
    handleChange({ user_id: userId });
  };

  const handleStatusChange = (statusId: string | null) => {
    if (statusId) {
      handleChange({ status_id: statusId });
    }
  };

  const assignedUser = organizationUsers.find(user => user.id === task.user_id);

  const getCategoryIcon = (category: TaskStatusCategory) => {
    switch (category) {
      case 'Todo':
        return Todo12;
      case 'In Progress':
        return InProgress12;
      case 'Complete':
        return Done12;
      default:
        return Todo12;
    }
  };

  const currentStatus = taskStatuses.find(status => status.id === task.status_id);
  const StatusIconComponent = currentStatus ? getCategoryIcon(currentStatus.category as TaskStatusCategory) : Todo12;

  return (
    <TaskWrapper onClick={onClick} $isSelected={isSelected} className="task-item">
      <EntityWrapper onClick={(e) => e.stopPropagation()}>
        <EntityPicker
          selectedId={task.status_id || taskStatuses[0]?.id}
          onChange={handleStatusChange}
          entities={taskStatuses.map(status => ({ id: status.id, name: status.name, color: status.color }))}
          label="Status"
          allowUnassigned={false}
          placement="bottom-start"
          iconOnly={true}
          icon={
            <StatusIconWrapper $color={currentStatus?.color || '#000000'}>
              <StatusIconComponent />
            </StatusIconWrapper>
          }
        />
      </EntityWrapper>

      <Description>{task.description}</Description>

      {task.client_id && (
        <EntityWrapper onClick={(e) => e.stopPropagation()}>
          <EntityPicker
            selectedId={task.client_id}
            onChange={handleClientChange}
            entities={clients.map(client => ({ id: client.id, name: client.full_name }))}
            label="Client"
            allowUnassigned={true}
            placement="bottom-end"
            iconOnly
          />
        </EntityWrapper>
      )}

      {task.project_id && (
        <EntityWrapper onClick={(e) => e.stopPropagation()}>
          <EntityPicker
            selectedId={task.project_id}
            onChange={handleProjectChange}
            entities={projects.map(project => ({ id: project.id, name: project.name }))}
            label="Project"
            allowUnassigned={true}
            placement="bottom-end"
            iconOnly
          />
        </EntityWrapper>
      )}

      <EntityWrapper onClick={(e) => e.stopPropagation()}>
        <EntityPicker
          selectedId={task.user_id}
          onChange={handleUserChange}
          entities={organizationUsers.map(user => ({ id: user.id, name: user.full_name || user.email }))}
          label="Assign"
          allowUnassigned={true}
          placement="bottom-end"
          iconOnly={!assignedUser}
          icon={
            assignedUser ? null : (
              <IconWrapper>
                <Client12 />
              </IconWrapper>
            )
          }
        />
      </EntityWrapper>

      <DatePickerWrapper onClick={(e) => e.stopPropagation()}>
        <DatePicker
          selectedDate={task.due_date ? new Date(task.due_date) : null}
          onChange={handleDateChange}
          label="Due Date"
          id={`task-due-date-${task.id}`}
          icon={<Calendar12 />}
          displayMode="icon"
          placement="bottom-end"
          showClearDate={true}
        />
      </DatePickerWrapper>
    </TaskWrapper>
  );
};

export default React.memo(TaskComponent);