import { useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Expense } from '../types';
import { useCreateExpenseMutation } from './useCreateExpenseMutation';

export const useExpenseOperations = (projectId?: string, clientId?: string, organizationId?: string) => {
  const queryClient = useQueryClient();
  const [isExpenseDrawerOpen, setIsExpenseDrawerOpen] = useState(false);
  const [editingExpense, setEditingExpense] = useState<Expense | null>(null);
  const createExpenseMutation = useCreateExpenseMutation();

  const handleEditExpense = (expense: Expense) => {
    setEditingExpense(expense);
    setIsExpenseDrawerOpen(true);
  };

  const deleteExpenseMutation = useMutation({
    mutationFn: async (expenseId: string) => {
      const { error } = await supabase.from("expenses").delete().eq("id", expenseId);
      if (error) throw error;
    },
    onSuccess: () => {
      // Invalidate both client and project expense queries
      if (clientId) {
        queryClient.invalidateQueries({ queryKey: ["clientExpenses", clientId, organizationId] });
      }
      if (projectId) {
        queryClient.invalidateQueries({ queryKey: ["projectExpenses", projectId, organizationId] });
      }
      // Also invalidate the general expenses query
      queryClient.invalidateQueries({ queryKey: ["expenses", organizationId] });
    },
    onError: (error) => {
      console.error("Error deleting expense:", error);
    },
  });

  const handleSaveExpense = (savedExpense: Expense) => {
    setEditingExpense(null);
    queryClient.setQueryData<Expense[]>(["expenses", organizationId], (old) =>
      old ? old.map((exp) => (exp.id === savedExpense.id ? savedExpense : exp)) : [savedExpense]
    );
    queryClient.invalidateQueries({ queryKey: ["projectExpenses", savedExpense.project_id] });
    queryClient.invalidateQueries({ queryKey: ["projectExpenses", projectId] }); // Invalidate the original project's expenses
    queryClient.invalidateQueries({ queryKey: ["clientExpenses", savedExpense.client_id] });
  };

  const handleCreateExpense = async (expenseData: Partial<Expense>): Promise<Expense> => {
    if (!organizationId) {
      throw new Error("No organization selected. Please select an organization and try again.");
    }

    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      throw new Error("No user found. Please log in and try again.");
    }
  
    try {
      const newExpense = await createExpenseMutation.mutateAsync({
        ...expenseData,
        organization_id: organizationId,
        project_id: projectId || expenseData.project_id,
        client_id: clientId || expenseData.client_id,
        user_id: user.id,
      });
      queryClient.invalidateQueries({ queryKey: ["projectExpenses", newExpense.project_id] });
      queryClient.invalidateQueries({ queryKey: ["clientExpenses", newExpense.client_id] });
      queryClient.invalidateQueries({ queryKey: ["expenses", organizationId] });
      return newExpense;
    } catch (error) {
      console.error("Error creating expense:", error);
      throw error;
    }
  };

  return {
    isExpenseDrawerOpen,
    setIsExpenseDrawerOpen,
    editingExpense,
    handleEditExpense,
    handleDeleteExpense: deleteExpenseMutation.mutate,
    handleSaveExpense,
    handleCreateExpense,
  };
};