import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useTimeEntries } from './hooks/useTimeEntries';
import { useOrganization } from './hooks/useOrganization';
import { useOrganizationUsers } from './hooks/useOrganizationUsers';
import { useClients } from './hooks/useClients';
import { useProjects } from './hooks/useProjects';
import { useCreateInvoiceMutation } from './hooks/useCreateInvoiceMutation';
import { useTimeTracking } from './hooks/useTimeTracking';
import { useMediaQuery } from './hooks/useMediaQuery';
import { usePageContext } from './hooks/usePageContext';
import { TimeEntry, GroupedTimeEntry, Filter, FilterOption, Client, Project, OrganizationUser } from './types';
import { parseISO, format, startOfDay, endOfDay, eachDayOfInterval, isSameYear, isSameDay } from 'date-fns';
import { supabase } from './supabaseClient';
import { Link, useNavigate } from '@tanstack/react-router';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import Button from './components/Button';
import AddTimeEntryDrawer from './components/Time/AddTimeEntryDrawer';
import DataTable from './components/DataTable';
import ErrorBoundary from './components/ErrorBoundary';
import Tabs from './components/Tabs';
import { Time32 } from './components/Icon';
import FilterDrawer from './components/FilterDrawer';
import FilterBox from './components/FilterBox';
import FilterPicker from './components/FilterPicker';
import MultipleEntityPicker from './components/MultipleEntityPicker';
import DateRangePicker from './components/DateRangePicker';
import { Calendar12, Client12, Project12, Profile12 } from './components/Icon';
import TimeEntriesChart from './components/Time/TimeEntriesChart';
import SelectedTimeEntriesOverlay from './components/Time/SelectedTimeEntriesOverlay';
import { formatDuration as formatDurationFromUtils } from './utils/timeUtils';

interface CreateInvoiceParams {
  organization_id: string;
  client_id?: string;
  project_id?: string;
  service_id?: string;
  start_time: string;
  end_time?: string;
  duration: number;
  description?: string;
  is_billable: boolean;
}

const PageWrapper = styled.div`
  padding: 0px;
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: start;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

const FilterBoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const TimeEntriesPage: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const { data: organizationId } = useOrganization();
  const { data: timeEntries, isLoading, error } = useTimeEntries();
  const { data: users, isLoading: isLoadingUsers, error: userError } = useOrganizationUsers();
  const { data: clients } = useClients();
  const { data: projects } = useProjects();
  const createInvoiceMutation = useCreateInvoiceMutation();
  const navigate = useNavigate();
  const { stopTimer, currentTimeEntryId } = useTimeTracking();
  const queryClient = useQueryClient();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTimeEntryId, setSelectedTimeEntryId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState('all');
  const [filters, setFilters] = useState<Filter[]>([]);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const handleCloseDrawer = useCallback(() => {
    setIsDrawerOpen(false);
    setSelectedTimeEntryId(null);
  }, []);

  const handleAddNewTimeEntry = useCallback(() => {
    setSelectedTimeEntryId(null);
    setIsDrawerOpen(true);
  }, []);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.time-entry-item')) {
      handleCloseDrawer();
    }
  }, [handleCloseDrawer]);

  const columnVisibilityConfig = useMemo(() => [
    { breakpoint: 1200, hidden: ['service.name', 'user_full_name'] },
    { breakpoint: 900, hidden: ['service.name', 'user_full_name', 'end_time'] },
    { breakpoint: 600, hidden: ['service.name', 'user_full_name', 'end_time', 'project.name'] },
    { breakpoint: 400, hidden: ['service.name', 'user_full_name', 'end_time', 'project.name', 'client', 'start_time'] },
  ], []);

  const formatGroupDate = useCallback((date: Date) => {
    return isSameYear(date, new Date())
      ? format(date, 'EEE, MMM d')
      : format(date, 'EEE, MMM d, yyyy');
  }, []);

  const filteredData = useMemo(() => {
    if (!timeEntries) return [];

    return timeEntries.filter((entry: TimeEntry) => {
      const clientFilter = filters.find(f => f.id === 'client')?.value as string[] | undefined;
      const projectFilter = filters.find(f => f.id === 'project')?.value as string[] | undefined;
      const userFilter = filters.find(f => f.id === 'user')?.value as string[] | undefined;
      const dateFilter = filters.find(f => f.id === 'date')?.value as { startDate: Date | null; endDate: Date | null } | undefined;
      const billableFilter = filters.find(f => f.id === 'billable')?.value as string | undefined;

      const clientMatch = !clientFilter || clientFilter.length === 0 || (entry.client_id && clientFilter.includes(entry.client_id));
      const projectMatch = !projectFilter || projectFilter.length === 0 || (entry.project_id && projectFilter.includes(entry.project_id));
      const userMatch = !userFilter || userFilter.length === 0 || userFilter.includes(entry.user_id);
      const tabMatch = activeTab === 'all' || 
        (activeTab === 'not-invoiced' && !entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id) || 
        (activeTab === 'invoiced' && entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id);

      const dateMatch = !dateFilter || !dateFilter.startDate || !dateFilter.endDate || 
        (parseISO(entry.start_time) >= dateFilter.startDate && parseISO(entry.start_time) <= dateFilter.endDate);

      const billableMatch = !billableFilter || 
        (billableFilter === 'Billable' && entry.is_billable) || 
        (billableFilter === 'Non-billable' && !entry.is_billable);

      return clientMatch && projectMatch && userMatch && tabMatch && dateMatch && billableMatch;
    }).map((entry: TimeEntry) => ({
      ...entry,
      groupDate: formatGroupDate(parseISO(entry.start_time)),
      user_full_name: users?.find((u: OrganizationUser) => u.id === entry.user_id)?.full_name || 'N/A',
      invoiceId: entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null,
    }));
  }, [timeEntries, users, activeTab, filters, formatGroupDate]);

  const chartData = useMemo(() => {
    if (!filteredData.length) return [];

    const startDate = startOfDay(parseISO(filteredData[0].start_time));
    const endDate = endOfDay(parseISO(filteredData[filteredData.length - 1].start_time));

    return eachDayOfInterval({ start: startDate, end: endDate }).map(day => ({
      date: format(day, 'MMM dd'),
      totalSeconds: filteredData.reduce((total: number, entry: GroupedTimeEntry) => 
        isSameDay(parseISO(entry.start_time), day) ? total + entry.duration : total, 0
      ),
    }));
  }, [filteredData]);

  const tabCounts = useMemo(() => {
    if (!timeEntries) return { all: 0, notInvoiced: 0, invoiced: 0 };
    return timeEntries.reduce((acc: { all: number; notInvoiced: number; invoiced: number }, entry: TimeEntry) => {
      acc.all++;
      if (entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id) {
        acc.invoiced++;
      } else {
        acc.notInvoiced++;
      }
      return acc;
    }, { all: 0, notInvoiced: 0, invoiced: 0 });
  }, [timeEntries]);

  const tabs = useMemo(() => [
    { id: 'all', label: 'All', count: tabCounts.all },
    { id: 'not-invoiced', label: 'Not Invoiced', count: tabCounts.notInvoiced },
    { id: 'invoiced', label: 'Invoiced', count: tabCounts.invoiced },
  ], [tabCounts]);

  const filterOptions: FilterOption[] = [
    { id: 'client', label: 'Client', type: 'multipleEntity' },
    { id: 'project', label: 'Project', type: 'multipleEntity' },
    { id: 'user', label: 'User', type: 'multipleEntity' },
    { id: 'date', label: 'Date Range', type: 'dateRange' },
    { id: 'billable', label: 'Billable', type: 'select', options: ['Billable', 'Non-billable'] },
  ];

  const getFilterOptions = useCallback((filterId: string) => {
    const option = filterOptions.find(opt => opt.id === filterId);
    return option?.options || [];
  }, [filterOptions]);

  const getFilterEntities = useCallback((filterId: string) => {
    switch (filterId) {
      case 'client':
        return clients?.map((client: Client) => ({ id: client.id, name: client.full_name })) || [];
      case 'project':
        return projects?.map((project: Project) => ({ id: project.id, name: project.name })) || [];
      case 'user':
        return users?.map((user: OrganizationUser) => ({ id: user.id, name: user.full_name || user.email || '' })) || [];
      default:
        return [];
    }
  }, [clients, projects, users]);

  const handleAddFilter = useCallback((filterId: Filter['id']) => {
    if (!filters.some(filter => filter.id === filterId)) {
      const filterOption = filterOptions.find(option => option.id === filterId);
      if (filterOption) {
        const newFilter: Filter = {
          id: filterId,
          label: filterOption.label,
          value: ['client', 'project', 'user'].includes(filterId) ? [] : 
                 filterId === 'date' ? { startDate: null, endDate: null } : ''
        };
        setFilters(prevFilters => [...prevFilters, newFilter]);
      }
    }
  }, [filters, filterOptions]);

  const handleRemoveFilter = useCallback((filterId: string) => {
    setFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
  }, []);

  const handleFilterChange = useCallback((filterId: Filter['id'], value: Filter['value']) => {
    setFilters(prevFilters => 
      prevFilters.map(filter => 
        filter.id === filterId ? { ...filter, value } : filter
      )
    );
  }, []);

  const handleDateRangeChange = useCallback((newRange: { startDate: Date | null; endDate: Date | null }) => {
    setDateRange(newRange);
    handleFilterChange('date', newRange);
  }, [handleFilterChange]);

  const handleApplyFilters = useCallback(() => {
    setIsFilterDrawerOpen(false);
  }, []);

  const columns: ColumnDef<GroupedTimeEntry>[] = useMemo(() => [
    {
      id: 'duration',
      accessorKey: 'duration',
      header: 'Duration',
      cell: ({ row }) => {
        const duration = row.original.duration;
        const endTime = row.original.end_time;
        if (!endTime) {
          // Calculate duration for running entries
          const startTime = parseISO(row.original.start_time);
          const now = new Date();
          const runningDuration = Math.floor((now.getTime() - startTime.getTime()) / 1000);
          return formatDurationFromUtils(runningDuration);
        }
        return formatDurationFromUtils(duration);
      },
    },
    {
      id: 'start_time',
      accessorKey: 'start_time',
      header: 'Start Time',
      cell: ({ getValue }) => {
        const date = new Date(getValue() as string);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      },
    },
    {
      id: 'client',
      accessorKey: 'client',
      header: 'Client',
      cell: ({ row }) => {
        const client = row.original.client;
        return client ? client.full_name : '';
      },
    },
    {
      id: 'project.name',
      accessorKey: 'project.name',
      header: 'Project',
      cell: ({ row }) => row.original.project?.name || '',
    },
    {
      id: 'service.name',
      accessorKey: 'service.name',
      header: 'Service',
      cell: ({ row }) => row.original.service?.name || '',
    },
    {
      id: 'user_full_name',
      accessorKey: 'user_full_name',
      header: 'User',
      cell: ({ getValue }) => getValue() || '',
    },
    {
      id: 'end_time',
      accessorKey: 'end_time',
      header: 'End Time',
      cell: ({ row }) => {
        const endTime = row.original.end_time;
        if (!endTime) {
          return <span style={{ color: 'green' }}>Running</span>;
        }
        return format(parseISO(endTime), 'HH:mm');
      },
    },
    {
      id: 'invoice',
      accessorKey: 'invoiceId',
      header: 'Invoice',
      align: 'right',
      cell: ({ getValue }) => {
        const invoiceId = getValue() as string | null;
        return invoiceId ? (
          <Link 
            to="/invoice/$id" 
            params={{ id: invoiceId }}
            search={{ from: '/time-entries' }}
          >
            View Invoice
          </Link>
        ) : (
          'Not Invoiced'
        );
      },
    },
  ], []);

  const isRowSelectable = useCallback((row: GroupedTimeEntry) => {
    return !row.invoiceId;
  }, []);

  const handleRowClick = useCallback((item: GroupedTimeEntry) => {
    setSelectedTimeEntryId(item.id);
    setIsDrawerOpen(true);
  }, []);

  const handleSelectionChange = useCallback((newSelection: string[]) => {
    setSelectedEntries(newSelection);
  }, []);

  const handleClearSelection = useCallback(() => {
    setSelectedEntries([]);
  }, []);

  const emptyState = useMemo(() => ({
    icon: <Time32 />,
    message: 'No time entries found',
    subMessage: 'Start tracking your time or adjust your filters to see entries.',
    action: {
      label: 'New Time Entry',
      onClick: handleAddNewTimeEntry
    }
  }), [handleAddNewTimeEntry]);

  const handleCreateInvoice = useCallback(async () => {
    if (selectedEntries.length === 0 || !timeEntries) {
      alert('Please select at least one time entry');
      return;
    }

    const selectedTimeEntries = timeEntries.filter((entry: TimeEntry) => 
      selectedEntries.some(selectedId => entry.id.startsWith(selectedId))
    );

    if (selectedTimeEntries.length === 0) {
      alert('No matching time entries found for selection');
      return;
    }

    const firstEntry = selectedTimeEntries[0];
    if (!firstEntry.client_id || !selectedTimeEntries.every((entry: TimeEntry) => entry.client_id === firstEntry.client_id)) {
      alert('All selected time entries must be for the same client');
      return;
    }

    try {
      const newInvoice = await createInvoiceMutation.mutateAsync({
        organization_id: firstEntry.organization_id,
        client_id: firstEntry.client_id,
        timeEntries: selectedTimeEntries
      });

      setSelectedEntries([]);
      navigate({ to: '/invoice/$id', params: { id: newInvoice.id } });
    } catch (error) {
      console.error('Error creating invoice:', error);
      alert('Failed to create invoice. Please try again.');
    }
  }, [selectedEntries, timeEntries, createInvoiceMutation, navigate]);

  const handleSaveTimeEntry = async () => {
    await stopTimer();
    setIsDrawerOpen(false);
    setSelectedTimeEntryId(null);
  };

  const handleDeleteTimeEntry = async (timeEntryId: string) => {
    try {
      await supabase.from('time_entries').delete().eq('id', timeEntryId);
      // Refetch time entries or update local state
      queryClient.invalidateQueries({ queryKey: ['timeEntries'] });
      // If the deleted entry was the current running timer, stop it
      if (currentTimeEntryId && timeEntryId === currentTimeEntryId) {
        await stopTimer();
      }
    } catch (error) {
      console.error('Error deleting time entry:', error);
      alert('Failed to delete time entry. Please try again.');
    }
  };

  const handleCreateTimeEntry = async (timeEntryData: Partial<TimeEntry>) => {
    try {
      if (!organizationId) {
        throw new Error('Organization ID is required');
      }

      const createParams: CreateInvoiceParams = {
        organization_id: organizationId,
        client_id: timeEntryData.client_id || undefined,
        project_id: timeEntryData.project_id || undefined,
        service_id: timeEntryData.service_id || undefined,
        start_time: timeEntryData.start_time || new Date().toISOString(),
        end_time: timeEntryData.end_time || undefined,
        duration: timeEntryData.duration || 0,
        description: timeEntryData.description || undefined,
        is_billable: timeEntryData.is_billable ?? true
      };

      const newTimeEntry = await createInvoiceMutation.mutateAsync(createParams);
      setIsDrawerOpen(false);
      return newTimeEntry;
    } catch (error) {
      console.error('Error creating time entry:', error);
      throw error;
    }
  };

  const getRowKey = useCallback((entry: GroupedTimeEntry) => entry.id.slice(0, 8), []);

  useEffect(() => {
    setPageHeaderProps({
      title: "Time",
      right: (
        <ButtonWrapper>
          <Button buttonType="primary" onClick={handleAddNewTimeEntry}>
            New Time Entry
          </Button>
        </ButtonWrapper>
      ),
    });
  }, [setPageHeaderProps, handleAddNewTimeEntry]);

  if (isLoading || isLoadingUsers) return null;
  if (error) return <div>Error: {error.message}</div>;
  if (userError) return <div>Error loading users: {userError.message}</div>;

  return (
    <ErrorBoundary fallback={<div>Something went wrong. Please try again later.</div>}>
      <PageWrapper ref={containerRef}>
        <Tabs 
          tabs={tabs} 
          activeTab={activeTab} 
          onTabChange={setActiveTab}
          actionButtons={
            <FilterContainer>
              <FilterBoxesWrapper>
                {filters.map(filter => (
                  <FilterBox
                    key={filter.id}
                    filter={filter}
                    onRemove={() => handleRemoveFilter(filter.id)}
                    onChange={(value) => handleFilterChange(filter.id as Filter['id'], value)}
                    options={getFilterOptions(filter.id)}
                    entities={getFilterEntities(filter.id)}
                  />
                ))}
              </FilterBoxesWrapper>
              <FilterPicker
                options={filterOptions}
                onAddFilter={handleAddFilter}
                activeFilters={filters.map(filter => filter.id)}
              />
            </FilterContainer>
          }
        />
        <TimeEntriesChart data={chartData} />
        <TableWrapper>
          <DataTable<GroupedTimeEntry>
            columns={columns}
            data={filteredData}
            isLoading={isLoading}
            onRowClick={handleRowClick}
            getRowKey={getRowKey}
            groupBy="groupDate"
            onSelectionChange={handleSelectionChange}
            isRowSelectable={isRowSelectable}
            columnVisibility={columnVisibilityConfig}
            selectedRows={selectedEntries}
            containerRef={containerRef}
            emptyState={emptyState}
          />
        </TableWrapper>
        {isSmallScreen && (
          <FilterDrawer
            isOpen={isFilterDrawerOpen}
            setIsOpen={setIsFilterDrawerOpen}
            onApply={handleApplyFilters}
          >
            <MultipleEntityPicker
              selectedIds={filters.find(filter => filter.id === 'client')?.value as string[] || []}
              onChange={(value) => handleFilterChange('client', value)}
              entities={getFilterEntities('client')}
              label="Clients"
              icon={<Client12 />}
              placement="bottom-start"
            />
            <MultipleEntityPicker
              selectedIds={filters.find(filter => filter.id === 'project')?.value as string[] || []}
              onChange={(value) => handleFilterChange('project', value)}
              entities={getFilterEntities('project')}
              label="Projects"
              icon={<Project12 />}
              placement="bottom-start"
            />
            <MultipleEntityPicker
              selectedIds={filters.find(filter => filter.id === 'user')?.value as string[] || []}
              onChange={(value) => handleFilterChange('user', value)}
              entities={getFilterEntities('user')}
              label="Users"
              icon={<Profile12 />}
              placement="bottom-start"
            />
            <DateRangePicker
              selectedRange={dateRange}
              onChange={handleDateRangeChange}
              label="Date Range"
              id="time-entry-date-range"
              variant="preview"
              icon={<Calendar12 />}
            />
          </FilterDrawer>
        )}
        <AddTimeEntryDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          timeEntry={timeEntries?.find((entry: TimeEntry) => entry.id === selectedTimeEntryId) || null}
          onSave={handleSaveTimeEntry}
          onDelete={handleDeleteTimeEntry}
          onCreate={handleCreateTimeEntry}
          organizationId={organizationId}
          onOverlayClick={handleOverlayClick}
          queryKey={['timeEntries', organizationId]}
          stopTimer={stopTimer}
        />
        <SelectedTimeEntriesOverlay
          selectedCount={selectedEntries.length}
          onCreateInvoice={handleCreateInvoice}
          onClearSelection={handleClearSelection}
        />
      </PageWrapper>
    </ErrorBoundary>
  );
};

export default TimeEntriesPage;