import React, { useState, useEffect } from 'react';
import { InvoiceTemplatesProps } from './types';
import styled from 'styled-components';
import { ClientSelector } from '../components/Clients/ClientSelector';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import AnimatedAmount from '../components/AnimatedAmount';
import DatePicker from '../components/DatePicker';
import { addDays } from 'date-fns';

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div.attrs<{ $headerColor: string }>(props => ({
  style: {
    backgroundColor: props.$headerColor,
  },
}))`
  padding: 15px;
`;

const HeaderContent = styled.div<{ $headerTextColor: string }>`
  color: ${props => props.$headerTextColor};

  input {
    color: inherit;
  }
`;

const Input = styled.input`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  margin: 0;
  color: inherit;
  &::placeholder {
    color: inherit;
  }
`;

const Logo = styled.img`
  max-width: 100px;
  max-height: 50px;
  object-fit: contain;
  margin-right: 10px;
`;

export const SimpleHeader: React.FC<InvoiceTemplatesProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient,
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
  }, [invoice.invoice_number]);

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const totals = calculateInvoiceTotals(invoice as InvoiceData);

  return (
    <FontWrapper $font={invoice.font || 'Arial'}>
      <HeaderContainer $headerColor={invoice.header_color}>
        <HeaderContent $headerTextColor={invoice.header_text_color}>
          {invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
          <div>
            <strong>Amount Due: <AnimatedAmount value={totals.amountDue} /></strong>
          </div>
          <ClientSelectorComponent
            clients={clients}
            selectedClientId={invoice.client_id || null}
            onSelectClient={(clientId) => updateInvoice('client_id', clientId)}
            disabled={isReadOnly}
            onCreateNewClient={onCreateNewClient}
          />
          <DatePicker
            id="invoice-date"
            label="Invoice Date"
            selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
            onChange={handleInvoiceDateChange}
            disabled={isReadOnly}
            isInvoiceDate={true}
          />
          <DatePicker
            id="due-date"
            label="Due Date"
            selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
            onChange={handleDueDateChange}
            disabled={isReadOnly}
            onSetDueDate={handleDueDaysChange}
            dueDays={invoice.due_days ?? 0}
            invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
          />
          <Input
            type="text"
            value={localInvoiceNumber}
            onChange={handleInvoiceNumberChange}
            placeholder="Invoice Number"
            readOnly={isReadOnly}
          />
        </HeaderContent>
      </HeaderContainer>
    </FontWrapper>
  );
};