import React, { useState } from 'react';
import styled from 'styled-components';
import { TaskStatus } from '../../types';
import { useTaskStatuses } from '../../hooks/useTaskStatuses';
import { useOrganization } from '../../hooks/useOrganization';
import Button from '../Button';
import { Todo12, InProgress12, Done12 } from '../Icon';

const StatusManagerWrapper = styled.div`
`;

const StatusList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const StatusIcon = styled.svg<{ $color: string }>`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  path {
    fill: ${props => props.$color};
  }
`;

const StatusItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StatusName = styled.span`
  margin-right: 10px;
`;

const CategorySection = styled.div`
  margin-bottom: 20px;
`;

const CategoryTitle = styled.h4`
  margin-bottom: 10px;
`;

const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const AddButton = styled(Button)`
  padding: 2px 8px;
  font-size: 12px;
`;

const TaskStatusManager: React.FC = () => {
  const { data: organizationId } = useOrganization();
  const { taskStatuses, createTaskStatus, updateTaskStatus, deleteTaskStatus } = useTaskStatuses();
  const [editingStatus, setEditingStatus] = useState<TaskStatus | null>(null);

  const handleCreateStatus = (category: 'Todo' | 'In Progress' | 'Complete') => {
    if (organizationId) {
      const newStatus = {
        name: `New ${category} Status`,
        color: '#000000',
        order_number: (taskStatuses?.length || 0) + 1,
        organization_id: organizationId,
        category: category,
      };
      createTaskStatus(newStatus, {
        onSuccess: () => {
          // Reset form fields if needed
        },
        onError: (error) => {
          console.error('Error creating status:', error);
          alert('Failed to create status. Please try again.');
        },
      });
    }
  };

  const handleUpdateStatus = (status: TaskStatus) => {
    if (editingStatus && editingStatus.id === status.id) {
      updateTaskStatus(editingStatus, {
        onSuccess: () => {
          setEditingStatus(null);
        },
        onError: (error) => {
          console.error('Error updating status:', error);
          alert('Failed to update status. Please try again.');
        },
      });
    } else {
      setEditingStatus(status);
    }
  };

  const handleDeleteStatus = (statusId: string) => {
    if (window.confirm('Are you sure you want to delete this status?')) {
      deleteTaskStatus(statusId, {
        onError: (error) => {
          console.error('Error deleting status:', error);
          alert('Failed to delete status. It might be the last one in its category.');
        },
      });
    }
  };

  const getCategoryIcon = (category: 'Todo' | 'In Progress' | 'Complete') => {
    switch (category) {
      case 'Todo':
        return Todo12;
      case 'In Progress':
        return InProgress12;
      case 'Complete':
        return Done12;
    }
  };

  const renderStatusList = (category: 'Todo' | 'In Progress' | 'Complete') => {
    const filteredStatuses = taskStatuses?.filter(status => status.category === category) || [];
    const CategoryIcon = getCategoryIcon(category);

    return (
      <CategorySection>
        <CategoryHeader>
          <CategoryTitle>
            <CategoryIcon width={12} height={12} style={{ marginRight: '8px' }} />
            {category}
          </CategoryTitle>
          <AddButton onClick={() => handleCreateStatus(category)}>+</AddButton>
        </CategoryHeader>
        <StatusList>
          {filteredStatuses.map(status => (
            <StatusItem key={status.id}>
              {editingStatus && editingStatus.id === status.id ? (
                <>
                  <input
                    type="text"
                    value={editingStatus.name}
                    onChange={(e) => setEditingStatus({ ...editingStatus, name: e.target.value })}
                  />
                  <input
                    type="color"
                    value={editingStatus.color}
                    onChange={(e) => setEditingStatus({ ...editingStatus, color: e.target.value })}
                  />
                </>
              ) : (
                <>
                  <StatusIcon as={CategoryIcon} $color={status.color} />
                  <StatusName>{status.name}</StatusName>
                </>
              )}
              <Button onClick={() => handleUpdateStatus(status)}>
                {editingStatus && editingStatus.id === status.id ? 'Save' : 'Edit'}
              </Button>
              <Button onClick={() => handleDeleteStatus(status.id)}>Delete</Button>
            </StatusItem>
          ))}
        </StatusList>
      </CategorySection>
    );
  };

  return (
    <StatusManagerWrapper>
      {renderStatusList('Todo')}
      {renderStatusList('In Progress')}
      {renderStatusList('Complete')}
    </StatusManagerWrapper>
  );
};

export default TaskStatusManager;