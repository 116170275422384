import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from './supabaseClient';
import styled from 'styled-components';
import { useOrganization } from './hooks/useOrganization';
import Button from './components/Button';

const DashboardContainer = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`;

const Section = styled.section`
  margin-bottom: 30px;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 15px;
`;

const StatusMessage = styled.p`
  font-size: 1.1em;
  margin-bottom: 15px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;

interface DashboardProps {
  verificationInProgress?: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ verificationInProgress = false }) => {
  const { data: organizationId } = useOrganization();
  const [stripeStatus, setStripeStatus] = useState<'not_connected' | 'connected' | 'verified'>('not_connected');
  const queryClient = useQueryClient();

  const { data: orgData, isLoading, error } = useQuery({
    queryKey: ['organization', organizationId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('organizations')
        .select('*')
        .eq('id', organizationId)
        .single();
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });

  const disconnectStripeMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch('/api/disconnect-stripe-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to disconnect Stripe account');
      }
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization', organizationId] });
      setStripeStatus('not_connected');
    },
  });

  useEffect(() => {
    if (orgData) {
      if (orgData.stripe_account_id && orgData.stripe_account_verified) {
        setStripeStatus('verified');
      } else if (orgData.stripe_account_id) {
        setStripeStatus('connected');
      } else {
        setStripeStatus('not_connected');
      }
    }
  }, [orgData]);

  const handleStripeConnect = async () => {
    try {
      const response = await fetch('/api/create-stripe-connect-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error('Error connecting to Stripe:', error);
    }
  };

  const handleStripeDisconnect = async () => {
    if (window.confirm('Are you sure you want to disconnect your Stripe account? This will affect your ability to process payments.')) {
      disconnectStripeMutation.mutate();
    }
  };

  const handleGoToStripeDashboard = () => {
    if (orgData?.stripe_account_id) {
      window.open(`https://dashboard.stripe.com/${orgData.stripe_account_id}`, '_blank');
    } else {
      console.error('Stripe account ID not found');
      alert('Unable to open Stripe dashboard. Please try again later.');
    }
  };

  if (isLoading) return <DashboardContainer>Loading...</DashboardContainer>;
  if (error) return <DashboardContainer>Error: {error.message}</DashboardContainer>;

  return (
    <DashboardContainer>
      <h1>Stripe Integration Status</h1>
      
      <Section>
        <SectionTitle>Stripe Connection</SectionTitle>
        {verificationInProgress ? (
          <>
            <StatusMessage>Stripe account verification is in progress.</StatusMessage>
            <StatusMessage>This process may take a few moments. Please refresh the page in a minute.</StatusMessage>
          </>
        ) : (
          <>
            {stripeStatus === 'not_connected' && (
              <>
                <StatusMessage>Your account is not connected to Stripe.</StatusMessage>
                <Button onClick={handleStripeConnect}>Connect Stripe Account</Button>
              </>
            )}
            {stripeStatus === 'connected' && (
              <>
                <StatusMessage>Your Stripe account is connected but not fully verified.</StatusMessage>
                <StatusMessage>Please complete the verification process in your Stripe dashboard.</StatusMessage>
                <ButtonGroup>
                  <Button onClick={handleStripeConnect}>Complete Stripe Verification</Button>
                  <Button onClick={handleGoToStripeDashboard}>Go to Stripe Dashboard</Button>
                  <Button onClick={handleStripeDisconnect} style={{ backgroundColor: '#dc3545' }}>
                    Disconnect Stripe Account
                  </Button>
                </ButtonGroup>
              </>
            )}
            {stripeStatus === 'verified' && (
              <>
                <StatusMessage>Your Stripe account is fully connected and verified.</StatusMessage>
                <StatusMessage>You can now accept payments through your invoices.</StatusMessage>
                <ButtonGroup>
                  <Button onClick={handleGoToStripeDashboard}>Go to Stripe Dashboard</Button>
                  <Button onClick={handleStripeDisconnect} style={{ backgroundColor: '#dc3545' }}>
                    Disconnect Stripe Account
                  </Button>
                </ButtonGroup>
              </>
            )}
          </>
        )}
      </Section>
    </DashboardContainer>
  );
};

export default Dashboard;