import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { BrandSettings } from '../types';
import { useOrganization } from './useOrganization';

export const useBrandSettings = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<BrandSettings | null>({
    queryKey: ['brandSettings', organizationId],
    queryFn: async () => {
      if (!organizationId) return null;

      const { data, error } = await supabase
        .from('brand_settings')
        .select('*')
        .eq('organization_id', organizationId)
        .single();

      if (error) throw error;
      return data as BrandSettings;
    },
    enabled: !!organizationId,
  });
};