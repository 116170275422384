import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Client } from '../types';
import { useOrganization } from './useOrganization';

export const useOrganizationClients = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<Client[], Error>({
    queryKey: ['organizationClients', organizationId],
    queryFn: async () => {
      if (!organizationId) return [];
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .eq('organization_id', organizationId);
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });
};