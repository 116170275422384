import React, { useEffect, useState } from 'react';
import { useParams, useSearch } from '@tanstack/react-router';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { payInvoiceRoute } from './router';
import styled from 'styled-components';

const PaymentFormContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
`;

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [paymentElementReady, setPaymentElementReady] = useState(false);

  console.log('PaymentForm rendered. Stripe:', stripe, 'Elements:', elements); // Log Stripe and Elements

  useEffect(() => {
    if (!stripe || !elements) {
      console.log('Stripe or Elements not yet loaded');
    }
  }, [stripe, elements]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log('Payment form submitted');

    if (!stripe || !elements) {
      console.error('Stripe not initialized');
      setError('Stripe has not been initialized');
      return;
    }

    if (!paymentElementReady) {
      console.error('Payment Element not ready');
      setError('Payment Element is not ready');
      return;
    }

    setProcessing(true);
    console.log('Processing payment...');

    try {
      const { error: submitError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
        },
      });

      if (submitError) {
        console.error('Payment confirmation error:', submitError);
        setError(submitError.message ?? 'An unknown error occurred');
      } else {
        console.log('Payment confirmed successfully');
      }
    } catch (err) {
      console.error('Error confirming payment:', err);
      setError('An unexpected error occurred while processing your payment');
    } finally {
      setProcessing(false);
      console.log('Payment processing completed');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {stripe && elements ? (
        <>
          <PaymentElement 
            onReady={() => {
              console.log('Payment Element ready');
              setPaymentElementReady(true);
            }}
            onChange={(event) => {
              console.log('Payment Element changed:', event);
              if (event.complete) {
                setError(null);
              } else if (event.empty) {
                setError('Please fill in your payment details');
              } else {
                setError(null);
              }
            }}
          />
          <button type="submit" disabled={!stripe || !paymentElementReady || processing}>
            {processing ? 'Processing...' : 'Pay Now'}
          </button>
        </>
      ) : (
        <div>Loading Stripe...</div>
      )}
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </form>
  );
};

const PayInvoicePage: React.FC = () => {
  const { invoiceId } = useParams({ from: payInvoiceRoute.id });
  const search = useSearch({ from: payInvoiceRoute.id });
  
  // Explicitly type the search parameters
  interface SearchParams {
    client_secret?: string;
    stripe_account_id?: string;
  }
  const { client_secret, stripe_account_id } = search as SearchParams;
  
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [stripeAccountId, setStripeAccountId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

  console.log('PayInvoicePage rendered. Invoice ID:', invoiceId, 'Client Secret from URL:', client_secret, 'Stripe Account ID:', stripe_account_id);

  useEffect(() => {
    if (client_secret && stripe_account_id) {
      console.log('Using client secret and Stripe account ID from URL');
      setClientSecret(client_secret);
      setStripeAccountId(stripe_account_id);
    } else {
      console.log('Fetching client secret and Stripe account ID from API');
      const fetchPaymentDetails = async () => {
        try {
          const response = await fetch('/api/create-payment-intent', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ invoiceId }),
          });
          console.log('API request sent for payment details');
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || 'Unknown error'}`);
          }
          const data = await response.json();
          console.log('API response:', data);
          if (data.clientSecret && data.stripeAccountId) {
            setClientSecret(data.clientSecret);
            setStripeAccountId(data.stripeAccountId);
            console.log('Client secret and Stripe account ID set from API');
          } else {
            throw new Error('Failed to obtain client secret or Stripe account ID');
          }
        } catch (error) {
          console.error('Error fetching payment details:', error);
          setError(`Error fetching payment details: ${error instanceof Error ? error.message : String(error)}`);
        }
      };
      fetchPaymentDetails();
    }
  }, [client_secret, stripe_account_id, invoiceId]);

  useEffect(() => {
    if (stripeAccountId) {
      setStripePromise(loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, { stripeAccount: stripeAccountId }));
    }
  }, [stripeAccountId]);

  if (error) {
    console.error('Error state:', error);
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  if (!clientSecret || !stripePromise) {
    console.log('Waiting for payment details...');
    return <div>Loading payment details...</div>;
  }

  console.log('Rendering Elements with client secret:', clientSecret);
  return (
    <PaymentFormContainer>
      <h1>Pay Invoice {invoiceId}</h1>
      {clientSecret && stripePromise && (
        <Elements 
          stripe={stripePromise} 
          options={{ 
            clientSecret,
            appearance: { theme: 'stripe' },
          }}
        >
          <PaymentForm />
        </Elements>
      )}
    </PaymentFormContainer>
  );
};

export default PayInvoicePage;