import React, { useState, useEffect, ReactNode } from "react";
import styled from "styled-components";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { Checkmark12 } from "./Icon";

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const InputPreview = styled.div<{ 
  $noEntitySelected: boolean; 
  $iconOnly: boolean;
  $noBorderRadius?: boolean;
}>`
  padding: ${props => props.$iconOnly ? '0' : '4px 8px'};
  display: inline-flex;
  gap: 8px;
  border-radius: ${props => props.$noBorderRadius ? '0' : '8px'};
  align-items: center;
  position: relative;
  width: ${props => props.$iconOnly ? '28px' : 'fit-content'};
  height: ${props => props.$iconOnly ? '28px' : 'auto'};
  cursor: pointer;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  user-select: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  justify-content: ${props => props.$iconOnly ? 'center' : 'space-between'};
  color: ${(props) => props.$noEntitySelected ? 'rgba(0,0,0,0.5)' : 'rgba(0, 0, 0, 0.8)'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; // Ensure it doesn't exceed parent width

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const IconWrapper = styled.div<{ $noEntitySelected: boolean; $iconOnly: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.$iconOnly ? '28px' : '12px'};
  height: ${props => props.$iconOnly ? '28px' : '12px'};

  svg path {
    fill: ${(props) => props.$noEntitySelected ? 'rgba(0,0,0,0.5)' : 'rgba(0, 0, 0, 0.8)'};
  }

  &:hover {
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const PopoverContainer = styled.div`
  z-index: 2000;
  pointer-events: auto;
`;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: ${(props) => props.$transformOrigin};
  z-index: 2000;
  border-radius: 12px;
  box-shadow: 
  0 2px 2px rgba(0, 0, 0, 0.05),
  0 4px 4px rgba(0, 0, 0, 0.05),
  0 8px 8px rgba(0, 0, 0, 0.05),
  0 16px 16px rgba(0, 0, 0, 0.05),
  0 32px 32px rgba(0, 0, 0, 0.05),
  0 0 0 0.5px rgba(0, 0, 0, 0.1);
  padding: 4px;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  pointer-events: auto;
`;

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`;

const Option = styled.div<{ $isSelected: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  pointer-events: auto;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(0, 0, 0, 0.05)" : "transparent"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.05)"};
  }

  &:active {
    transform: scale(0.99);
  }
`;

interface Entity {
  id: string;
  name: string;
}

interface EntityPickerProps {
  selectedId: string | null;
  onChange: (id: string | null) => void;
  entities: Entity[];
  label: string;
  allowUnassigned?: boolean;
  icon?: React.ReactNode;
  onPopoverOpenChange?: (isOpen: boolean) => void;
  placement?: Placement;
  iconOnly?: boolean;
  children?: ReactNode;
  noBorderRadius?: boolean;
}

const EntityPicker: React.FC<EntityPickerProps> = ({
  selectedId,
  onChange,
  entities,
  label,
  allowUnassigned = false,
  icon,
  onPopoverOpenChange,
  placement = "left-start",
  iconOnly = false,
  children,
  noBorderRadius = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement,
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, {
    toggle: true,
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
      case "top-start":
      case "top-end":
        return "bottom";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "top";
      case "left":
      case "left-start":
      case "left-end":
        return "right";
      case "right":
      case "right-start":
      case "right-end":
        return "left";
      default:
        return "top";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const handleOptionClick = (entityId: string | null) => {
    onChange(entityId);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    } else {
      const timeout = setTimeout(() => setIsMounted(false), 150);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  useEffect(() => {
    if (onPopoverOpenChange) {
      onPopoverOpenChange(isOpen);
    }
  }, [isOpen, onPopoverOpenChange]);

  const selectedEntity = entities.find(e => e.id === selectedId) || (allowUnassigned ? { id: null, name: label } : entities[0]);
  const noEntitySelected = selectedId === null;

  return (
    <PickerWrapper>
      <InputPreview 
        ref={refs.setReference} 
        {...getReferenceProps()} 
        $noEntitySelected={noEntitySelected}
        $iconOnly={iconOnly}
        $noBorderRadius={noBorderRadius}
        title={selectedEntity.name} // Add this line to show full text on hover
      >
        {icon && (
          <IconWrapper $noEntitySelected={noEntitySelected} $iconOnly={iconOnly}>
            {icon}
          </IconWrapper>
        )}
        {!iconOnly && (
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {selectedEntity.name}
          </span>
        )}
      </InputPreview>
      <FloatingPortal>
        {isMounted && (
          <PopoverContainer
            ref={refs.setFloating}
            style={{
              ...floatingStyles,
              zIndex: 2000,
            }}
            {...getFloatingProps()}
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="entity-picker-popover"
          >
            <PopoverContent 
              style={styles} 
              $transformOrigin={transformOrigin}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {allowUnassigned && (
                <Option
                  $isSelected={selectedId === null}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOptionClick(null);
                  }}
                >
                  <span>No {label}</span>
                  {selectedId === null && (
                    <CheckIconWrapper>
                      <Checkmark12 />
                    </CheckIconWrapper>
                  )}
                </Option>
              )}
              {entities.map((entity) => (
                <Option
                  key={entity.id}
                  $isSelected={entity.id === selectedId}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOptionClick(entity.id);
                  }}
                >
                  <span>{entity.name}</span>
                  {entity.id === selectedId && (
                    <CheckIconWrapper>
                      <Checkmark12 />
                    </CheckIconWrapper>
                  )}
                </Option>
              ))}
            </PopoverContent>
          </PopoverContainer>
        )}
      </FloatingPortal>
      {children}
    </PickerWrapper>
  );
};

export default EntityPicker;