import React from 'react';
import { EditorContent, Editor } from '@tiptap/react';
import { Play8, Trash12, Pause8 } from '../../Icon';
import { TimeInputWrapper, TimeInputField, TimeInputSeparator, ButtonGroup, LogTimeButton, DeleteButton } from './TimeTrackerStyles';
import styled from 'styled-components';

const DefaultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
`;

const EditorWrapper = styled.div`
  // Add any specific styles for the editor wrapper
`;

interface TimeTrackerLayoutProps {
  selectedClientId: string | null;
  selectedProjectId: string | null;
  selectedServiceId: string | null;
  manualTime: string;
  handleManualTimeChange: (field: 'hours' | 'minutes' | 'seconds', value: string) => void;
  editor: Editor | null;
  isBillable: boolean;
  isRunning: boolean;
  isPaused: boolean;
  handleStart: () => void;
  handlePause: () => void;
  handleLogTime: () => void;
  handleDelete: () => void;
  renderPickerWrapper: () => React.ReactNode;
}

const DefaultTimeTracker: React.FC<TimeTrackerLayoutProps> = ({
  manualTime,
  handleManualTimeChange,
  editor,
  isRunning,
  isPaused,
  handleStart,
  handlePause,
  handleLogTime,
  handleDelete,
}) => {
  return (
    <DefaultWrapper>
      <TimeInputWrapper>
        <TimeInputField
          type="number"
          value={manualTime.split(':')[0]}
          onChange={(e) => handleManualTimeChange('hours', e.target.value)}
        />
        <TimeInputSeparator>:</TimeInputSeparator>
        <TimeInputField
          type="number"
          value={manualTime.split(':')[1]}
          onChange={(e) => handleManualTimeChange('minutes', e.target.value)}
        />
        <TimeInputSeparator>:</TimeInputSeparator>
        <TimeInputField
          type="number"
          value={manualTime.split(':')[2]}
          onChange={(e) => handleManualTimeChange('seconds', e.target.value)}
        />
      </TimeInputWrapper>
      <EditorWrapper>
        <EditorContent editor={editor} />
      </EditorWrapper>
      <ButtonGroup>
        {!isRunning ? (
          <LogTimeButton onClick={handleStart}>
            <Play8/> {isPaused ? 'Resume' : 'Start'}
          </LogTimeButton>
        ) : (
          <LogTimeButton onClick={handlePause}>
            <Pause8/> Pause
          </LogTimeButton>
        )}
        {(isRunning || isPaused) && (
          <LogTimeButton onClick={handleLogTime}>
            Log Time
          </LogTimeButton>
        )}
        {(isRunning || isPaused) && (
          <DeleteButton onClick={handleDelete}><Trash12 /></DeleteButton>
        )}
      </ButtonGroup>
    </DefaultWrapper>
  );
};

export default DefaultTimeTracker;