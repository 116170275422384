import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';

export const useAuth = () => {
  return useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) {
          if (error.message === 'Auth session missing!') {
            // Session is missing, but this might be expected for non-authenticated users
            return null;
          }
          throw error;
        }
        return user;
      } catch (error) {
        console.error('Error fetching user:', error);
        // If the error is due to an invalid token, clear the session
        if (error instanceof Error && error.message.includes('JWT')) {
          await supabase.auth.signOut();
        }
        return null;
      }
    },
    retry: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 15, // 15 minutes
  });
};