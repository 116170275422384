import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useNavigate } from '@tanstack/react-router';
import { useAuth } from './useAuth';

export const useOrganization = () => {
  const navigate = useNavigate();
  const { data: user } = useAuth();

  return useQuery({
    queryKey: ['organization', user?.id],
    queryFn: async () => {
      if (!user) return null;

      console.log('useOrganization: Fetching current user');
      const { data: { user: fetchedUser }, error: userError } = await supabase.auth.getUser();
      if (userError) {
        console.error('useOrganization: Error fetching user:', userError);
        if (userError.message === 'Auth session missing!') {
          return null;
        }
        throw userError;
      }
      if (!fetchedUser) {
        console.error('useOrganization: No user found');
        return null;
      }

      console.log('useOrganization: Checking user metadata for current organization');
      const currentOrganizationId = fetchedUser.user_metadata.current_organization_id;
      
      if (currentOrganizationId) {
        console.log('useOrganization: Found current organization in user metadata:', currentOrganizationId);
        // Verify if the organization exists
        const { data: organization, error: orgError } = await supabase
          .from('organizations')
          .select('id')
          .eq('id', currentOrganizationId)
          .single();

        if (orgError || !organization) {
          console.error('useOrganization: Error fetching organization or organization not found:', orgError);
          // Clear the invalid organization ID from user metadata
          await supabase.auth.updateUser({
            data: { current_organization_id: null }
          });
          return null;
        }

        return currentOrganizationId;
      }

      console.log('useOrganization: No current organization in metadata, checking for pending invitations');
      const { data: pendingInvitations, error: invitationError } = await supabase
        .from('pending_invitations')
        .select('id')
        .eq('email', fetchedUser.email);

      if (invitationError) {
        console.error('useOrganization: Error checking pending invitations:', invitationError);
      } else if (pendingInvitations && pendingInvitations.length > 0) {
        console.log('useOrganization: Found pending invitations, redirecting to pending-invitations page');
        navigate({ to: '/pending-invitations' });
        return 'pending_invitations';
      }

      console.log('useOrganization: No pending invitations, fetching from organization_members');
      const { data, error } = await supabase
        .from('organization_members')
        .select('organization_id')
        .eq('user_id', fetchedUser.id)
        .limit(1);

      if (error) {
        console.error('useOrganization: Error fetching organization:', error);
        throw error;
      }
      if (!data || data.length === 0) {
        console.log('useOrganization: No organization found for user');
        return null;
      }

      const organizationId = data[0].organization_id;
      console.log('useOrganization: Found organization:', organizationId);

      // Update user metadata with the first organization as current
      await supabase.auth.updateUser({
        data: { current_organization_id: organizationId }
      });

      return organizationId;
    },
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
    enabled: !!user,
  });
};