import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useNavigate } from '@tanstack/react-router';
import { clientRoute } from './router';
import { supabase } from "./supabaseClient";
import { Client, Project, Expense, TimeEntry, InvoiceData } from "./types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { useCreateInvoiceMutation } from "./hooks/useCreateInvoiceMutation";
import NotesEditor from "./components/NotesEditor";
import Button from "./components/Button";
import { debounce } from "lodash";
import { useOrganization } from "./hooks/useOrganization";
import { formatCurrency } from './utils/formatCurrency';
import AddExpenseDrawer from './components/Expenses/AddExpenseDrawer';
import { useExpenseOperations } from './hooks/useExpenseOperations';
import Tabs from "./components/Tabs";
import { Toggle } from "./components/Toggle";
import InvoiceList from './components/InvoiceList';
import ExpenseList from './components/ExpenseList';
import TimeEntryList from './components/TimeEntryList';
import { useTimeTracking } from './hooks/useTimeTracking';
import AddTimeEntryDrawer from "./components/Time/AddTimeEntryDrawer";
import { Card, CardContent, CardTitle, CardItem, CardFooter } from './components/Card';
import EmptyStateCard from './components/EmptyStateCard';
import EntityPicker from "./components/EntityPicker";
import { formatDate } from './utils/dateUtils';
import InvoiceDrawer from './components/Invoices/InvoiceDrawer';
import InvoiceDetails from './components/Invoices/InvoiceDetails';
import { usePageContext } from './hooks/usePageContext';

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ClientPageWrapper = styled.div`
  width: 100%;
`;

const SyncStatus = styled.div`
  margin-bottom: 10px;
  font-style: italic;
  position: fixed;
  bottom: 0;
  left: 400px;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }
  }
`;

const InputFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 48px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 32px 0 16px 0;
  padding-bottom: 16px;
  color: #333;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const DeleteButton = styled(Button)`
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
`;

const TabContent = styled.div`
`;

const EditableTitle = styled.div`
  font-size: inherit;
  line-height: inherit;
  font-family: 'PP Mori', sans-serif;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 0 8px;
  border-radius: 8px;
  white-space: pre;
  display: inline-block;
  min-width: 1px;
  color: rgba(0, 0, 0, 0.8);

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:empty:before {
    content: attr(data-placeholder);
    color: rgba(0, 0, 0, 0.5);
  }
`;

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 32px 48px;
`;

const StatusWrapper = styled.div`
  margin-right: auto;
`;

const ClientPage: React.FC = () => {
  const { id } = useParams({ from: clientRoute.id });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [localClient, setLocalClient] = useState<Client | null>(null);
  const [localChanges, setLocalChanges] = useState<Partial<Client>>({});
  const createInvoiceMutation = useCreateInvoiceMutation();
  const { data: organizationId } = useOrganization();
  const [activeTab, setActiveTab] = useState("overview");
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isTimeEntryDrawerOpen, setIsTimeEntryDrawerOpen] = useState(false);
  const [selectedTimeEntryId, setSelectedTimeEntryId] = useState<string | null>(null);
  const { stopTimer } = useTimeTracking();
  const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceData | null>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const { setPageHeaderProps } = usePageContext();

  const {
    isExpenseDrawerOpen,
    setIsExpenseDrawerOpen,
    editingExpense,
    handleEditExpense: handleEditExpenseFromHook,
    handleDeleteExpense,
    handleSaveExpense,
    handleCreateExpense: originalHandleCreateExpense,
  } = useExpenseOperations(undefined, id, organizationId);

  const handleEditExpense = useCallback((expense: Expense) => {
    handleEditExpenseFromHook(expense);
    setIsExpenseDrawerOpen(true);
  }, [handleEditExpenseFromHook, setIsExpenseDrawerOpen]);

  const handleAddNewTimeEntry = useCallback(() => {
    setSelectedTimeEntryId(null);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleTimeEntryRowClick = useCallback((timeEntry: TimeEntry) => {
    setSelectedTimeEntryId(timeEntry.id);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleSaveTimeEntry = useCallback(async () => {
    await stopTimer();
    queryClient.invalidateQueries({ queryKey: ['clientTimeEntries', id, organizationId] });
    setIsTimeEntryDrawerOpen(false);
    setSelectedTimeEntryId(null);
  }, [queryClient, id, organizationId, stopTimer]);

  const handleDeleteTimeEntry = useCallback(async (timeEntryId: string) => {
    try {
      await supabase.from('time_entries').delete().eq('id', timeEntryId);
      queryClient.invalidateQueries({ queryKey: ['clientTimeEntries', id, organizationId] });
    } catch (error) {
      console.error('Error deleting time entry:', error);
      alert('Failed to delete time entry. Please try again.');
    }
  }, [queryClient, id, organizationId]);

  const handleCreateTimeEntry = useCallback(async (timeEntryData: Partial<TimeEntry>): Promise<TimeEntry> => {
    const { data, error } = await supabase
      .from('time_entries')
      .insert({ ...timeEntryData, client_id: id, organization_id: organizationId })
      .select()
      .single();

    if (error) throw error;
    queryClient.invalidateQueries({ queryKey: ['clientTimeEntries', id, organizationId] });
    return data;
  }, [id, organizationId, queryClient]);

  const handleProjectStatusChange = useCallback(
    (projectId: string, newStatus: string) => {
      if (!id) return;
      supabase
        .from("projects")
        .update({ status: newStatus })
        .eq("id", projectId)
        .then(({ error }) => {
          if (error) {
            console.error('Error updating project status:', error);
          } else {
            queryClient.invalidateQueries({ queryKey: ["clientProjects", id] });
          }
        });
    },
    [id, queryClient]
  );
  

  const handleCreateExpense = useCallback(() => {
    setIsExpenseDrawerOpen(true);
  }, [setIsExpenseDrawerOpen]);

  const { data: client, isFetching: isFetchingClient } = useQuery<Client, Error>({
    queryKey: ["client", id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("clients")
        .select("*")
        .eq("id", id)
        .single();
      if (error) throw error;
      return data as Client;
    },
    initialData: () => {
      const clients = queryClient.getQueryData<Client[]>(["clients"]);
      return clients?.find((c) => c.id === id);
    },
    staleTime: 60000, // 1 minute
  });

  const { data: invoices, isLoading: isLoadingInvoices } = useQuery<
    InvoiceData[],
    Error
  >({
    queryKey: ["clientInvoices", id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("invoices")
        .select("*")
        .eq("client_id", id);
      if (error) throw error;
      return data as InvoiceData[];
    },
    staleTime: 1000,
  });

  const { data: projects, isLoading: isLoadingProjects } = useQuery<Project[], Error>({
    queryKey: ["clientProjects", id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("projects")
        .select("*")
        .eq("client_id", id);
      if (error) throw error;
      return data as Project[];
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });

  const { data: expenses, isLoading: isLoadingExpenses } = useQuery<Expense[], Error>({
    queryKey: ["clientExpenses", id, organizationId],
    queryFn: async () => {
      if (!organizationId || !id) throw new Error("Organization or Client ID not found");
      
      const { data, error } = await supabase
        .from("expenses")
        .select("*")
        .eq("client_id", id)
        .eq("organization_id", organizationId);
      if (error) throw error;
      return data as Expense[];
    },
    enabled: !!id && !!organizationId,
  });

  const { data: timeEntries, isLoading: isLoadingTimeEntries } = useQuery<TimeEntry[]>({
    queryKey: ['clientTimeEntries', id, organizationId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('time_entries')
        .select('*')
        .eq('client_id', id)
        .eq('organization_id', organizationId);
      if (error) throw error;
      return data;
    },
    enabled: !!id && !!organizationId,
  });

  const updateClientMutation = useMutation({
    mutationFn: async (updatedClient: Partial<Client>) => {
      const { data, error } = await supabase
        .from("clients")
        .update(updatedClient)
        .eq("id", id)
        .select();
      if (error) {
        console.error('Supabase update error:', error);
        throw error;
      }
      return data[0] as Client;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["client", id], (oldData: Client | undefined) => {
        return oldData ? { ...oldData, ...data } : data;
      });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
    onError: (error) => {
      console.error('Mutation error:', error);
    },
  });

  const deleteClientMutation = useMutation({
    mutationFn: () =>
      new Promise<void>((resolve, reject) => {
        supabase
          .from("clients")
          .delete()
          .eq("id", id)
          .then(({ error }) => {
            if (error) reject(error);
            else resolve();
          });
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      navigate({ to: "/clients" });
    },
  });

  const debouncedUpdateClient = useCallback(
    debounce((updatedFields: Partial<Client>) => {
      console.log('Updating client:', updatedFields);
      updateClientMutation.mutate(updatedFields);
    }, 500),
    [updateClientMutation]
  );

  const handleInputChange = (field: keyof Client, value: string | boolean) => {
    setLocalChanges(prev => ({ ...prev, [field]: value }));
    setLocalClient(prev => prev ? { ...prev, [field]: value } : null);
    debouncedUpdateClient({ [field]: value });
  };

  const handleNotesUpdate = (content: string) => {
    handleInputChange("notes", content);
  };

  const handleCreateInvoice = useCallback(async () => {
    if (localClient && organizationId) {
      try {
        const newInvoice = await createInvoiceMutation.mutateAsync({
          customer: localClient.full_name,
          client_id: localClient.id,
          organization_id: organizationId,
        });
        console.log("New invoice created:", newInvoice);
        navigate({ to: "/invoice/$id", params: { id: newInvoice.id } });
      } catch (error) {
        console.error("Error creating invoice:", error);
      }
    }
  }, [localClient, organizationId, createInvoiceMutation, navigate]);

  const renderActionButtons = useCallback(() => {
    switch (activeTab) {
      case "invoices":
        return <Button buttonType="secondary" onClick={handleCreateInvoice}>New Invoice</Button>;
      case "projects":
        return <Button buttonType="secondary" onClick={() => navigate({ to: '/projects', search: { clientId: id } })}>New Project</Button>;
      case "expenses":
        return <Button buttonType="secondary" onClick={handleCreateExpense}>New Expense</Button>;
      case "time-entries":
        return <Button buttonType="secondary" onClick={handleAddNewTimeEntry}>New Time Entry</Button>;
      default:
        return null;
    }
  }, [activeTab, handleCreateInvoice, handleCreateExpense, handleAddNewTimeEntry, navigate, id]);

  const deleteClient = async () => {
    if (!client) return;
    if (
      window.confirm(`Are you sure you want to delete ${client.full_name}?`)
    ) {
      deleteClientMutation.mutate();
    }
  };


  const handleInvoiceRowClick = useCallback((invoice: InvoiceData) => {
    setSelectedInvoice(invoice);
    setIsInvoiceDrawerOpen(true);
  }, []);

  const handleCloseInvoiceDrawer = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsInvoiceDrawerOpen(false);
      setSelectedInvoice(null);
    }
  }, [isAnyPopoverOpen]);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    if (!isAnyPopoverOpen && (event.target as HTMLElement).classList.contains('drawer-overlay')) {
      handleCloseInvoiceDrawer();
    }
  }, [handleCloseInvoiceDrawer, isAnyPopoverOpen]);

  const handleTitleChange = useCallback(() => {
    if (titleRef.current) {
      const newFullName = titleRef.current.textContent || '';
      setLocalClient(prev => prev ? { ...prev, full_name: newFullName } : null);
      debouncedUpdateClient({ full_name: newFullName });
    }
  }, [debouncedUpdateClient]);

  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPageHeaderProps({
      parentPath: "/clients",
      parentName: "Clients",
      title: (
        <EditableTitle
          ref={titleRef}
          contentEditable
          suppressContentEditableWarning
          onInput={handleTitleChange}
          onBlur={handleTitleChange}
          data-placeholder="Enter client name"
          autoFocus={isTitleFocused}
          onFocus={() => setIsTitleFocused(true)}
        >
          {localClient?.full_name}
        </EditableTitle>
      ),
      right: (
        <ButtonWrapper>
        </ButtonWrapper>
      ),
    });
  }, [setPageHeaderProps, localClient, isTitleFocused]);

  useEffect(() => {
    if (client) {
      setLocalClient(prevLocal => ({
        ...client,
        ...prevLocal,
        ...localChanges
      }));
      setLocalChanges({});
    }
  }, [client]);

  useEffect(() => {
    if (client && client.full_name === "") {
      setIsTitleFocused(true);
    }
  }, [client]);

  const isLoading = isFetchingClient || isLoadingInvoices || isLoadingProjects || isLoadingExpenses || isLoadingTimeEntries;

  if (isLoading) {
    return "";
  }

  const totalDue =
    invoices?.reduce((sum, invoice) => sum + invoice.amount_due, 0) || 0;
  const totalExpenses = expenses?.reduce((sum, expense) => sum + expense.amount, 0) || 0;

  const projectStatuses = [
    { id: "not_started", name: "Not Started" },
    { id: "planning", name: "Planning" },
    { id: "in_progress", name: "In Progress" },
    { id: "on_hold", name: "On Hold" },
    { id: "completed", name: "Completed" },
  ];

  return (
    <PageWrapper>
      <ClientPageWrapper ref={containerRef}>
        {localClient && (
          <>
            <SyncStatus>
              {updateClientMutation.isPending && <span>Saving...</span>}
              {updateClientMutation.isError && <span>Error saving changes</span>}
              {updateClientMutation.isSuccess && <span>All changes saved</span>}
            </SyncStatus>
            
            <Tabs
              tabs={[
                { id: "overview", label: "Overview" },
                { id: "invoices", label: "Invoices" },
                { id: "projects", label: "Projects" },
                { id: "expenses", label: "Expenses" },
                { id: "time-entries", label: "Time" },
              ]}
              activeTab={activeTab}
              onTabChange={setActiveTab}
              actionButtons={renderActionButtons()}
            />

            <TabContent>
              {activeTab === "overview" && (
                <>
                  <SectionTitle>Client Details</SectionTitle>
                  <DeleteButton onClick={deleteClient}>Delete Client</DeleteButton>

                  <InputFieldsContainer>
                    <InputField>
                      <label htmlFor="company">Company</label>
                      <input
                        id="company"
                        value={localClient.company || ""}
                        onChange={(e) => handleInputChange("company", e.target.value)}
                        placeholder="Company"
                      />
                    </InputField>
                    <InputField>
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        value={localClient.email || ""}
                        onChange={(e) => handleInputChange("email", e.target.value)}
                        placeholder="Email"
                      />
                    </InputField>
                    <InputField>
                      <label htmlFor="phone">Phone</label>
                      <input
                        id="phone"
                        value={localClient.phone || ""}
                        onChange={(e) => handleInputChange("phone", e.target.value)}
                        placeholder="Phone"
                      />
                    </InputField>
                    <InputField>
                      <label htmlFor="activeStatus">Active Status</label>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Toggle
                          checked={localClient.is_active}
                          onChange={(checked) => handleInputChange("is_active", checked)}
                        />
                        {localClient.is_active ? "Active" : "Inactive"}
                      </div>
                    </InputField>
                  </InputFieldsContainer>

                  <SectionTitle>Address</SectionTitle>
                    <InputFieldsContainer>
                      <InputField>
                        <label htmlFor="addressLine1">Address Line 1</label>
                        <input
                          id="addressLine1"
                          value={localClient.address_line1 || ""}
                          onChange={(e) => handleInputChange("address_line1", e.target.value)}
                          placeholder="Address Line 1"
                        />
                      </InputField>
                      <InputField>
                        <label htmlFor="addressLine2">Address Line 2</label>
                        <input
                          id="addressLine2"
                          value={localClient.address_line2 || ""}
                          onChange={(e) => handleInputChange("address_line2", e.target.value)}
                          placeholder="Address Line 2"
                        />
                      </InputField>
                      <InputField>
                        <label htmlFor="city">City</label>
                        <input
                          id="city"
                          value={localClient.city || ""}
                          onChange={(e) => handleInputChange("city", e.target.value)}
                          placeholder="City"
                        />
                      </InputField>
                      <InputField>
                        <label htmlFor="state">State/Province</label>
                        <input
                          id="state"
                          value={localClient.state || ""}
                          onChange={(e) => handleInputChange("state", e.target.value)}
                          placeholder="State/Province"
                        />
                      </InputField>
                      <InputField>
                        <label htmlFor="postalCode">Postal Code</label>
                        <input
                          id="postalCode"
                          value={localClient.postal_code || ""}
                          onChange={(e) => handleInputChange("postal_code", e.target.value)}
                          placeholder="Postal Code"
                        />
                      </InputField>
                      <InputField>
                        <label htmlFor="country">Country</label>
                        <input
                          id="country"
                          value={localClient.country || ""}
                          onChange={(e) => handleInputChange("country", e.target.value)}
                          placeholder="Country"
                        />
                      </InputField>
                    </InputFieldsContainer>

                  <SectionTitle>Financial Overview</SectionTitle>
                  <p>Total Due: ${totalDue.toFixed(2)}</p>
                  <p>Total Expenses: {formatCurrency(totalExpenses)}</p>

                  <SectionTitle>Notes</SectionTitle>
                  <NotesEditor
                    initialContent={localClient.notes || ""}
                    onUpdate={handleNotesUpdate}
                  />
                </>
              )}

              {activeTab === "invoices" && (
                <InvoiceList
                  invoices={invoices || []}
                  isLoading={isLoadingInvoices}
                  onCreateInvoice={handleCreateInvoice}
                  containerRef={containerRef}
                  onSelectionChange={(selectedIds) => {
                    console.log('Selected invoice IDs in ClientPage:', selectedIds);
                    // Handle selection change here if needed
                  }}
                  onRowClick={handleInvoiceRowClick}
                  selectedInvoiceId={selectedInvoice?.id || null}
                />
              )}

              {activeTab === "projects" && (
                <>
                  <PageGrid>
                    {projects && projects.length > 0 ? (
                      projects.map((project) => (
                        <Card
                          key={project.id}
                          onClick={() => navigate({ to: '/project/$id', params: { id: project.id } })}
                        >
                          <CardContent>
                            <CardTitle>{project.name || "Untitled Project"}</CardTitle>
                            <CardItem>{project.description || "No description"}</CardItem>
                          </CardContent>
                          <CardFooter>
                            <StatusWrapper onClick={(e) => e.stopPropagation()}>
                              <EntityPicker
                                selectedId={project.status || 'planning'}
                                onChange={(statusId) => handleProjectStatusChange(project.id, statusId as string)}
                                entities={projectStatuses}
                                label="Status"
                                allowUnassigned={false}
                                placement="bottom-start"
                              />
                            </StatusWrapper>
                            {project.due_date && (
                              <CardItem>Due {formatDate(project.due_date)}</CardItem>
                            )}
                          </CardFooter>
                        </Card>
                      ))
                    ) : (
                      <EmptyStateCard
                        title="Create a new project"
                        description="Add your first project for this client to get started"
                        actionText="Create New Project"
                        onClick={() => navigate({ to: '/projects', search: { clientId: id } })}
                      />
                    )}
                  </PageGrid>
                </>
              )}

              {activeTab === "expenses" && (
                <ExpenseList
                  expenses={expenses}
                  isLoading={isLoadingExpenses}
                  onCreateExpense={handleCreateExpense}
                  onEditExpense={handleEditExpense}
                  containerRef={containerRef}
                  onSelectionChange={(selectedIds) => {
                    console.log('Selected expense IDs in ClientPage:', selectedIds);
                  }}
                />
              )}

              {activeTab === "time-entries" && (
                <TimeEntryList
                  timeEntries={timeEntries}
                  isLoading={isLoadingTimeEntries}
                  onCreateTimeEntry={handleAddNewTimeEntry}
                  onEditTimeEntry={handleTimeEntryRowClick}
                  containerRef={containerRef}
                  onSelectionChange={(selectedIds) => {
                    console.log('Selected time entry IDs in ClientPage:', selectedIds);
                  }}
                />
              )}
            </TabContent>
          </>
        )}
      </ClientPageWrapper>
      <AddExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        setIsOpen={setIsExpenseDrawerOpen}
        expense={editingExpense}
        onSave={handleSaveExpense}
        onDelete={handleDeleteExpense}
        onCreate={originalHandleCreateExpense}
        organizationId={organizationId}
        defaultClientId={id}
      />
      <AddTimeEntryDrawer
        isOpen={isTimeEntryDrawerOpen}
        setIsOpen={setIsTimeEntryDrawerOpen}
        timeEntry={timeEntries?.find(entry => entry.id === selectedTimeEntryId) || null}
        onSave={handleSaveTimeEntry}
        onDelete={() => selectedTimeEntryId && handleDeleteTimeEntry(selectedTimeEntryId)}
        onCreate={handleCreateTimeEntry}
        organizationId={organizationId}
        defaultClientId={id}
        onOverlayClick={() => setIsTimeEntryDrawerOpen(false)}
        queryKey={['clientTimeEntries', id, organizationId]}
        stopTimer={stopTimer}
      />
      <InvoiceDrawer
        isOpen={isInvoiceDrawerOpen}
        setIsOpen={setIsInvoiceDrawerOpen}
        selectedInvoice={selectedInvoice}
        onOverlayClick={handleOverlayClick}
        onPopoverOpenChange={setIsAnyPopoverOpen}
        title="Invoice Details"
      >
        {selectedInvoice && (
          <InvoiceDetails
            key={selectedInvoice.id}
            invoice={selectedInvoice}
            clients={[]}
            navigate={navigate}
            queryClient={queryClient}
            payments={[]}
          />
        )}
      </InvoiceDrawer>
    </PageWrapper>
  );
};

export default ClientPage;