import React, { useEffect, useState } from 'react';
import { useNavigate } from '@tanstack/react-router';
import { supabase } from './supabaseClient';
import { useAuth } from './hooks/useAuth';
import { acceptInviteRoute } from './router';
import styled from 'styled-components';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
`;

const Message = styled.div`
  font-size: 18px;
  text-align: center;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

const AcceptInvitePage: React.FC = () => {
  console.log('AcceptInvitePage rendered');
  const navigate = useNavigate();
  const { data: user, isLoading: isUserLoading, error: userError } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { email, organizationId } = acceptInviteRoute.useSearch();

  useEffect(() => {
    if (!email || !organizationId) {
      setError('Invalid invitation link');
      return;
    }

    const acceptInvitation = async () => {
      if (isUserLoading) return;

      if (!user) {
        console.log('User not authenticated, storing invitation details');
        localStorage.setItem('pendingInvitation', JSON.stringify({ email, organizationId }));
        navigate({ to: '/' }); // Redirect to login/signup page
        return;
      }

      setIsProcessing(true);
      try {
        // Check if the user is already a member of the organization
        const { data: existingMember, error: existingMemberError } = await supabase
          .from('organization_members')
          .select('*')
          .eq('user_id', user.id)
          .eq('organization_id', organizationId)
          .single();

        if (existingMemberError && existingMemberError.code !== 'PGRST116') {
          throw existingMemberError;
        }

        if (existingMember) {
          console.log('User is already a member of the organization');
          // User is already a member, update their current organization and redirect
          await supabase.auth.updateUser({
            data: { current_organization_id: organizationId }
          });
          navigate({ to: '/' });
          return;
        }

        // Proceed with invitation acceptance
        const { data: invitation, error: inviteError } = await supabase
          .from('pending_invitations')
          .select('*')
          .eq('email', email)
          .eq('organization_id', organizationId)
          .single();

        if (inviteError || !invitation) {
          console.error('Invalid or expired invitation:', inviteError);
          setError('Invalid or expired invitation');
          return;
        }

        console.log('Accepting invitation for user:', user.id);
        const { error: insertMemberError } = await supabase
          .from('organization_members')
          .insert({ user_id: user.id, organization_id: organizationId });

        if (insertMemberError) {
          console.error('Error inserting new organization member:', insertMemberError);
          throw insertMemberError;
        }

        console.log('Deleting accepted invitation');
        await supabase
          .from('pending_invitations')
          .delete()
          .eq('id', invitation.id);

        console.log('Updating user metadata with new organization');
        await supabase.auth.updateUser({
          data: { current_organization_id: organizationId }
        });

        // Clear the pending invitation from localStorage
        localStorage.removeItem('pendingInvitation');

        console.log('Invitation accepted successfully, redirecting to home');
        navigate({ to: '/' });
      } catch (error) {
        console.error('Error accepting invitation:', error);
        setError('An error occurred while accepting the invitation');
      } finally {
        setIsProcessing(false);
      }
    };

    acceptInvitation();
  }, [email, organizationId, user, isUserLoading, navigate]);

  if (isUserLoading) {
    return (
      <PageWrapper>
        <Message>Loading...</Message>
      </PageWrapper>
    );
  }

  if (userError) {
    return (
      <PageWrapper>
        <Message>Error: {userError.message}</Message>
        <Button onClick={() => navigate({ to: '/' })}>Go to Login</Button>
      </PageWrapper>
    );
  }

  if (!user) {
    return (
      <PageWrapper>
        <Message>Please log in to accept the invitation</Message>
        <Button onClick={() => navigate({ to: '/' })}>Go to Login</Button>
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <Message>{error}</Message>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper>
      <Message>Processing your invitation...</Message>
      {isProcessing && <Message>Please wait while we set up your account.</Message>}
    </PageWrapper>
  );
};

export default AcceptInvitePage;