import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';
import '../../RightDrawer.css';
import styled from 'styled-components';
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../../supabaseClient";
import { Expense, ExpenseCategory, EXPENSE_CATEGORIES } from "../../types";
import Button from "../Button";
import { useClients } from "../../hooks/useClients";
import { useInvoices } from "../../hooks/useInvoices";
import { useProjects } from "../../hooks/useProjects";
import EntityPicker from "../EntityPicker";
import { Close12 } from '../Icon';
import DatePicker from "../DatePicker";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  outline: none;
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  background-color: #f5f5f2;
  height: 100%;
  overflow-y: scroll;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const Form = styled.form`
  display: grid;
  gap: 10px;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.05);
  border: 0;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 20px;
  outline: none;
`;

const CategorySelect = styled.select`
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.05);
  border: 0;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 20px;
  outline: none;
`;

const DrawerColumns = styled.div`
  display: flex;
  height: 100%;
`;

const DrawerLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0,0,0,0.05);
  padding: 32px;
  border-right: 1px solid rgba(0,0,0,0.05);
`;

const DrawerRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const DrawerRightContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
`;

const ImageUploadArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    min-height: 100px;
    outline: none;
    padding: 24px 40px;
    border-top: 1px solid rgba(0,0,0,0.1);
    font-size: 16px;
    line-height: 20px;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const ExpenseDetails = styled.div`
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DrawerTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  padding: 32px 40px 0 40px;
  margin: 0;
`;

const ButtonFooter = styled.div`
  display: flex;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f5f5f2;
  gap: 10px;
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const DeleteButton = styled(Button)`
  background-color: #ff4d4f;
  color: white;

  &:hover {
    background-color: #ff7875;
  }
`;

interface AddExpenseDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  expense?: Expense | null;
  onSave: (expense: Expense) => void;
  onDelete: (expenseId: string) => void;
  onCreate: (expenseData: Partial<Expense>) => Promise<Expense>;
  organizationId?: string;
  defaultProjectId?: string;
  defaultClientId?: string;
  projectId?: string;
}

const AddExpenseDrawer: React.FC<AddExpenseDrawerProps> = ({
  isOpen,
  setIsOpen,
  expense,
  onSave,
  onDelete,
  onCreate,
  organizationId,
  defaultProjectId,
  defaultClientId,
  projectId,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [formData, setFormData] = useState<Omit<Expense, "id" | "user_id">>({
    date: new Date().toISOString().split("T")[0],
    amount: 0,
    description: "",
    client_id: null,
    project_id: null,
    invoice_id: null,
    category: "" as ExpenseCategory,
    receipt_image_path: "",
    organization_id: "",
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add expense description...',
      }),
    ],
    content: formData.description || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setFormData(prev => ({ ...prev, description: html }));
    },
  });

  useEffect(() => {
    if (editor && formData.description !== editor.getHTML()) {
      editor.commands.setContent(formData.description || '');
    }
  }, [editor, formData.description]);

  useEffect(() => {
    if (expense) {
      setFormData({
        date: expense.date || new Date().toISOString().split("T")[0],
        amount: expense.amount || 0,
        description: expense.description || "",
        client_id: expense.client_id || defaultClientId || null,
        project_id: expense.project_id || defaultProjectId || projectId || null,
        invoice_id: expense.invoice_id || null,
        receipt_image_path: expense.receipt_image_path || "",
        organization_id: expense.organization_id || "",
        category: expense.category || ("" as ExpenseCategory),
      });
      if (expense.receipt_image_path) {
        fetchImageUrl(expense.receipt_image_path);
      }
    } else {
      // Reset form data when no expense is provided (for new expense)
      setFormData({
        date: new Date().toISOString().split("T")[0],
        amount: 0,
        description: "",
        client_id: defaultClientId || null,
        project_id: defaultProjectId || projectId || null,
        invoice_id: null,
        category: "" as ExpenseCategory,
        receipt_image_path: "",
        organization_id: organizationId || "",
      });
      setPreviewUrl(null);
      setSelectedFile(null);
    }
  }, [expense, defaultProjectId, defaultClientId, projectId, organizationId]);

  const fetchImageUrl = async (path: string) => {
    try {
      const { data } = await supabase.storage
        .from("expense_receipts")
        .createSignedUrl(path, 3600); // URL valid for 1 hour
      if (data) {
        setPreviewUrl(data.signedUrl);
      }
    } catch (error) {
      console.error("Error fetching image URL:", error);
    }
  };

  const deleteExpenseMutation = useMutation({
    mutationFn: async (id: string) => {
      const { error } = await supabase.from("expenses").delete().eq("id", id);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expenses"] });
      setIsOpen(false);
    },
  });

  const { data: clients } = useClients();
  const { data: projects } = useProjects();
  const { data: invoices } = useInvoices();

  const updateExpenseMutation = useMutation({
    mutationFn: async (updatedExpense: Expense) => {
      const { data, error } = await supabase
        .from("expenses")
        .update(updatedExpense)
        .eq("id", updatedExpense.id)
        .select()
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["expenses"] });
      setIsOpen(false);
    },
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!organizationId) {
      alert("No organization selected. Please select an organization and try again.");
      return;
    }

    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      alert("No user found. Please log in and try again.");
      return;
    }

    let receipt_image_path = formData.receipt_image_path;

    if (selectedFile) {
      const fileExt = selectedFile.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      try {
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from("expense_receipts")
          .upload(filePath, selectedFile, {
            upsert: true,
            contentType: selectedFile.type,
          });

        if (uploadError) throw uploadError;

        if (uploadData) {
          receipt_image_path = filePath;
          console.log("File uploaded successfully. Path:", receipt_image_path);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload receipt image. Please try again.");
        return;
      }
    }

    const expenseData = {
      ...formData,
      user_id: user.id, // Ensure user_id is set here
      receipt_image_path,
      organization_id: organizationId,
      client_id: formData.client_id || null,
      project_id: formData.project_id || null,
      invoice_id: formData.invoice_id || null,
    };

    // Ensure all required fields are filled
    if (!expenseData.date || !expenseData.amount || !expenseData.description || !expenseData.category) {
      alert("Please fill all required fields");
      return;
    }

    try {
      if (expense?.id) {
        await updateExpenseMutation.mutateAsync({
          ...expenseData,
          id: expense.id,
        } as Expense);
        onSave({ ...expenseData, id: expense.id } as Expense);
      } else {
        const newExpense = await onCreate({
          ...expenseData,
          project_id: expenseData.project_id || defaultProjectId || projectId,
          organization_id: organizationId,
        });
        onSave(newExpense);
      }
      setIsOpen(false);
    } catch (error) {
      console.error("Error saving expense:", error);
      if (error instanceof Error) {
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
      }
      if (typeof error === 'object' && error !== null) {
        console.error("Error details:", JSON.stringify(error, null, 2));
      }
      alert(
        `Failed to save expense. Please check the console for more details. Error: ${error instanceof Error ? error.message : 'Unknown error'}`
      );
    }
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value as ExpenseCategory;
    setFormData((prev) => ({
      ...prev,
      category,
    }));
  };

  const handleDelete = () => {
    if (
      expense?.id &&
      window.confirm("Are you sure you want to delete this expense?")
    ) {
      deleteExpenseMutation.mutate(expense.id, {
        onSuccess: () => {
          onDelete(expense.id);
          setIsOpen(false);
        },
        onError: (error) => {
          console.error("Error deleting expense:", error);
          alert("Failed to delete expense. Please try again.");
        },
      });
    }
  };

  const handleClientChange = (clientId: string | null) => {
    setFormData((prev) => ({ ...prev, client_id: clientId }));
  };

  const handleProjectChange = (projectId: string | null) => {
    setFormData((prev) => ({ ...prev, project_id: projectId }));
  };

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isAnyPopoverOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnPopover = (clickedElement as Element).closest('.entity-picker-popover') !== null;

      if (!isClickInsideDrawer && !isClickOnPopover) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isAnyPopoverOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen]);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction="right"
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DrawerColumns>
              <DrawerLeft>
                {previewUrl ? (
                  <>
                    <ImagePreview src={previewUrl} alt="Receipt" />
                    <Button
                      type="button"
                      onClick={() => {
                        setPreviewUrl(null);
                        setFormData((prev) => ({ ...prev, receipt_image_path: "" }));
                      }}
                    >
                      Remove Image
                    </Button>
                  </>
                ) : (
                  <ImageUploadArea onClick={() => document.getElementById('file-input')?.click()}>
                    <p>Click to upload an image</p>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={handleFileSelect}
                      style={{ display: 'none' }}
                    />
                  </ImageUploadArea>
                )}
              </DrawerLeft>
              <DrawerRight>
                <DrawerRightContent>
                  <DrawerHeader>
                    <DrawerTitle>{expense ? "Edit Expense" : "Add New Expense"}</DrawerTitle>
                    <CloseButton onClick={handleClose}><Close12 /></CloseButton>
                  </DrawerHeader>
                  <Form onSubmit={handleSubmit}>
                    <ExpenseDetails>
                      <DatePicker
                        selectedDate={formData.date ? new Date(formData.date) : null}
                        onChange={(date) => {
                          if (date) {
                            setFormData((prev) => ({ ...prev, date: date.toISOString().split('T')[0] }));
                          }
                        }}
                        label="Date"
                        id="expense-date"
                        placeholder="Select date"
                      />
                      <Input
                        type="number"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                        step="0.01"
                        required
                      />
                      <CategorySelect
                        name="category"
                        value={formData.category}
                        onChange={handleCategoryChange}
                        required
                      >
                        <option value="">Select a category</option>
                        {Object.entries(EXPENSE_CATEGORIES).map(([category, subcategories]) => (
                          <optgroup key={category} label={category}>
                            {subcategories.map((subcategory) => (
                              <option key={`${category}-${subcategory}`} value={`${category}:${subcategory}`}>
                                {subcategory}
                              </option>
                            ))}
                          </optgroup>
                        ))}
                      </CategorySelect>
                      <EntityPicker
                        selectedId={formData.client_id}
                        onChange={handleClientChange}
                        entities={clients?.map(client => ({ id: client.id, name: client.full_name })) || []}
                        label="Client"
                        allowUnassigned={true}
                        onPopoverOpenChange={setIsAnyPopoverOpen}
                      />
                      <EntityPicker
                        selectedId={formData.project_id}
                        onChange={handleProjectChange}
                        entities={projects?.map(project => ({ id: project.id, name: project.name })) || []}
                        label="Project"
                        allowUnassigned={true}
                        onPopoverOpenChange={setIsAnyPopoverOpen}
                      />
                      <EntityPicker
                        selectedId={formData.invoice_id || null}
                        onChange={(invoiceId) => handleChange({ target: { name: 'invoice_id', value: invoiceId } } as React.ChangeEvent<HTMLSelectElement>)}
                        entities={invoices?.map(invoice => ({ id: invoice.id, name: invoice.invoice_number })) || []}
                        label="Invoice"
                        allowUnassigned={true}
                        onPopoverOpenChange={setIsAnyPopoverOpen}
                      />
                    </ExpenseDetails>
                    <EditorWrapper>
                      <EditorContent editor={editor} />
                    </EditorWrapper>
                  </Form>
                </DrawerRightContent>
                <ButtonFooter>
                  <Button type="submit" onClick={handleSubmit}>
                    {expense ? "Update Expense" : "Create Expense"}
                  </Button>
                  {expense && (
                    <DeleteButton type="button" onClick={handleDelete}>
                      Delete Expense
                    </DeleteButton>
                  )}
                  <CancelButton type="button" onClick={handleClose}>
                    Cancel
                  </CancelButton>
                </ButtonFooter>
              </DrawerRight>
            </DrawerColumns>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default AddExpenseDrawer;