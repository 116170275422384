import React, { useMemo } from 'react';
import { Expense } from '../types';
import DataTable from './DataTable';
import { ColumnDef } from '@tanstack/react-table';
import { Expense32 } from './Icon';
import { formatCurrency } from '../utils/formatCurrency';

interface ExpenseListProps {
  expenses: Expense[] | undefined;
  isLoading: boolean;
  onCreateExpense: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
  onSelectionChange?: (selectedIds: string[]) => void;
  onEditExpense: (expense: Expense) => void;
}

const ExpenseList: React.FC<ExpenseListProps> = ({
  expenses,
  isLoading,
  onCreateExpense,
  containerRef,
  onSelectionChange,
  onEditExpense,
}) => {

  const expenseColumns: ColumnDef<Expense>[] = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        cell: ({ getValue }) => new Date(getValue() as string).toLocaleDateString(),
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        cell: ({ getValue }) => formatCurrency(getValue() as number),
      },
      {
        accessorKey: 'category',
        header: 'Category',
      },
    ],
    []
  );

  const handleRowClick = (expense: Expense) => {
    onEditExpense(expense);
  };

  const getExpenseRowKey = (expense: Expense) => expense.id;

  const expenseEmptyState = useMemo(() => ({
    icon: <Expense32 />,
    message: "No expenses found",
    subMessage: "Create a new expense to get started",
    action: {
      label: "New Expense",
      onClick: onCreateExpense
    }
  }), [onCreateExpense]);

  const handleSelectionChange = (selectedIds: string[]) => {
    console.log('Selected expense IDs:', selectedIds);
    if (onSelectionChange) {
      onSelectionChange(selectedIds);
    }
  };

  return (
    <DataTable
      columns={expenseColumns}
      data={expenses || []}
      isLoading={isLoading}
      onRowClick={handleRowClick}
      getRowKey={getExpenseRowKey}
      containerRef={containerRef}
      emptyState={expenseEmptyState}
      onSelectionChange={handleSelectionChange}
    />
  );
};

export default ExpenseList;