import { createClient } from '@supabase/supabase-js';

const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL as string;
const SUPABASE_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY as string;

if (!SUPABASE_URL || !SUPABASE_KEY) {
  console.error('Supabase URL or Anon Key is missing');
}

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
  },
});

const MAX_RETRIES = 5;
const RETRY_DELAY = 2000; // 2 seconds

const setupRealtimeSubscription = () => {
  const channel = supabase.channel('global_updates');

  channel
    .on('postgres_changes', { event: '*', schema: 'public', table: 'tasks' }, (payload) => {
      console.log('Task change:', payload);
      // Emit a custom event with the payload
      window.dispatchEvent(new CustomEvent('TASK_UPDATED', { detail: payload }));
    })
    .subscribe((status) => {
      console.log(`Subscription status: ${status}`);
      if (status === 'SUBSCRIBED') {
        console.log('Realtime subscription established');
      } else if (status === 'CLOSED') {
        console.log('Realtime CLOSED, attempting to reconnect...');
        retryConnection(0);
      }
    });

  return channel;
};

const retryConnection = (retryCount: number) => {
  if (retryCount >= MAX_RETRIES) {
    console.error('Max retries reached. Unable to establish a stable connection.');
    return;
  }

  console.log(`Retry attempt ${retryCount} of ${MAX_RETRIES}`);
  setTimeout(() => {
    console.log('Reconnecting...');
    const newChannel = setupRealtimeSubscription();
    newChannel.unsubscribe().then(() => {
      console.log('Unsubscribed from previous channel');
    });
  }, RETRY_DELAY);
};

// Initialize connection
setupRealtimeSubscription();

// Function to check Supabase connection
const checkSupabaseConnection = async () => {
  try {
    const { error } = await supabase.from('tasks').select('id').limit(1);
    return !error;
  } catch (error) {
    console.error('Supabase connection error:', error);
    return false;
  }
};

// Check initial connection
checkSupabaseConnection().then((isConnected) => {
  if (isConnected) {
    console.log('Supabase connection established');
  } else {
    console.error('Failed to connect to Supabase. Please check your configuration.');
  }
});

// Log current Realtime connection status periodically
type ConnectionState = 'CONNECTING' | 'OPEN' | 'CLOSING' | 'CLOSED';

const connectionStateMap: Record<ConnectionState, string> = {
  'CONNECTING': 'Connecting',
  'OPEN': 'Open',
  'CLOSING': 'Closing',
  'CLOSED': 'Closed'
};

setInterval(() => {
  // Call the connectionState function and convert its return value to our ConnectionState type
  const state = supabase.realtime.connectionState() as unknown as ConnectionState;
  console.log('Current Realtime connection status:', connectionStateMap[state] || state);
}, 5000);