import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useMatches, useNavigate } from "@tanstack/react-router";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import SidebarLeft from "./components/SidebarLeft";
import { supabase } from "./supabaseClient";
import Auth from "./Auth";
import { useOrganization } from "./hooks/useOrganization";
import PendingInvitationsPage from './PendingInvitationsPage';
import CreateOrganizationPage from './CreateOrganizationPage';
import LoadingScreen from './components/LoadingScreen';
import OnboardingPage from './OnboardingPage';
import { debounce } from 'lodash';
import { useAuth } from './hooks/useAuth';
import PageHeader, { PageHeaderProps } from './components/PageHeader'; // Import PageHeader
import { PageContext } from './contexts/PageHeaderContext';

// Define the UserProfile interface here
interface UserProfile {
  full_name: string | null;
  onboarding_completed: boolean;
}

const Layout: React.FC = () => {
  const { data: user, isLoading: authLoading, error: authError } = useAuth();
  const matches = useMatches();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: organizationId, isLoading: orgLoading, error: orgError } = useOrganization();
  const isPublicRoute = matches.some(match => match.pathname.startsWith('/share/'));

  const fetchRouteData = useCallback(async (route: string) => {
    if (!organizationId) {
      console.log('No organizationId available, skipping prefetch');
      return null;
    }

    const commonOptions = {
      eq: 'organization_id',
      value: organizationId,
      orderBy: { column: 'updated_at', ascending: false },
    };

    const routeConfigs = {
      '/': { table: 'invoices', limit: 20 },
      '/time': { table: 'time_entries', limit: 20, orderBy: { column: 'start_time', ascending: false } },
      '/clients': { table: 'clients', limit: 50, orderBy: { column: 'created_at', ascending: false } },
      '/projects': { table: 'projects', limit: 20, orderBy: { column: 'created_at', ascending: false } },
      '/tasks': { table: 'tasks', limit: 50, orderBy: { column: 'created_at', ascending: false } },
    };

    const config = routeConfigs[route as keyof typeof routeConfigs];
    if (!config) return null;

    try {
      let query = supabase
        .from(config.table)
        .select('*')
        .eq(commonOptions.eq, commonOptions.value)
        .limit(config.limit);

      // Use the route-specific orderBy if available, otherwise use the common orderBy
      const orderBy = 'orderBy' in config ? config.orderBy : commonOptions.orderBy;
      query = query.order(orderBy.column, { ascending: orderBy.ascending });

      const { data, error } = await query;

      if (error) throw error;
      if (route === '/time') {
        queryClient.setQueryData(['timeEntries', organizationId], data);
      } else {
        queryClient.setQueryData(['route', route, organizationId], data);
      }
      return data;
    } catch (error) {
      console.error(`Error fetching data for ${route}:`, error);
      return null;
    }
  }, [organizationId]);

  const debouncedPrefetchRoute = useCallback(
    debounce((route: string) => {
      if (organizationId) {
        console.log(`Prefetching data for route: ${route}`);
        queryClient.prefetchQuery({
          queryKey: ['route', route, organizationId],
          queryFn: () => fetchRouteData(route),
          staleTime: 5 * 60 * 1000, // 5 minutes
        });
      }
    }, 300),
    [queryClient, organizationId, fetchRouteData]
  );

  useEffect(() => {
    const prefetchQueries = async () => {
      if (user && organizationId) {
        // Existing prefetch queries
        queryClient.prefetchQuery({
          queryKey: ['organization', organizationId],
          queryFn: async () => {
            const { data, error } = await supabase
              .from('organizations')
              .select('*')
              .eq('id', organizationId)
              .single();
            if (error) throw error;
            return data;
          },
          staleTime: 60 * 60 * 1000, // 1 hour
        });

        queryClient.prefetchQuery({
          queryKey: ['organizationMembers', organizationId],
          queryFn: async () => {
            const { data: memberData, error: memberError } = await supabase
              .from('organization_members')
              .select('user_id')
              .eq('organization_id', organizationId);

            if (memberError) throw memberError;

            if (!memberData || memberData.length === 0) {
              return [];
            }

            // Filter out null user_ids and remove duplicates
            const userIds = [...new Set(memberData.map(member => member.user_id).filter(Boolean))];

            if (userIds.length === 0) {
              return [];
            }

            const { data: userData, error: userError } = await supabase
              .from('profiles')
              .select('id, full_name, email')
              .in('id', userIds);

            if (userError) throw userError;

            return userData || [];
          },
          staleTime: 30 * 60 * 1000, // 30 minutes
        });

        // Prefetch route data
        const routesToPrefetch = ['/', '/clients', '/projects', '/tasks'];
        routesToPrefetch.forEach(debouncedPrefetchRoute);
      }
    };

    prefetchQueries();
  }, [user, organizationId, queryClient, debouncedPrefetchRoute]);

  const { data: userProfile, isLoading: isProfileLoading } = useQuery<UserProfile | null, Error>({
    queryKey: ['userProfile', user?.id],
    queryFn: async () => {
      if (!user?.id) return null;
      const { data, error } = await supabase
        .from('profiles')
        .select('full_name, onboarding_completed')
        .eq('id', user.id)
        .single();
      if (error) throw error;
      return data as UserProfile | null;
    },
    enabled: !!user?.id,
  });

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event) => {
        if (event === 'SIGNED_OUT') {
          queryClient.clear();
          navigate({ to: '/' });
        }
      }
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, [navigate, queryClient]);

  const [pageHeaderProps, setPageHeaderProps] = useState<PageHeaderProps>({});

  const renderContent = useMemo(() => {
    if (authLoading || orgLoading || isProfileLoading) {
      return <LoadingScreen />;
    }

    if (isPublicRoute) {
      return <Outlet />;
    }

    if (authError) {
      console.error('Authentication error:', authError);
      return <Auth />;
    }

    if (!user) {
      return <Auth />;
    }

    if (!userProfile || !userProfile.full_name || !userProfile.onboarding_completed) {
      return <OnboardingPage />;
    }

    if (organizationId === null && !orgLoading) {
      if (orgError) {
        console.error('Error fetching organization:', orgError);
      }
      return <CreateOrganizationPage />;
    }

    if (organizationId === 'pending_invitations') {
      return <PendingInvitationsPage />;
    }

    if (organizationId) {
      return (
        <div className="app">
          <div className="sidebar">
            <SidebarLeft />
          </div>
          <div className="main-content">
            <PageHeader {...pageHeaderProps} />
            <Outlet />
          </div>
        </div>
      );
    }

    return <LoadingScreen />;
  }, [user, authLoading, authError, orgLoading, isProfileLoading, isPublicRoute, userProfile, organizationId, orgError, pageHeaderProps]);

  return (
    <PageContext.Provider value={{ setPageHeaderProps }}>
      {renderContent}
    </PageContext.Provider>
  );
};

export default Layout;