import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Task, Client, Project, TaskStatus } from '../../types';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import DatePicker from '../DatePicker';
import EntityPicker from '../EntityPicker';
import { Close12, Client12, Project12, Calendar12, Todo12, InProgress12, Done12 } from '../Icon';
import Button from '../Button'; // Make sure to import the Button component
import { Trash12 } from '../Icon'; // Import the trash icon
import { debounce } from 'lodash';

const TaskDetailsWrapper = styled.div`
  padding: 0;
`;

const TaskTitle = styled.input`
  width: 100%;
  padding: 48px 48px 24px 48px;
  border: 0;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  box-sizing: border-box;
  background: transparent;
`;

const PropertiesList = styled.div`
  padding: 0 40px 40px 40px;
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    min-height: 100px;
    outline: none;
    padding: 24px 48px 200px 48px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const StatusIconWrapper = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  svg path {
    fill: ${props => props.$color};
  }
`;

const StatusIcon = styled.svg`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
`;

const DeleteButton = styled(Button)`
  color: #ff4d4f;
  background-color: transparent;
  margin-top: 16px;
  svg path {
    fill: #ff4d4f;
  }
  &:hover {
    color: #ff4d4f;
    background-color: rgba(255, 77, 79, 0.1);
  }
`;

interface TaskDetailsProps {
  task: Task | null | undefined;
  updateTask: (updatedTask: Partial<Task>, isEditing: boolean) => void;
  updateLocalTask: (updatedTask: Partial<Task>) => void;
  clients: Client[];
  projects: Project[];
  organizationUsers: { id: string; full_name: string | null; email: string }[];
  taskStatuses: TaskStatus[];
  onPopoverOpenChange: (isOpen: boolean) => void;
  onDeleteTask: (taskId: string) => void;
  autoFocus?: boolean;
}

const TaskDetails: React.FC<TaskDetailsProps> = ({
  task,
  updateTask,
  updateLocalTask,
  clients,
  projects,
  organizationUsers,
  taskStatuses,
  onPopoverOpenChange,
  onDeleteTask,
  autoFocus,
}) => {
  const [localTask, setLocalTask] = useState<Task | null>(task || null);
  
  // Ref to track if the user is editing
  const isEditingRef = useRef(false);

  useEffect(() => {
    if (task && !isEditingRef.current) {
      setLocalTask(task);
    }
  }, [task, isEditingRef]);

  // Use useRef to store the debounced function
  const debouncedUpdateTask = useRef(
    debounce(
      (id: string, changes: Partial<Task>, isEditing: boolean) => {
        updateTask({ id, ...changes }, isEditing);
      },
      500,
      { maxWait: 2000 } // Optional: Set maxWait to prevent excessive delays
    )
  ).current;

  type TaskFieldValue = string | number | boolean | null;

  const handleInputChange = (field: keyof Task, value: TaskFieldValue) => {
    isEditingRef.current = true; // User has started editing
    const updatedTask = { ...localTask!, [field]: value };
    setLocalTask(updatedTask);
    updateLocalTask(updatedTask); // Update the task in the parent component

    if (localTask?.id) {
      debouncedUpdateTask(localTask.id, { [field]: value }, isEditingRef.current);
    }
  };

  // Adjust the effect to reset isEditingRef.current after a timeout
  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500); // Adjust timeout as needed

      return () => clearTimeout(timer);
    }
  }, [localTask]);

  const titleInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [autoFocus]);

  const handleLocalUpdate = (field: keyof Task, value: unknown) => {
    if (localTask) {
      const updatedTask = { ...localTask, [field]: value };
      setLocalTask(updatedTask);
      updateLocalTask(updatedTask); // Update the task in the parent component
      updateTask({ id: localTask.id, [field]: value }, isEditingRef.current);
    }
  };

  const getCategoryIcon = (category: 'Todo' | 'In Progress' | 'Complete') => {
    switch (category) {
      case 'Todo':
        return Todo12;
      case 'In Progress':
        return InProgress12;
      case 'Complete':
        return Done12;
    }
  };

  const currentStatus = localTask ? taskStatuses.find(status => status.id === localTask.status_id) : null;
  const StatusIconComponent = currentStatus ? getCategoryIcon(currentStatus.category as 'Todo' | 'In Progress' | 'Complete') : Todo12;

  const handleDeleteTask = () => {
    if (localTask) {
      onDeleteTask(localTask.id);
    }
  };

  // Update the editor setup
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add notes here...',
      }),
    ],
    content: localTask?.notes || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      handleInputChange('notes', html);
    },
  });

  useEffect(() => {
    if (editor && !isEditingRef.current) {
      // Only set content if not editing
      if (localTask?.notes !== editor.getHTML()) {
        editor.commands.setContent(localTask?.notes || '');
      }
    }
  }, [editor, localTask?.notes]);

  if (!localTask) {
    return null; // or return a loading state
  }

  return (
    <TaskDetailsWrapper>
      <TaskTitle
        ref={titleInputRef}
        type="text"
        name="description"
        value={localTask.description || ""}
        onChange={(e) => handleInputChange('description', e.target.value)}
        placeholder="Task description"
      />
      <PropertiesList>
        <DatePicker
          selectedDate={localTask.due_date ? new Date(localTask.due_date) : null}
          onChange={(date) => handleLocalUpdate('due_date', date ? date.toISOString().split('T')[0] : null)}
          label="Due Date"
          id="task-due-date"
          onOpenChange={onPopoverOpenChange}
          icon={<Calendar12 />}
          placement="left-start"
          showClearDate={true}
        />
        <EntityPicker
          selectedId={localTask.status_id || null}
          onChange={(id) => handleLocalUpdate('status_id', id)}
          entities={taskStatuses.map((status) => ({
            id: status.id,
            name: status.name,
            color: status.color,
          }))}
          label="Status"
          allowUnassigned={false}
          icon={
            <StatusIconWrapper $color={currentStatus?.color || '#000000'}>
              <StatusIcon as={StatusIconComponent} />
            </StatusIconWrapper>
          }
          onPopoverOpenChange={onPopoverOpenChange}
        />
        <EntityPicker
          selectedId={localTask.client_id || null}
          onChange={(id) => handleLocalUpdate('client_id', id)}
          entities={clients?.map((client: Client) => ({
            id: client.id,
            name: client.full_name
          })) || []}
          label="Add a Client"
          allowUnassigned={true}
          icon={<Client12 />}
          onPopoverOpenChange={onPopoverOpenChange}
        />
        <EntityPicker
          selectedId={localTask.project_id || null}
          onChange={(id) => handleLocalUpdate('project_id', id)}
          entities={projects?.map((project: Project) => ({
            id: project.id,
            name: project.name
          })) || []}
          label="Add to Project"
          allowUnassigned={true}
          icon={<Project12 />}
          onPopoverOpenChange={onPopoverOpenChange}
        />
        <EntityPicker
          selectedId={localTask.user_id || null}
          onChange={(id) => handleLocalUpdate('user_id', id)}
          entities={organizationUsers.map((user) => ({
            id: user.id,
            name: user.full_name || user.email
          }))}
          label="Assign"
          allowUnassigned={true}
          icon={<Close12 />}
          onPopoverOpenChange={onPopoverOpenChange}
        />
        <DeleteButton onClick={handleDeleteTask}>
          <Trash12 /> Delete Task
        </DeleteButton>
      </PropertiesList>
      <EditorWrapper>
        <EditorContent editor={editor} />
      </EditorWrapper>
    </TaskDetailsWrapper>
  );
};

export default TaskDetails;