import React from "react";
import styled from "styled-components";
import InvoiceItems from "./components/Invoices/InvoiceItems";
import InvoiceTotals from "./components/Invoices/InvoiceTotals";
import { InvoiceItem, Service, InvoiceData } from "./types";

const PreviewContainer = styled.div.attrs<{
  $backgroundColor: string;
  $bodyTextColor: string;
  $font: string;
}>((props) => ({
  style: {
    backgroundColor: props.$backgroundColor,
    color: props.$bodyTextColor,
    fontFamily: `${props.$font}, sans-serif`,
  },
}))`
  padding: 80px;
`;

interface InvoicePreviewProps {
  font: string;
  headerColor: string;
  headerTextColor: string;
  backgroundColor: string;
  bodyTextColor: string;
}

const InvoicePreview: React.FC<InvoicePreviewProps> = ({
  font,
  backgroundColor,
  bodyTextColor,
}) => {
  const mockItems: InvoiceItem[] = [
    {
      id: "1",
      invoice_id: "1",
      description: "Item 1",
      quantity: 2,
      price: 100,
      taxable: true,
      order: 0,
    },
    {
      id: "2",
      invoice_id: "1",
      description: "Item 2",
      quantity: 1,
      price: 800,
      taxable: false,
      order: 1,
    },
  ];

  const mockServices: Service[] = [];

  const calculateTotals = () => {
    const subtotal = mockItems.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0
    );
    const taxRate = 0.1; // 10% tax rate
    const taxAmount = mockItems.reduce(
      (sum, item) =>
        item.taxable ? sum + item.quantity * item.price * taxRate : sum,
      0
    );
    const total = subtotal + taxAmount;
    const totalPaid = 0; // Assuming no payments made yet
    const remainingBalance = total - totalPaid;
    return { subtotal, taxAmount, total, totalPaid, remainingBalance };
  };

  const totals = calculateTotals();

  const mockInvoice: InvoiceData = {
    id: "preview-invoice",
    invoice_number: "PREVIEW-001",
    customer: "Preview Customer",
    subtotal: totals.subtotal,
    tax_rate: 10,
    amount_due: totals.total,
    invoice_date: new Date().toISOString(),
    due_date: new Date().toISOString(),
    items: mockItems,
    invoice_template: "simple",
    header_color: "#ffffff",
    header_text_color: "#000000",
    background_color: backgroundColor,
    body_text_color: bodyTextColor,
    public_id: "preview",
    payments: [],
    font: font,
    status: "draft",
    due_days: 30,
    currency: "USD",
    organization_id: 'preview-org-id',
    total: totals.total, // Added this line
    invoice_created_at: new Date().toISOString(),
  };

  // Dummy functions for InvoiceItems props
  const handleServiceSelect = () => {};
  const handleDescriptionChange = () => {};
  const updateItem = () => {};
  const deleteItem = () => {};

  return (
    <PreviewContainer
      $backgroundColor={backgroundColor}
      $bodyTextColor={bodyTextColor}
      $font={font}
    >
      <InvoiceItems
        invoice={mockInvoice}
        services={mockServices}
        handleServiceSelect={handleServiceSelect}
        handleDescriptionChange={handleDescriptionChange}
        updateItem={updateItem}
        deleteItem={deleteItem}
        onCreateNewService={() => {}}
      />
      <InvoiceTotals invoice={mockInvoice} />
    </PreviewContainer>
  );
};

export default InvoicePreview;