import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient, QueryKey } from "@tanstack/react-query";
import styled from "styled-components";
import { supabase } from "../../supabaseClient";
import { TimeEntry } from "../../types";
import Button from "../Button";
import { useClients } from "../../hooks/useClients";
import { useProjects } from "../../hooks/useProjects";
import { useServices } from "../../hooks/useServices";
import { debounce } from 'lodash';
import EntityPicker from "../EntityPicker";
import { Drawer } from 'vaul';
import { Close12, Client12, Project12 } from '../Icon';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { Toggle } from '../Toggle';
import DatePicker from "../DatePicker";

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  background-color: #f5f5f2;
  height: 100%;
  overflow-y: scroll;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 40px 16px;
  margin-bottom: 24px;
`;

const DrawerTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: rgba(0,0,0,0.8);
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 40px;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input, select {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }
  }
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    min-height: 100px;
    outline: none;
    padding: 24px 40px;
    border-top: 1px solid rgba(0,0,0,0.1);
    font-size: 16px;
    line-height: 20px;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ButtonFooter = styled.div`
  display: flex;
  position: fixed;
  margin-top: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f5f5f2;
  border-radius: 0 0 8px 8px;
  gap: 10px;
`;

const CancelButton = styled(Button)`
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const TimeInput = styled.input`
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 6px;
  border: 0;
  background-color: rgba(0,0,0,0.05);

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
  }
`;

const DateTimeWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

interface AddTimeEntryDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  timeEntry?: TimeEntry | null;
  onSave: (timeEntry: TimeEntry) => void;
  onDelete: (timeEntryId: string) => void;
  onCreate: (timeEntryData: Partial<TimeEntry>) => Promise<TimeEntry>;
  organizationId?: string;
  defaultProjectId?: string;
  defaultClientId?: string;
  onOverlayClick: (event: React.MouseEvent) => void;
  queryKey: QueryKey;
  stopTimer: () => Promise<void>;
}

const AddTimeEntryDrawer: React.FC<AddTimeEntryDrawerProps> = ({
  isOpen,
  setIsOpen,
  timeEntry,
  onSave,
  onDelete,
  organizationId,
  defaultProjectId,
  defaultClientId,
  onOverlayClick,
  queryKey,
  stopTimer,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen]);
  

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isDatePickerOpen || isAnyPopoverOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnTimeEntryItem = (clickedElement as Element).closest('.time-entry-item') !== null;
      const isClickOnPopover = (clickedElement as Element).closest('.entity-picker-popover') !== null;
      const isClickOnDatePicker = (clickedElement as Element).closest('.date-picker-element') !== null;

      if (!isClickInsideDrawer && !isClickOnTimeEntryItem && !isClickOnPopover && !isClickOnDatePicker) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isDatePickerOpen, isAnyPopoverOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen]);

  const queryClient = useQueryClient();
  const [formData, setFormData] = useState<Omit<TimeEntry, "id" | "user_id">>({
    organization_id: organizationId || "",
    client_id: defaultClientId || null,
    project_id: defaultProjectId || null,
    service_id: null,
    start_time: new Date().toISOString(),
    end_time: null,
    duration: 0,
    description: "",
    is_billable: true,
  });

  const { data: clients } = useClients();
  const { data: projects } = useProjects();
  const { data: services } = useServices();


  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add time entry description...',
      }),
    ],
    content: formData.description || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setFormData(prev => ({ ...prev, description: html }));
    },
  });

  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserId = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUserId(user?.id || null);
    };
    fetchUserId();
  }, []);

  useEffect(() => {
    if (isOpen) {
      if (timeEntry) {
        setFormData({
          organization_id: timeEntry.organization_id,
          client_id: timeEntry.client_id || defaultClientId || null,
          project_id: timeEntry.project_id || defaultProjectId || null,
          service_id: timeEntry.service_id || null,
          start_time: timeEntry.start_time,
          end_time: timeEntry.end_time,
          duration: timeEntry.duration,
          description: timeEntry.description || "",
          is_billable: timeEntry.is_billable || true,
        });
      } else {
        // Reset form data for new time entry
        setFormData({
          organization_id: organizationId || "",
          client_id: defaultClientId || null,
          project_id: defaultProjectId || null,
          service_id: null,
          start_time: new Date().toISOString(),
          end_time: null,
          duration: 0,
          description: "",
          is_billable: true,
        });
      }
      // Reset the editor content
      if (editor) {
        editor.commands.setContent("");
      }
    }
  }, [isOpen, timeEntry, defaultProjectId, defaultClientId, organizationId, editor]);

  const updateTimeEntryMutation = useMutation({
    mutationFn: async (updatedTimeEntry: Partial<TimeEntry>) => {
      if (!updatedTimeEntry.id) throw new Error("No time entry ID provided");
      const { data, error } = await supabase
        .from("time_entries")
        .update(updatedTimeEntry)
        .eq("id", updatedTimeEntry.id)
        .select()
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["timeEntries", data.id], data);
      queryClient.invalidateQueries({ queryKey: ["timeEntries"] });
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const createTimeEntryMutation = useMutation({
    mutationFn: async (newTimeEntry: Partial<TimeEntry>) => {
      const { data, error } = await supabase
        .from("time_entries")
        .insert(newTimeEntry)
        .select()
        .single();
      if (error) throw error;
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["timeEntries", data.id], data);
      queryClient.invalidateQueries({ queryKey: ["timeEntries"] });
      queryClient.invalidateQueries({ queryKey });
      onSave(data);
    },
  });

  const debouncedUpdateTimeEntry = useCallback(
    debounce((updatedData: Partial<TimeEntry>) => {
      console.log('Sending update request with data:', updatedData);
      if (timeEntry?.id) {
        updateTimeEntryMutation.mutate({ ...updatedData, id: timeEntry.id }, {
          onError: (error) => {
            console.error('Error updating time entry:', error);
            // Optionally, you can add user-facing error handling here
          }
        });
      }
    }, 500),
    [updateTimeEntryMutation, timeEntry]
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    let updatedData = { ...formData, [name]: value };

    // If duration is changed, update the end time
    if (name === 'duration') {
      const startTime = new Date(formData.start_time);
      const endTime = new Date(startTime.getTime() + parseInt(value) * 1000);
      updatedData = { ...updatedData, end_time: endTime.toISOString() };
    }

    setFormData(updatedData);
    if (timeEntry?.id) {
      debouncedUpdateTimeEntry({ ...updatedData, id: timeEntry.id });
    }
  };

  const handleEntityChange = (field: keyof TimeEntry, value: string | null) => {
    console.log(`Updating ${field} with value:`, value);
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    if (timeEntry?.id) {
      debouncedUpdateTimeEntry({ ...updatedData, id: timeEntry.id });
    }
  };

  const handleBillableToggle = (newIsBillable: boolean) => {
    const updatedData = { ...formData, is_billable: newIsBillable };
    setFormData(updatedData);
    if (timeEntry?.id) {
      debouncedUpdateTimeEntry({ ...updatedData, id: timeEntry.id });
    }
  };

  const handleDelete = () => {
    if (
      timeEntry?.id &&
      window.confirm("Are you sure you want to delete this time entry?")
    ) {
      onDelete(timeEntry.id);
      setIsOpen(false);
    }
  };

  const handleSave = async () => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }

    await stopTimer(); // Ensure the timer is stopped

    const timeEntryData = {
      organization_id: formData.organization_id,
      user_id: userId, // Include the user_id here
      client_id: formData.client_id,
      project_id: formData.project_id,
      service_id: formData.service_id,
      start_time: formData.start_time,
      end_time: formData.end_time,
      duration: formData.duration,
      description: formData.description,
      is_billable: formData.is_billable,
    };

    if (timeEntry?.id) {
      // Update existing time entry
      await updateTimeEntryMutation.mutateAsync({ id: timeEntry.id, ...timeEntryData });
    } else {
      // Create new time entry
      await createTimeEntryMutation.mutateAsync(timeEntryData);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (editor && formData.description !== editor.getHTML()) {
      editor.commands.setContent(formData.description || '');
    }
  }, [editor, formData.description]);

  const handleDateChange = (field: 'start_time' | 'end_time') => (date: Date | null) => {
    if (date) {
      const currentTime = new Date(formData[field] || new Date());
      const newDateTime = new Date(date);
      newDateTime.setHours(currentTime.getHours(), currentTime.getMinutes());
      
      let updatedData = { ...formData, [field]: newDateTime.toISOString() };

      // If start_time is changed, update the end_time based on duration
      if (field === 'start_time') {
        const endTime = new Date(newDateTime.getTime() + formData.duration * 1000);
        updatedData = { ...updatedData, end_time: endTime.toISOString() };
      }

      // If end_time is changed, update the duration
      if (field === 'end_time') {
        const startTime = new Date(formData.start_time);
        const duration = Math.floor((newDateTime.getTime() - startTime.getTime()) / 1000);
        updatedData = { ...updatedData, duration };
      }

      setFormData(updatedData);
      if (timeEntry?.id) {
        debouncedUpdateTimeEntry({ ...updatedData, id: timeEntry.id });
      }
    }
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const [hours, minutes] = value.split(':').map(Number);
    
    const currentDate = new Date(formData[name as 'start_time' | 'end_time'] || new Date());
    currentDate.setHours(hours, minutes);

    let updatedData = { ...formData, [name]: currentDate.toISOString() };

    // If start_time is changed, update the end_time based on duration
    if (name === 'start_time') {
      const endTime = new Date(currentDate.getTime() + formData.duration * 1000);
      updatedData = { ...updatedData, end_time: endTime.toISOString() };
    }

    // If end_time is changed, update the duration
    if (name === 'end_time') {
      const startTime = new Date(formData.start_time);
      const duration = Math.floor((currentDate.getTime() - startTime.getTime()) / 1000);
      updatedData = { ...updatedData, duration };
    }

    setFormData(updatedData);
    if (timeEntry?.id) {
      debouncedUpdateTimeEntry({ ...updatedData, id: timeEntry.id });
    }
  };

  const formatTime = (dateString: string | null) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction="right"
      modal={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={onOverlayClick} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DrawerHeader>
              <DrawerTitle>{timeEntry ? "Edit Time Entry" : "New Time Entry"}</DrawerTitle>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            </DrawerHeader>
            <InputFieldsContainer>
              <InputField>
                <label htmlFor="start_time">Start</label>
                <DateTimeWrapper>
                  <DatePicker
                    selectedDate={formData.start_time ? new Date(formData.start_time) : null}
                    onChange={handleDateChange('start_time')}
                    label="Start Date"
                    id="start_date"
                    variant="input"
                    onOpenChange={(isOpen) => {
                      setIsDatePickerOpen(isOpen);
                      setIsAnyPopoverOpen(isOpen);
                    }}
                  />
                  <TimeInput
                    type="time"
                    id="start_time"
                    name="start_time"
                    value={formatTime(formData.start_time)}
                    onChange={handleTimeChange}
                    required
                  />
                </DateTimeWrapper>
              </InputField>
              <InputField>
                <label htmlFor="end_time">End</label>
                <DateTimeWrapper>
                  <DatePicker
                    selectedDate={formData.end_time ? new Date(formData.end_time) : null}
                    onChange={handleDateChange('end_time')}
                    label="End Date"
                    id="end_date"
                    variant="input"
                    onOpenChange={(isOpen) => {
                      setIsDatePickerOpen(isOpen);
                      setIsAnyPopoverOpen(isOpen);
                    }}
                  />
                  <TimeInput
                    type="time"
                    id="end_time"
                    name="end_time"
                    value={formatTime(formData.end_time)}
                    onChange={handleTimeChange}
                  />
                </DateTimeWrapper>
              </InputField>
              <InputField>
                <label htmlFor="duration">Duration (seconds)</label>
                <input
                  id="duration"
                  type="number"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  placeholder="Duration (in seconds)"
                  required
                />
              </InputField>
              <EntityPicker
                selectedId={formData.client_id}
                onChange={(id) => handleEntityChange("client_id", id)}
                entities={clients?.map(client => ({ id: client.id, name: client.full_name })) || []}
                label="Client"
                allowUnassigned
                icon={<Client12 />}
                onPopoverOpenChange={setIsAnyPopoverOpen}
              />
              <EntityPicker
                selectedId={formData.project_id}
                onChange={(id) => handleEntityChange("project_id", id)}
                entities={projects?.map(project => ({ id: project.id, name: project.name })) || []}
                label="Project"
                allowUnassigned
                icon={<Project12 />}
                onPopoverOpenChange={setIsAnyPopoverOpen}
              />
              <EntityPicker
                selectedId={formData.service_id}
                onChange={(id) => handleEntityChange("service_id", id)}
                entities={services?.map(service => ({ id: service.id, name: service.name })) || []}  
                label="Service"
                allowUnassigned
                icon={<Close12 />}
                onPopoverOpenChange={setIsAnyPopoverOpen}
              />
              <InputField>
                <label>Billable</label>
                <ToggleWrapper>
                  <Toggle
                    checked={formData.is_billable}
                    onChange={handleBillableToggle}
                  />
                  {formData.is_billable ? "Billable" : "Non-billable"}
                </ToggleWrapper>
              </InputField>
            </InputFieldsContainer>
            <EditorWrapper>
              <EditorContent editor={editor} />
            </EditorWrapper>
            <ButtonFooter>
              <Button onClick={handleSave}>
                {timeEntry ? "Save Changes" : "Create Time Entry"}
              </Button>
              {timeEntry && (
                <Button onClick={handleDelete} style={{ backgroundColor: "red" }}>
                  Delete Time Entry
                </Button>
              )}
              <CancelButton onClick={handleClose}>Cancel</CancelButton>
            </ButtonFooter>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default AddTimeEntryDrawer;