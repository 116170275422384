import React from 'react';
import styled from 'styled-components';
import { TimeTrackerStyle, Client, Project, Service } from '../../types';
import DefaultTimeTracker from './TimeTrackerLayouts/DefaultTimeTracker';
import ModernTimeTracker from './TimeTrackerLayouts/ModernTimeTracker';
import MinimalTimeTracker from './TimeTrackerLayouts/MinimalTimeTracker';
import { Close12 } from '../Icon';
import { Editor } from '@tiptap/react';
import ReactDOM from 'react-dom';

const FullscreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99999; // Maximum z-index value
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  width: 80%;
  max-width: 800px;
`;

interface FullscreenTimeTrackerProps {
  onClose: () => void;
  timeTrackerStyle: TimeTrackerStyle;
  selectedClientId: string | null;
  selectedProjectId: string | null;
  selectedServiceId: string | null;
  handleClientChange: (clientId: string | null) => void;
  handleProjectChange: (projectId: string | null) => void;
  handleServiceChange: (serviceId: string | null) => void;
  clients: Client[];
  projects: Project[];
  services: Service[];
  manualTime: string;
  handleManualTimeChange: (field: 'hours' | 'minutes' | 'seconds', value: string) => void;
  editor: Editor | null;
  isBillable: boolean;
  handleBillableToggle: (newIsBillable: boolean) => void;
  isRunning: boolean;
  isPaused: boolean;
  handleStart: () => void;
  handlePause: () => void;
  handleResume: () => void;
  handleLogTime: () => void;
  handleDelete: () => void;
  renderPickerWrapper: () => React.ReactNode;
  elapsedTime: number;
}

const FullscreenTimeTracker: React.FC<FullscreenTimeTrackerProps> = ({
  onClose,
  timeTrackerStyle,
  selectedClientId,
  selectedProjectId,
  selectedServiceId,
  handleClientChange,
  handleProjectChange,
  handleServiceChange,
  clients,
  projects,
  services,
  manualTime,
  handleManualTimeChange,
  editor,
  isBillable,
  handleBillableToggle,
  isRunning,
  isPaused,
  handleStart,
  handlePause,
  handleResume,
  handleLogTime,
  handleDelete,
  renderPickerWrapper,
  elapsedTime,
}) => {
  const renderTimeTracker = () => {
    const commonProps = {
      selectedClientId,
      selectedProjectId,
      selectedServiceId,
      handleClientChange,
      handleProjectChange,
      handleServiceChange,
      clients,
      projects,
      services,
      manualTime,
      handleManualTimeChange,
      editor,
      isBillable,
      handleBillableToggle,
      isRunning,
      isPaused,
      handleStart,
      handlePause,
      handleResume,
      handleLogTime,
      handleDelete,
      renderPickerWrapper,
      elapsedTime,
    };

    switch (timeTrackerStyle) {
      case 'modern':
        return <ModernTimeTracker {...commonProps} />;
      case 'minimal':
        return <MinimalTimeTracker {...commonProps} />;
      default:
        return <DefaultTimeTracker {...commonProps} />;
    }
  };

  const fullscreenContent = (
    <FullscreenWrapper>
      <CloseButton onClick={onClose}>
        <Close12 />
      </CloseButton>
      <ContentWrapper>
        {renderTimeTracker()}
      </ContentWrapper>
    </FullscreenWrapper>
  );

  return ReactDOM.createPortal(
    fullscreenContent,
    document.body
  );
};

export default FullscreenTimeTracker;