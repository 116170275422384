import React from "react";
import { Drawer } from "vaul";
import "./RightDrawer.css"; // Import the CSS file

interface RightDrawerComponentProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  title: string;
  description?: string;
}

const RightDrawerComponent: React.FC<RightDrawerComponentProps> = ({
  isOpen,
  setIsOpen,
  children,
  title,
  description,
}) => {
  return (
    <Drawer.Root open={isOpen} onOpenChange={setIsOpen} direction="right">
      <Drawer.Portal>
        <Drawer.Overlay className="drawer-overlay" />
        <Drawer.Content className="drawer-content">
          <div className="drawer-inner-content">
            <Drawer.Title asChild>
              <h2>{title}</h2>
            </Drawer.Title>
            {description && (
              <Drawer.Description asChild>
                <p>{description}</p>
              </Drawer.Description>
            )}
            {children}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default RightDrawerComponent;
