export function formatDuration(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = '';




  if (hours > 0) {
    result += `${hours} ${hours === 1 ? 'Hr' : 'Hrs'}`;
    if (minutes > 0) result += ' ';
  }

  if (minutes > 0 || hours > 0) {
    result += `${minutes} ${minutes === 1 ? 'Min' : 'Mins'}`;
  }

  // Only show seconds if total duration is less than 1 hour
  if (hours === 0 && (remainingSeconds > 0 || (hours === 0 && minutes === 0))) {
    if (minutes > 0) result += ' ';
    result += `${remainingSeconds} ${remainingSeconds === 1 ? 'Sec' : 'Secs'}`;
  }

  return result.trim();
}