import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearch } from "@tanstack/react-router";

interface SearchParams {
  email?: string;
  organizationId?: string;
  action?: string;
}

const Auth: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const search = useSearch({ from: '__root__' }) as SearchParams;

  useEffect(() => {
    // Check if there's an invitation in the URL
    if (search.email && search.organizationId && search.action === 'accept-invite') {
      localStorage.setItem('pendingInvitation', JSON.stringify({ 
        email: search.email, 
        organizationId: search.organizationId 
      }));
      setEmail(search.email);
    }
  }, [search]);

  const handleSignInSuccess = async () => {
    queryClient.clear(); // Clear cache on successful sign-in
    
    const { data: { user }, error } = await supabase.auth.getUser();
    
    if (error) {
      console.error('Error fetching user data:', error);
      return;
    }

    const pendingInvitation = localStorage.getItem('pendingInvitation');
    if (pendingInvitation) {
      const { email, organizationId } = JSON.parse(pendingInvitation);
      navigate({ 
        to: '/accept-invite', 
        search: { email, organizationId } 
      });
      localStorage.removeItem('pendingInvitation');
    } else if (user?.user_metadata?.current_organization_id) {
      navigate({ to: '/' });
    } else {
      navigate({ to: '/create-organization' });
    }
  };

  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      alert(error.message);
    } else {
      await handleSignInSuccess();
    }
    setLoading(false);
  };

  const handleEmailSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { data, error } = await supabase.auth.signUp({ 
      email, 
      password,
      options: {
        emailRedirectTo: `${window.location.origin}/auth/callback`
      }
    });
    if (error) {
      alert(error.message);
    } else if (data.user && data.user.identities && data.user.identities.length === 0) {
      alert('An account with this email already exists. Please sign in instead.');
    } else {
      await handleSignUpSuccess();
    }
    setLoading(false);
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/auth/callback`
      }
    });
    if (error) {
      alert(error.message);
    }
    setLoading(false);
  };

  const handleSignUpSuccess = async () => {
    queryClient.clear(); // Clear cache on successful sign-up
    
    const pendingInvitation = localStorage.getItem('pendingInvitation');
    if (pendingInvitation) {
      const { email } = JSON.parse(pendingInvitation);
      // Fetch and cache the pending invitation
      await queryClient.prefetchQuery({
        queryKey: ['pendingInvitations', email],
        queryFn: async () => {
          const { data, error } = await supabase
            .from('pending_invitations')
            .select(`
              id,
              organization_id,
              organizations (name),
              email
            `)
            .eq('email', email);
          if (error) throw error;
          return data;
        },
      });
      navigate({ to: '/pending-invitations' });
      localStorage.removeItem('pendingInvitation');
    } else {
      alert('Check your email for the confirmation link!');
      navigate({ to: '/create-organization' });
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={handleEmailSignIn}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          autoComplete="email"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          autoComplete="current-password"
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Sign In'}
        </button>
      </form>
      <button onClick={handleEmailSignUp} disabled={loading}>
        Sign Up
      </button>
      <button onClick={handleGoogleSignIn} disabled={loading}>
        Sign In with Google
      </button>
    </div>
  );
};

export default Auth;