import { InvoiceData } from '../types';

export function calculateInvoiceTotals(invoice: InvoiceData) {
  let subtotal = 0;
  let taxableAmount = 0;

  for (const item of invoice.items) {
    const itemTotal = item.quantity * item.price;
    subtotal += itemTotal;
    if (item.taxable) {
      taxableAmount += itemTotal;
    }
  }

  const taxAmount = (taxableAmount * invoice.tax_rate) / 100;
  const total = subtotal + taxAmount;
  const totalPaid = invoice.payments?.reduce((sum, payment) => sum + payment.amount, 0) || 0;
  const amountDue = Math.max(0, total - totalPaid);

  return { subtotal, taxAmount, total, totalPaid, amountDue };
}

export function recalculateInvoice(invoice: InvoiceData): InvoiceData {
  const totals = calculateInvoiceTotals(invoice);
  return {
    ...invoice,
    subtotal: totals.subtotal,
    total: totals.total,
    amount_due: totals.amountDue,
  };
}