import React from 'react';
import styled from 'styled-components';

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
}

const SwitchContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SwitchInput = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;
`;

const SwitchSlider = styled.span<{ checked: boolean }>`
  display: flex;
  align-items: center;
  width: 32px;
  height: 16px;
  background: ${props => props.checked ? '#4CAF50' : '#ccc'};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: ${props => props.checked ? 'calc(100% - 2px)' : '2px'};
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    transition: 0.2s;
    transform: ${props => props.checked ? 'translateX(-100%)' : 'translateX(0)'};
  }
`;

const SwitchLabel = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
`;

const Switch: React.FC<SwitchProps> = ({ checked, onChange, label }) => {
  return (
    <SwitchContainer>
      <SwitchInput
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <SwitchSlider checked={checked} />
      {label && <SwitchLabel>{label}</SwitchLabel>}
    </SwitchContainer>
  );
};

export default Switch;