import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Invoice } from '../types';
import { useOrganization } from './useOrganization';

export const useInvoices = () => {
  const { data: organizationId } = useOrganization();

  return useQuery<Invoice[], Error>({
    queryKey: ['invoices', organizationId],
    queryFn: async () => {
      if (!organizationId) return [];
      const { data, error } = await supabase
        .from('invoices')
        .select('*')
        .eq('organization_id', organizationId);
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });
};