import { useEffect, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Task } from '../types';
import { useOrganization } from './useOrganization';

export const useFetchTasks = (projectId?: string) => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const localUpdatesRef = useRef<Map<string, boolean>>(new Map());

  const fetchTasks = async (): Promise<Task[]> => {
    if (!organizationId) throw new Error('Organization ID is required');
    let query = supabase
      .from('tasks')
      .select('*')
      .eq('organization_id', organizationId);
    
    if (projectId) {
      query = query.eq('project_id', projectId);
    }
    
    const { data, error } = await query;
    if (error) throw error;
    return data;
  };

  const query = useQuery<Task[], Error>({
    queryKey: ['tasks', organizationId, projectId],
    queryFn: fetchTasks,
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (!organizationId) return;

    const handleTaskUpdate = (event: CustomEvent) => {
      const payload = event.detail;
      queryClient.setQueryData(['tasks', organizationId, projectId], (oldData: Task[] | undefined) => {
        if (!oldData) return oldData;
        
        if (payload.eventType === 'INSERT') {
          return sortTasks([...oldData, payload.new as Task]);
        }
        if (payload.eventType === 'UPDATE') {
          return sortTasks(oldData.map(task => task.id === payload.new.id ? payload.new as Task : task));
        }
        if (payload.eventType === 'DELETE') {
          return sortTasks(oldData.filter(task => task.id !== payload.old.id));
        }
        
        return oldData;
      });
    };

    window.addEventListener('TASK_UPDATED', handleTaskUpdate as EventListener);

    return () => {
      window.removeEventListener('TASK_UPDATED', handleTaskUpdate as EventListener);
    };
  }, [organizationId, projectId, queryClient]);

  return {
    ...query,
    localUpdatesRef,
  };
};

const sortTasks = (tasks: Task[]): Task[] => {
  return [...tasks].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
};