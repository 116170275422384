import React from "react";
import styled from "styled-components";
import { Service } from '../../types';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";

const PopoverContainer = styled.div``;

const PopoverContent = styled.div<{ $transformOrigin: string }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: ${(props) => props.$transformOrigin};
  z-index: 1000;
`;

const Input = styled.input`
  width: 100%;
  border: 0;
  outline: none;
  font-size: 16px;
  padding: 0;
  background: transparent;
  font-family: inherit;
  color: inherit;
  border-radius: 0;
  box-sizing: border-box;
`;

const ServiceList = styled.ul<{ $transformOrigin: string }>`
  position: absolute;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  border-radius: 12px;
  margin: 0;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;
  padding: 4px;
  -webkit-backdrop-filter: blur(8px);
  font-size: 14px;
`;

const ServiceListItem = styled.li`
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

interface ServiceSelectorProps {
  services: Service[];
  onSelect: (service: Service) => void;
  onInputChange: (value: string) => void;
  initialValue: string;
  disabled?: boolean;
  onCreateNewService: () => void;
}

export const ServiceSelector: React.FC<ServiceSelectorProps> = ({
  services,
  onSelect,
  onInputChange,
  initialValue,
  disabled,
  onCreateNewService,
}) => {
  const [inputValue, setInputValue] = React.useState(initialValue);
  const [isOpen, setIsOpen] = React.useState(false);
  const [filteredServices, setFilteredServices] = React.useState<Service[]>(services);
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
      case "top-start":
      case "top-end":
        return "bottom start";
      case "bottom":
      case "bottom-start":
      case "bottom-end":
        return "top start";
      case "left":
      case "left-start":
      case "left-end":
        return "right start";
      case "right":
      case "right-start":
      case "right-end":
        return "left start";
      default:
        return "top left";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const click = useClick(context, {
    // Disable the click event on the reference element (input)
    enabled: false,
  });  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onInputChange(newValue);
    
    if (newValue === '') {
      setFilteredServices(services);
      setIsOpen(true);
    } else {
      const filtered = services.filter(service =>
        service.name.toLowerCase().includes(newValue.toLowerCase())
      );
      setFilteredServices(filtered);
      setIsOpen(false);
    }
  };

  const handleSelectService = (service: Service) => {
    setInputValue(service.name);
    onSelect(service);
    setIsOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isOpen && filteredServices.length > 0) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setActiveIndex((prev) => (prev === null || prev === filteredServices.length - 1) ? 0 : prev + 1);
          break;
        case 'ArrowUp':
          e.preventDefault();
          setActiveIndex((prev) => (prev === null || prev === 0) ? filteredServices.length - 1 : prev - 1);
          break;
        case 'Enter':
          e.preventDefault();
          if (activeIndex !== null) {
            handleSelectService(filteredServices[activeIndex]);
          }
          break;
      }
    }
  };

  return (
    <>
      <Input
        ref={refs.setReference}
        {...getReferenceProps()}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={() => {
          if (inputValue === '') {
            setIsOpen(true);
          }
        }}
        placeholder="Type to search or edit description..."
        disabled={disabled}
      />
      <FloatingPortal>
        {isMounted && isOpen && inputValue === '' && (
          <PopoverContainer
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
             <PopoverContent style={styles} $transformOrigin={transformOrigin}>
              <ServiceList $transformOrigin={transformOrigin}>
                {services.map((service, index) => (
                  <ServiceListItem
                    key={service.id}
                    onClick={() => handleSelectService(service)}
                    style={index === activeIndex ? { backgroundColor: 'rgba(0, 0, 0, 0.1)' } : {}}
                  >
                    {service.name} - ${service.price.toFixed(2)}
                  </ServiceListItem>
                ))}
                <ServiceListItem
                  key="create-new"
                  onClick={onCreateNewService}
                  style={{ fontStyle: 'italic' }}
                >
                  Create new service...
                </ServiceListItem>
              </ServiceList>
            </PopoverContent>
          </PopoverContainer>
        )}
      </FloatingPortal>
    </>
  );
};

export default ServiceSelector;