import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  padding: 20px 24px 16px 24px;
  min-height: 128px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s ease, transform 0.1s ease;
  background-color: rgba(0,0,0,0.05);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 4px;
  user-select: none;
  color: rgba(0,0,0,0.8);

  &:hover {
    background-color: rgba(0,0,0,0.1);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const CardItem = styled.div`
  font-size: 14px;
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-left: -8px;
`;

interface CardProps {
  onClick?: () => void;
  onMouseEnter?: () => void;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ onClick, onMouseEnter, children }) => (
  <CardWrapper onClick={onClick} onMouseEnter={onMouseEnter}>
    {children}
  </CardWrapper>
);

export { Card, CardContent, CardTitle, CardItem, CardFooter };