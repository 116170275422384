import React, { useState, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { invoiceTemplates } from "./templates/InvoiceTemplates";
import SidebarRight from "./components/SidebarRight";
import PaymentsDrawer from "./components/PaymentsDrawer";
import NewClientDrawer from "./components/Clients/AddClientDrawer";
import { Client, InvoiceData, Payment } from "./types";
import type { InvoiceItem, Service } from "./types";
import { useQueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { useClients } from "./hooks/useInvoiceHooks";
import { useInvoiceMutations } from "./hooks/useInvoiceMutations";
import { useServices } from "./hooks/useServices";
import PaymentsTable from "./components/PaymentsTable";
import InvoiceTotals from "./components/Invoices/InvoiceTotals";
import InvoiceItems from "./components/Invoices/InvoiceItems";
import { supabase } from "./supabaseClient";
import { useNavigate, useParams } from '@tanstack/react-router';
import axios from "axios";
import NotesEditor from "./components/NotesEditor";
import InvoiceMoreMenu from "./components/Invoices/InvoiceMoreMenu";
import { RightSidebar16 } from "./components/Icon";
import AnimatedHeader from "./components/AnimatedHeader";
import Button from "./components/Button";
import { useProjects } from "./hooks/useProjects";
import { useOrganization } from "./hooks/useOrganization";
import { invoiceRoute } from './router';
import { useInvoice } from "./hooks/useInvoiceHooks";
import SendInvoiceDrawer from './components/Invoices/SendInvoiceDrawer';
import { ClientSelector } from "./components/Clients/ClientSelector";
import AddServiceDrawer from './components/Services/AddServiceDrawer';
import InvoicePageSkeleton from './components/Invoices/InvoicePageSkeleton';
import { isAfter } from 'date-fns';
import { StatusBadge } from './components/StatusBadge';
import { defaultSettings } from './utils/invoiceUtils';
import { usePageContext } from './hooks/usePageContext';
import InvoiceHistoryDrawer from './components/Invoices/InvoiceHistoryDrawer';
import { useEmailTrackingData } from './hooks/useEmailTrackingData';
import { useBrandSettings } from './hooks/useBrandSettings';

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const MainContent = styled.div<MainContentProps>`
  flex: 1;
  max-width: 960px;
  width: 100%;
  transition: max-width 0.3s ease-in-out;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$bodyTextColor};
  font-family: ${(props) => props.$font};
  box-shadow: 
  0 8px 8px rgba(0, 0, 0, 0.05),
  0 24px 32px rgba(0, 0, 0, 0.05),
  0 40px 64px rgba(0, 0, 0, 0.05),
  0 64px 80px rgba(0, 0, 0, 0.05);
`;

const ToggleButton = styled.button`
  width: 28px;
  height: 28px;
  z-index: 1;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InvoiceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
`;

const InvoiceDetailsContainer = styled.div`
  padding: 64px 64px 80px;
`;

const InvoiceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 24px;
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const PaymentsContent = styled.div`
  max-width: 960px;
  width: 100%;
  padding-bottom: 80px;
`;

const AddItemRow = styled.div`
  padding: 12px;
`;

const NavInvoiceTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
`;

const PaymentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 48px;

`;

const PaymentsHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

interface MainContentProps {
  $isSidebarVisible: boolean;
  $backgroundColor: string;
  $bodyTextColor: string;
  $font: string; // Add this line
}

const InvoicePage: React.FC = () => {
  const { id } = useParams({ from: invoiceRoute.id });
  const queryClient = useQueryClient();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewClientDrawerOpen, setIsNewClientDrawerOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isAddServiceDrawerOpen, setIsAddServiceDrawerOpen] = useState(false);
  const [isSendDrawerOpen, setIsSendDrawerOpen] = useState(false);
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);
  const { data: organizationId } = useOrganization();
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [localInvoice, setLocalInvoice] = useState<InvoiceData | null>(() => {
    const cachedData = queryClient.getQueryData(['invoice', id, organizationId]);
    return cachedData as InvoiceData | null;
  });
  const [localChanges] = useState<Partial<InvoiceData>>({});
  const [deletingItems, setDeletingItems] = useState<Set<string>>(new Set());
  const { setPageHeaderProps } = usePageContext();

  const { data: services } = useServices();
  const navigate = useNavigate();

  const { data: brandSettings } = useBrandSettings();

  const { data: clients } = useClients();
  const { data: projects } = useProjects();
  const {
    data: serverInvoice,
    isLoading: isLoadingInvoice,
    isError: isErrorInvoice,
    error: invoiceError,
  } = useInvoice(id);

  useEffect(() => {
    if (serverInvoice) {
      setLocalInvoice(prev => {
        if (!prev) return serverInvoice;
        return {
          ...prev,
          ...serverInvoice,
          items: serverInvoice.items || prev.items,
        };
      });
    }
  }, [serverInvoice]);

  const {
    updateInvoiceMutation,
    updateInvoiceItemsMutation,
    addInvoiceItemMutation,
    deleteInvoiceItemMutation,
    addPaymentMutation,
    removePaymentMutation,
    deleteInvoiceMutation,
    shareInvoiceMutation,
    reorderInvoiceItemsMutation,
    updateInvoiceItemDescriptionMutation,
  } = useInvoiceMutations(id);

  const updateInvoice = useCallback(
    (field: keyof InvoiceData, value: InvoiceData[keyof InvoiceData]) => {
      setLocalInvoice((prev) => {
        if (!prev) return prev;
        return { ...prev, [field]: value };
      });
      if (id) {
        updateInvoiceMutation.mutate({ [field]: value }, {
          onSuccess: (updatedInvoice) => {
            setLocalInvoice((prev) => {
              if (!prev) return updatedInvoice;
              return {
                ...prev,
                ...updatedInvoice,
                // Preserve 'draft' status if it was already set
                status: prev.status === 'draft' ? 'draft' : updatedInvoice.status
              };
            });
          }
        });
      }
    },
    [updateInvoiceMutation, id]
  );

  const updateItem = useCallback(
    (itemId: string, field: keyof InvoiceItem, value: string | number | boolean) => {
      const sanitizedValue = (field === 'price' || field === 'quantity') && value === '' ? null : value;

      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedItems = prev.items.map(item =>
          item.id === itemId ? { ...item, [field]: sanitizedValue } : item
        );
        return { ...prev, items: updatedItems };
      });

      if (sanitizedValue !== null && /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(itemId)) {
        updateInvoiceItemsMutation.mutate([{ id: itemId, [field]: sanitizedValue }]);
      }
    },
    [updateInvoiceItemsMutation]
  );

  const addItem = useCallback(
    (serviceId?: string) => {
      if (!id) return;

      const newItem: Omit<InvoiceItem, "id"> = {
        invoice_id: id,
        description: "",
        quantity: 1,
        price: 0,
        taxable: false,
        order: localInvoice?.items?.length ?? 0,
      };

      if (serviceId) {
        const selectedService = services?.find(service => service.id === serviceId);
        if (selectedService) {
          newItem.description = selectedService.name;
          newItem.price = selectedService.price;
          newItem.taxable = selectedService.taxable;
        }
      }

      const tempId = `temp-${Date.now()}`;
      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedItems = [...(prev.items || []), { ...newItem, id: tempId, order: prev.items.length }];
        return { ...prev, items: updatedItems };
      });

      addInvoiceItemMutation.mutate(newItem, {
        onSuccess: ({ newItem: addedItem, updatedInvoice }) => {
          setLocalInvoice((prev) => {
            if (!prev) return updatedInvoice;
            const updatedItems = prev.items.map(item => 
              item.id === tempId ? { ...addedItem, order: prev.items.length - 1 } : item
            );
            return {
              ...prev,
              ...updatedInvoice,
              items: updatedItems,
              // Preserve 'draft' status if it was already set
              status: prev.status === 'draft' ? 'draft' : updatedInvoice.status
            };
          });
        },
        onError: () => {
          setLocalInvoice((prev) => {
            if (!prev) return prev;
            const updatedItems = prev.items.filter(item => item.id !== tempId);
            return { ...prev, items: updatedItems };
          });
        }
      });
    },
    [id, localInvoice, services, addInvoiceItemMutation]
  );

  const deleteItem = useCallback(async (itemId: string) => {
    if (!localInvoice || !id) return;

    setDeletingItems((prev) => new Set(prev).add(itemId));

    setLocalInvoice((prev) => {
      if (!prev) return prev;
      const updatedItems = (prev.items || []).filter((item) => item.id !== itemId);
      const reorderedItems = updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));
      return { ...prev, items: reorderedItems };
    });

    deleteInvoiceItemMutation.mutate(itemId, {
      onSuccess: ({ updatedInvoice }) => {
        setDeletingItems((prev) => {
          const newSet = new Set(prev);
          newSet.delete(itemId);
          return newSet;
        });
        setLocalInvoice((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            ...updatedInvoice,
            items: (prev.items || [])
              .filter((item) => item.id !== itemId)
              .map((item, index) => ({ ...item, order: index })),
          };
        });
      },
      onError: (error) => {
        console.error("Error deleting item:", error);
        setDeletingItems((prev) => {
          const newSet = new Set(prev);
          newSet.delete(itemId);
          return newSet;
        });
        setLocalInvoice((prev) => {
          if (!prev) return prev;
          return queryClient.getQueryData<InvoiceData>(['invoice', id]) || prev;
        });
      },
    });
  }, [deleteInvoiceItemMutation, queryClient, id]);

  const handleServiceSelect = (service: Service, itemId: string) => {
    updateItem(itemId, "description", service.name || "");
    updateItem(itemId, "price", service.price);
    updateItem(itemId, "taxable", service.taxable);
  };

  const handleSelectProject = useCallback(
    (projectId: string | null) => {
      setSelectedProjectId(projectId);
      updateInvoice("project_id", projectId);
    },
    [updateInvoice]
  );

  useEffect(() => {
    if (localInvoice && localInvoice.project_id) {
      setSelectedProjectId(localInvoice.project_id);
    }
  }, [localInvoice]);

  const handleDescriptionChange = (value: string, itemId: string) => {
    setLocalInvoice((prev) => {
      if (!prev) return prev;
      const updatedItems = prev.items.map(item =>
        item.id === itemId ? { ...item, description: value } : item
      );
      return { ...prev, items: updatedItems };
    });
    updateInvoiceItemDescriptionMutation.mutate({ itemId, description: value });
  };

  const handleShareInvoice = useCallback(async (): Promise<string> => {
    const result = await shareInvoiceMutation.mutateAsync();
    if (result && result.public_id) {
      const link = `${window.location.origin}/share/${result.public_id}`;
      return link;
    }
    throw new Error("Failed to generate shareable link");
  }, [shareInvoiceMutation]);

  const addPayment = useCallback(
    (payment: Omit<Payment, "id">) => {
      if (!organizationId) {
        console.error("Organization ID is missing");
        return;
      }
      addPaymentMutation.mutate(
        { ...payment, organization_id: organizationId },
        {
          onSuccess: ({ paymentData, updatedInvoice }) => {
            setLocalInvoice((prev) => {
              if (!prev) return null;
              return {
                ...prev,
                payments: Array.isArray(prev.payments) ? [...prev.payments, paymentData] : [paymentData],
                amount_due: updatedInvoice.amount_due,
                subtotal: updatedInvoice.subtotal,
                status: updatedInvoice.status,
              };
            });
          },
        }
      );
    },
    [addPaymentMutation, organizationId]
  );

  const removePayment = useCallback(
    (paymentId: string) => {
      removePaymentMutation.mutate(paymentId, {
        onSuccess: ({ updatedInvoice }) => {
          setLocalInvoice((prev) => {
            if (!prev) return prev;
            const paymentToRemove = prev.payments.find(
              (p) => p.id === paymentId
            );
            const updatedPayments = prev.payments.filter(
              (p) => p.id !== paymentId
            );
            const updatedAmountDue =
              prev.amount_due + (paymentToRemove?.amount || 0);
            return {
              ...prev,
              payments: updatedPayments,
              amount_due: updatedAmountDue,
              status: updatedInvoice.status,
            };
          });
        },
      });
    },
    [removePaymentMutation]
  );

  const deleteInvoice = useCallback(() => {
    if (
      localInvoice &&
      window.confirm(
        `Are you sure you want to delete invoice ${
          localInvoice.invoice_number || "Unknown"
        }?`
      )
    ) {
      deleteInvoiceMutation.mutate(undefined, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["invoices"] });
          navigate({ to: "/" });
        },
        onError: (error) => {
          console.error("Error deleting invoice:", error);
          alert("Failed to delete invoice. Please try again or contact support if the problem persists.");
        },
      });
    }
  }, [localInvoice, deleteInvoiceMutation, navigate, queryClient]);

  const handleDownloadPDF = useCallback(async () => {
    try {
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;
      if (!session) throw new Error("No active session found");

      const token = session.access_token;
      console.log("Auth token retrieved successfully");

      const apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3001/generate-pdf"
          : "/api/generate-pdf";

      console.log("Sending request to generate PDF");
      const response = await axios.post(
        apiUrl,
        { invoiceData: localInvoice },
        {
          responseType: "blob",
          headers: { Authorization: `Bearer ${token}` },
          validateStatus: (status) => status < 500,
        }
      );

      console.log("PDF generation response status:", response.status);

      if (response.status === 401) {
        throw new Error(
          "Authentication failed. Token may be invalid or expired."
        );
      }

      if (response.status !== 200) {
        const errorText = await response.data.text();
        throw new Error(
          `Failed to generate PDF. Server responded with: ${errorText}`
        );
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `invoice-${localInvoice?.id || "unknown"}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading PDF:", error);
      if (axios.isAxiosError(error) && error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response data:", await error.response.data.text());
        alert(
          `Failed to generate PDF. Server error: ${await error.response.data.text()}`
        );
      } else {
        alert("Failed to generate PDF. Please try again later.");
      }
    }
  }, [localInvoice, supabase.auth]);

  const handleSendInvoice = useCallback(async () => {
    if (!localInvoice || !localInvoice.client_id) {
      alert("Please select a client before sending the invoice.");
      return;
    }

    const client = clients?.find((c) => c.id === localInvoice.client_id);
    if (!client || !client.email) {
      alert("Client email not found. Please update the client information.");
      return;
    }

    try {
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;
      if (!session) throw new Error("No active session found");

      const token = session.access_token;

      const apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3000/send-invoice"
          : "/api/send-invoice";

      console.log("Sending invoice data:", localInvoice);
      console.log("Client email:", client.email);

      const pdfResponse = await axios.post(
        "/api/generate-pdf",
        { invoiceData: localInvoice },
        { headers: { Authorization: `Bearer ${token}` }, responseType: "blob" }
      );

      const pdfBlob = new Blob([pdfResponse.data], { type: "application/pdf" });

      const formData = new FormData();
      formData.append("invoiceData", JSON.stringify(localInvoice));
      formData.append("clientEmail", client.email);
      formData.append("clientName", client.full_name);
      formData.append("pdfFile", pdfBlob, `invoice-${localInvoice.id}.pdf`);

      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        alert("Invoice sent successfully!");
        setIsSendDrawerOpen(false);
      } else {
        throw new Error(`Failed to send invoice. Status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error sending invoice:", error);
      if (error instanceof Error) {
        console.error("Error details:", error.message);
      }
      if (axios.isAxiosError(error) && error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response data:", error.response.data);
      }
      alert(
        "Failed to send invoice. Please check the console for more details."
      );
    }
  }, [localInvoice, clients, supabase.auth]);

  const handlePreviewHTML = useCallback(async () => {
    try {
      const {
        data: { session },
        error: sessionError,
      } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;
      if (!session) throw new Error("No active session found");

      const token = session.access_token;
      console.log("Auth token retrieved successfully");

      const apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3001/preview-invoice-html"
          : "/api/preview-invoice-html";

      console.log("Sending request to preview HTML");
      const response = await axios.post(
        apiUrl,
        { invoiceData: localInvoice },
        {
          responseType: "text",
          headers: { Authorization: `Bearer ${token}` },
          validateStatus: (status) => status < 500,
        }
      );

      console.log("HTML preview response status:", response.status);

      if (response.status === 401) {
        throw new Error(
          "Authentication failed. Token may be invalid or expired."
        );
      }

      if (response.status !== 200) {
        throw new Error(
          `Failed to generate HTML preview. Server responded with status ${response.status}`
        );
      }

      const newWindow = window.open();
      newWindow!.document.write(response.data);
      newWindow!.document.close();
    } catch (error) {
      console.error("Error previewing HTML:", error);
      alert("Failed to preview HTML. Please try again later.");
    }
  }, [localInvoice, supabase.auth]);

  const handleInvoiceNumberChange = useCallback((value: string) => {
    updateInvoice("invoice_number", value);
  }, [updateInvoice]);


  const resetToDefaultSettings = useCallback(async () => {
    if (!id || !organizationId) return;

    try {
      console.log("Fetching brand settings for organization:", organizationId);
      const { data: brandSettings, error: brandSettingsError } = await supabase
        .from("brand_settings")
        .select("*")
        .eq("organization_id", organizationId)
        .single();

      if (brandSettingsError) {
        console.error("Error fetching brand settings:", brandSettingsError);
        throw brandSettingsError;
      }

      console.log("Brand settings fetched:", brandSettings);

      const defaultInvoiceSettings = {
        font: brandSettings?.default_font || defaultSettings.default_font,
        header_color: brandSettings?.default_header_color || defaultSettings.default_header_color,
        header_text_color: brandSettings?.default_header_text_color || defaultSettings.default_header_text_color,
        background_color: brandSettings?.default_background_color || defaultSettings.default_background_color,
        body_text_color: brandSettings?.default_body_text_color || defaultSettings.default_body_text_color,
        invoice_template: brandSettings?.default_template || 'simple',
      };

      console.log("Default invoice settings to be applied:", defaultInvoiceSettings);

      console.log("Updating invoice with ID:", id);
      const { data, error } = await supabase
        .from("invoices")
        .update(defaultInvoiceSettings)
        .eq("id", id)
        .select()
        .single();

      if (error) {
        console.error("Error updating invoice:", error);
        throw error;
      }

      console.log("Invoice updated successfully:", data);

      setLocalInvoice(prev => prev ? { ...prev, ...data } : data);
      console.log("Local invoice state updated");
    } catch (error) {
      console.error("Error resetting to default settings:", error);
    }
  }, [id, organizationId, supabase, setLocalInvoice]);

  const sortedInvoiceItems = useMemo(() => {
    return localInvoice ? orderBy(localInvoice.items, ["order"], ["asc"]) : [];
  }, [localInvoice]);

  const reorderItems = useCallback(
    (newItems: InvoiceItem[]) => {
      const updatedItems = newItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      setLocalInvoice((prev) => {
        if (!prev) return prev;
        return { ...prev, items: updatedItems };
      });
      reorderInvoiceItemsMutation.mutate(updatedItems);
    },
    [reorderInvoiceItemsMutation]
  );

  const client = useMemo(() => {
    return clients?.find((c) => c.id === localInvoice?.client_id) || null;
  }, [clients, localInvoice?.client_id]);

  const getInvoiceStatus = useCallback((invoice: InvoiceData) => {
    if (invoice.status === 'draft') return 'draft';
    if (invoice.status === 'paid') return 'paid';
    
    const dueDate = new Date(invoice.due_date);
    const today = new Date();
    
    if (isAfter(today, dueDate)) {
      return 'overdue';
    }
    
    return 'unpaid';
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (localInvoice) {
      const currentStatus = getInvoiceStatus(localInvoice);
      if (currentStatus !== localInvoice.status && isMounted) {
        updateInvoice('status', currentStatus);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [localInvoice, getInvoiceStatus, updateInvoice]);

  useEffect(() => {
    if (localInvoice) {
      const currentStatus = getInvoiceStatus(localInvoice);
      if (currentStatus !== localInvoice.status) {
        updateInvoice('status', currentStatus);
      }
    }
  }, [localInvoice, getInvoiceStatus, updateInvoice]);

  useEffect(() => {
    setPageHeaderProps({
      parentPath: "/",
      parentName: "Invoices",
      title: (
        <>
          <NavInvoiceTitle>
            {localInvoice?.invoice_number || "#"}
          </NavInvoiceTitle>
          {localInvoice && (
            <StatusBadge $status={getInvoiceStatus(localInvoice)}>
              {getInvoiceStatus(localInvoice)}
            </StatusBadge>
          )}
        </>
      ),
      right: (
        <ButtonWrapper>
          <Button onClick={() => setIsSendDrawerOpen(true)} buttonType="primary">Send Invoice</Button>
          <Button onClick={() => setIsHistoryDrawerOpen(true)}>History</Button>
          <InvoiceMoreMenu
            onDelete={deleteInvoice}
            onDownloadPDF={handleDownloadPDF}
            onPreviewHTML={handlePreviewHTML}
            onShare={handleShareInvoice}
          />
          <ToggleButton onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
            <RightSidebar16 />
          </ToggleButton>
        </ButtonWrapper>
      ),
    });
  }, [
    setPageHeaderProps,
    localInvoice,
    isSidebarVisible,
  ]);

  const { data: emailTrackingData } = useEmailTrackingData(localInvoice?.id ?? null);

  if (isLoadingInvoice) {
    return <InvoicePageSkeleton />;
  }

  if (isErrorInvoice) {
    return <div>Error loading invoice: {invoiceError?.message || 'Unknown error'}</div>;
  }

  if (!localInvoice) {
    return <div>Invoice not found</div>;
  }

  const SelectedTemplate = invoiceTemplates[localInvoice.invoice_template];

  const handleCreateNewClient = () => {
    setIsNewClientDrawerOpen(true);
  };

  const handleCreateNewService = () => {
    setIsAddServiceDrawerOpen(true);
  };

  const handleNewServiceCreated = (newService: Service) => {
    // Add the new service as a line item
    if (id) {
      const newItem: Omit<InvoiceItem, "id"> = {
        invoice_id: id,
        description: newService.name,
        quantity: 1,
        price: newService.price,
        taxable: newService.taxable,
        order: localInvoice?.items?.length ?? 0,
      };

      // Optimistic update
      const tempId = `temp-${Date.now()}`;
      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedItems = Array.isArray(prev.items)
          ? [...prev.items, { ...newItem, id: tempId, order: prev.items.length }]
          : [{ ...newItem, id: tempId, order: 0 }];
        return { ...prev, items: updatedItems };
      });

      // Add the item to the database
      addInvoiceItemMutation.mutate(newItem, {
        onSuccess: ({ newItem: addedItem, updatedInvoice }) => {
          console.log("Successfully added item:", addedItem);
          setLocalInvoice((prev) => {
            if (!prev) return updatedInvoice;
            const updatedItems = Array.isArray(prev.items)
              ? prev.items.map(item => item.id === tempId ? { ...addedItem, order: prev.items.length - 1 } : item)
              : [{ ...addedItem, order: 0 }];
            return {
              ...prev,
              ...updatedInvoice,
              items: updatedItems,
              // Preserve 'draft' status if it was already set
              status: prev.status === 'draft' ? 'draft' : updatedInvoice.status
            };
          });
        },
        onError: (error) => {
          console.error("Error adding new item:", error);
          // Revert the optimistic update
          setLocalInvoice((prev) => {
            if (!prev) return prev;
            const updatedItems = Array.isArray(prev.items)
              ? prev.items.filter(item => item.id !== tempId)
              : [];
            return { ...prev, items: updatedItems };
          });
          // Optionally, show an error message to the user
        }
      });
    }
  };
  return (
    <PageWrapper>
      <InvoiceContainer>
        <InvoiceWrapper>
          <MainContent
            $isSidebarVisible={isSidebarVisible}
            $backgroundColor={
              localInvoice.background_color ||
              (brandSettings?.default_background_color ?? "#ffffff")
            }
            $bodyTextColor={
              localInvoice.body_text_color ||
              (brandSettings?.default_body_text_color ?? "#000000")
            }
            $font={
              localInvoice.font || (brandSettings?.default_font ?? "Arial")
            }
          >
            <AnimatedHeader
              headerColor={
                localInvoice.header_color ||
                brandSettings?.default_header_color ||
                "#ffffff"
              }
            >
              {SelectedTemplate && (
                <SelectedTemplate
                  invoice={{
                    ...localInvoice,
                    ...localChanges,
                    header_color:
                      localInvoice.header_color ||
                      brandSettings?.default_header_color ||
                      "#ffffff",
                    header_text_color:
                      localInvoice.header_text_color ||
                      brandSettings?.default_header_text_color ||
                      "#000000",
                    font:
                      localInvoice.font ||
                      brandSettings?.default_font ||
                      "Arial",
                    logo_url: localInvoice.logo_url,
                  }}
                  updateInvoice={updateInvoice}
                  clients={clients || []}
                  ClientSelectorComponent={(props) => (
                    <ClientSelector
                      {...props}
                      onCreateNewClient={handleCreateNewClient}
                    />
                  )}
                  onCreateNewClient={handleCreateNewClient}
                  selectedClient={client}
                />
              )}
            </AnimatedHeader>
            <InvoiceDetailsContainer>
            <InvoiceItems
              invoice={{
                ...localInvoice,
                items: sortedInvoiceItems
                  .filter(item => !deletingItems.has(item.id) && item.id !== undefined)
                  .map(item => ({ ...item, id: item.id as string }))
              }}
              services={services || []}
              handleServiceSelect={handleServiceSelect}
              handleDescriptionChange={handleDescriptionChange}
              updateItem={updateItem}
              deleteItem={deleteItem}
              reorderItems={reorderItems}
              onCreateNewService={handleCreateNewService}
            />
              <AddItemRow>
                <Button onClick={() => addItem()}>Add Item</Button>
              </AddItemRow>
              {localInvoice && (
                <InvoiceTotals invoice={localInvoice} />
              )}
            
            </InvoiceDetailsContainer>
            <NotesEditor
                initialContent={localInvoice.notes || ""}
                onUpdate={(content) => updateInvoice("notes", content)}
              />
          </MainContent>
          <PaymentsContent>
            <PaymentsHeader>
            <PaymentsHeading>Payments</PaymentsHeading><Button onClick={() => setIsDrawerOpen(true)}>
              Manage Payments
            </Button>
            </PaymentsHeader>

            <PaymentsTable
              payments={localInvoice.payments}
              removePayment={removePayment}
            />
          </PaymentsContent>
        </InvoiceWrapper>
        <SidebarRight
          projects={projects ?? []}
          selectedProjectId={selectedProjectId}
          onSelectProject={handleSelectProject}
          invoiceTemplate={localInvoice.invoice_template}
          updateInvoice={updateInvoice}
          headerColor={
            localInvoice.header_color ||
            brandSettings?.default_header_color ||
            "#ffffff"
          }
          headerTextColor={
            localInvoice.header_text_color ||
            brandSettings?.default_header_text_color ||
            "#000000"
          }
          backgroundColor={
            localInvoice.background_color ||
            brandSettings?.default_background_color ||
            "#ffffff"
          }
          bodyTextColor={
            localInvoice.body_text_color ||
            brandSettings?.default_body_text_color ||
            "#000000"
          }
          taxRate={localInvoice.tax_rate}
          font={localInvoice.font || brandSettings?.default_font || "Arial"}
          currency={localInvoice.currency}
          isVisible={isSidebarVisible}
          resetToDefaultSettings={resetToDefaultSettings}
          organizationId={organizationId}
          clients={clients || []}
          selectedClientId={localInvoice.client_id || null}
          onSelectClient={(clientId) => updateInvoice("client_id", clientId)}
          invoiceDate={localInvoice.invoice_date}
          dueDate={localInvoice.due_date}
          dueDays={localInvoice.due_days}
          emailTrackingData={emailTrackingData || undefined}
          invoiceNumber={localInvoice.invoice_number || ""}
          onInvoiceNumberChange={handleInvoiceNumberChange}
        />
      </InvoiceContainer>

      <PaymentsDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        payments={localInvoice?.payments || []}
        addPayment={addPayment}
        removePayment={removePayment}
        amount_due={localInvoice?.amount_due || 0}
        organizationId={organizationId}
        invoiceStatus={localInvoice?.status || 'draft'}
      />
      <NewClientDrawer
        isOpen={isNewClientDrawerOpen}
        setIsOpen={setIsNewClientDrawerOpen}
        onClientAdded={(newClient) => {
          queryClient.setQueryData<Client[]>(["clients"], (old) => [
            ...(old || []),
            newClient,
          ]);
          updateInvoice("client_id", newClient.id);
          setIsNewClientDrawerOpen(false);
        }}
      />
      <SendInvoiceDrawer
        isOpen={isSendDrawerOpen}
        setIsOpen={setIsSendDrawerOpen}
        invoice={localInvoice}
        client={client}
        onSendInvoice={handleSendInvoice}
        onDownloadPDF={handleDownloadPDF}
        onOverlayClick={() => setIsSendDrawerOpen(false)}
      />
      <AddServiceDrawer
        isOpen={isAddServiceDrawerOpen}
        setIsOpen={setIsAddServiceDrawerOpen}
        serviceToEdit={null}
        onServiceCreated={handleNewServiceCreated}
      />
      <InvoiceHistoryDrawer
        isOpen={isHistoryDrawerOpen}
        setIsOpen={setIsHistoryDrawerOpen}
        payments={localInvoice?.payments || []}
        emailTrackingData={emailTrackingData || null}
        invoice_created_at={localInvoice?.invoice_created_at || ''}
      />
    </PageWrapper>
  );
};

export default InvoicePage;
