import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useParams, useNavigate } from '@tanstack/react-router';
import { projectRoute } from './router';
import { supabase } from "./supabaseClient";
import { Project, Invoice, Expense, Task, TimeEntry, InvoiceData } from "./types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import Button from "./components/Button";
import EntityPicker from "./components/EntityPicker";
import { Client12, Close12, Calendar12, Tasks32 } from "./components/Icon";
import AddExpenseDrawer from "./components/Expenses/AddExpenseDrawer";
import Tabs from "./components/Tabs";
import DatePicker from "./components/DatePicker";
import TaskDrawer from './components/Tasks/TaskDrawer';
import TaskDetails from './components/Tasks/TaskDetails';
import { useProjects } from './hooks/useProjects';
import { useOrganization } from './hooks/useOrganization';
import { useClients } from './hooks/useClients';
import { useCreateInvoiceMutation } from "./hooks/useCreateInvoiceMutation";
import { useExpenseOperations } from './hooks/useExpenseOperations';
import { useOrganizationUsers } from './hooks/useOrganizationUsers';
import { useUser } from './hooks/useUser';
import { useTaskMutations } from './hooks/useTaskMutations';
import NotesEditor from "./components/NotesEditor";
import { useInvoices } from './hooks/useInvoices';
import { ColumnDef } from '@tanstack/react-table';
import { useTaskStatuses } from './hooks/useTaskStatuses';
import TaskTable from './components/Tasks/TaskTable';
import AddTimeEntryDrawer from "./components/Time/AddTimeEntryDrawer";
import { debounce } from "lodash";
import { useTimeTracking } from './hooks/useTimeTracking';
import InvoiceList from './components/InvoiceList';
import ExpenseList from './components/ExpenseList';
import TimeEntryList from './components/TimeEntryList';
import InvoiceDrawer from './components/Invoices/InvoiceDrawer';
import InvoiceDetails from './components/Invoices/InvoiceDetails';
import { useFetchTasks } from './hooks/useFetchTasks';
import { usePageContext } from './hooks/usePageContext';

const ProjectPageWrapper = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const Section = styled.section`
  margin-bottom: 40px;
`;

const InputField = styled.div`

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }

  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  textarea {
    min-height: 100px;
  }

  input[type="checkbox"] {
    margin-right: 10px;
  }
`;

const PageContent = styled.div`
  height: 100%;
`;

const ProjectMetaWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 16px 32px 0;
  gap: 4px;
`;

const TabContent = styled.div`
  height: calc(100% - 48px); // Adjust this value based on your tabs height
`;

const EditableTitleWrapper = styled.div`
  display: inline-block;
  position: relative;
  min-width: 200px;
  max-width: 100%;
  margin-left: -8px;
`;

const EditableTitle = styled.div`
  font-size: inherit;
  line-height: inherit;
  font-family: 'PP Mori', sans-serif;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 0 8px;
  border-radius: 8px;
  white-space: pre;
  display: inline-block;
  min-width: 1px;
  color: rgba(0, 0, 0, 0.8);

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:empty:before {
    content: attr(data-placeholder);
    color: rgba(0, 0, 0, 0.5);
  }
`;

const OverviewContent = styled.div`
  margin: 0 auto;
  max-width: 960px;
`;

const ProjectPage: React.FC = () => {
  const { id } = useParams({ from: projectRoute.id });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const { data: projects } = useProjects();
  const { data: clients = [] } = useClients();
  const createInvoiceMutation = useCreateInvoiceMutation();
  const { data: organizationUsers } = useOrganizationUsers();
  const { data: user } = useUser();
  const { taskStatuses, getDefaultStatus } = useTaskStatuses();
  const { stopTimer } = useTimeTracking();
  const { updateTaskMutation, createTaskMutation, deleteTaskMutation } = useTaskMutations();
  const { setPageHeaderProps } = usePageContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);

  const [localProject, setLocalProject] = useState<Project | null>(null);
  const [localChanges, setLocalChanges] = useState<Partial<Project>>({});
  const [activeTab, setActiveTab] = useState<'overview' | 'invoices' | 'expenses' | 'tasks' | 'time-entries'>('overview');
  const [isTaskDrawerOpen, setIsTaskDrawerOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [, setIsDatePickerOpen] = useState(false);
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const [isTimeEntryDrawerOpen, setIsTimeEntryDrawerOpen] = useState(false);
  const [selectedTimeEntryId, setSelectedTimeEntryId] = useState<string | null>(null);
  const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceData | null>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);

  const projectStatuses = [
    { id: "not_started", name: "Not Started" },
    { id: "planning", name: "Planning" },
    { id: "in_progress", name: "In Progress" },
    { id: "on_hold", name: "On Hold" },
    { id: "completed", name: "Completed" },
  ];

  const { data: project } = useQuery<Project>({
    queryKey: ["project", id, organizationId],
    queryFn: async () => {
      if (!id || !organizationId) throw new Error("Project ID or Organization ID not found");
      
      // Check for prefetched data
      const cachedData = queryClient.getQueryData(["project", id, organizationId]);
      if (cachedData) return cachedData as Project;

      // If not in cache, fetch from Supabase
      const { data, error } = await supabase
        .from("projects")
        .select("*")
        .eq("id", id)
        .eq("organization_id", organizationId)
        .single();
      if (error) throw error;
      return data as Project;
    },
    enabled: !!id && !!organizationId,
  });


  const handleCreateInvoice = useCallback(async () => {
    if (project && organizationId) {
      try {
        const invoiceData = {
          project_id: project.id,
          client_id: project.client_id || undefined,
          organization_id: organizationId,
        };

        console.log("Creating invoice with data:", invoiceData);

        const newInvoice = await createInvoiceMutation.mutateAsync(invoiceData);

        console.log("New invoice created:", newInvoice);

        if (newInvoice) {
          queryClient.invalidateQueries({ queryKey: ["projectInvoices", id, organizationId] });
          navigate({ to: '/invoice/$id', params: { id: newInvoice.id } });
        }
      } catch (error) {
        console.error("Error creating invoice:", error);
      }
    }
  }, [project, organizationId, createInvoiceMutation, queryClient, id, navigate]);

  const { data: invoices, isLoading: isLoadingInvoices } = useInvoices();

  const projectInvoices = useMemo(() => {
    return invoices?.filter(invoice => invoice.project_id === id).map(invoice => ({
      ...invoice,
      customer: invoice.customer_name || '', // Assuming customer_name exists in Invoice type
      items: [], // You might need to fetch invoice items separately
      invoice_template: 'default', // Set a default value or fetch from somewhere
      header_color: '#ffffff', // Set a default value or fetch from somewhere
      header_text_color: '#000000', // Set a default value or fetch from somewhere
      font: 'Arial', // Set a default value or fetch from somewhere
      due_days: 30, // Set a default value or calculate based on invoice_date and due_date
      currency: invoice.currency || 'USD', // Assuming currency exists in Invoice type, otherwise set a default
      payments: [], // Add an empty array for payments
    } as unknown as InvoiceData)) || [];
  }, [invoices, id]);


  const { data: expenses, isLoading: isLoadingExpenses } = useQuery<Expense[], Error>({
    queryKey: ["projectExpenses", id, organizationId],
    queryFn: async () => {
      if (!organizationId || !id) throw new Error("Organization or Project ID not found");
      
      // Check for prefetched data
      const cachedData = queryClient.getQueryData(["projectExpenses", id, organizationId]);
      if (cachedData) return cachedData as Expense[];

      // If not in cache, fetch from Supabase
      const { data, error } = await supabase
        .from("expenses")
        .select("*")
        .eq("project_id", id)
        .eq("organization_id", organizationId);
      if (error) throw error;
      return data as Expense[];
    },
    enabled: !!id && !!organizationId,
  });

  const { data: tasks, isLoading: isLoadingTasks, localUpdatesRef } = useFetchTasks();

  const [localTasks, setLocalTasks] = useState<Task[]>([]);

  const sortTasks = useCallback((tasks: Task[]) => {
    return [...tasks].sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }, []);

  useEffect(() => {
    if (tasks) {
      setLocalTasks(sortTasks(tasks.filter(task => task.project_id === id)));
    }
  }, [tasks, sortTasks, id]);

  const updateLocalTask = useCallback((updatedTask: Partial<Task>) => {
    setLocalTasks(prev =>
      prev.map(task =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      )
    );
  }, []);

  const updateTask = useCallback((updatedTask: Partial<Task>, isEditing: boolean = false) => {
    if (!updatedTask.id) return;

    // Update localUpdatesRef with the editing state
    localUpdatesRef.current.set(updatedTask.id, isEditing);

    setLocalTasks(prev =>
      prev.map(task =>
        task.id === updatedTask.id ? { ...task, ...updatedTask } : task
      )
    );

    updateTaskMutation.mutate(updatedTask, {
      onSettled: () => {
        // Remove the task ID from the localUpdatesRef if not editing
        if (!isEditing) {
          localUpdatesRef.current.delete(updatedTask.id!);
        }
      },
    });
  }, [updateTaskMutation, localUpdatesRef]);

  const handleCreateTask = useCallback(() => {
    if (!organizationId || !user || !id) {
      console.error('Organization, user, or project not found');
      return;
    }

    const defaultStatus = getDefaultStatus();

    if (!defaultStatus) {
      console.error('No default status found');
      return;
    }

    const newTask = {
      description: '',
      is_completed: false,
      due_date: null,
      notes: '',
      client_id: localProject?.client_id || null,
      project_id: id,
      user_id: user.id,
      organization_id: organizationId,
      status_id: defaultStatus.id,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };

    createTaskMutation.mutate(newTask, {
      onSuccess: (createdTask) => {
        setLocalTasks(prev => sortTasks([...prev, createdTask]));
        setSelectedTaskId(createdTask.id);
        setIsTaskDrawerOpen(true);
      },
      onError: (error) => {
        console.error('Error creating task:', error);
      }
    });
  }, [createTaskMutation, organizationId, user, id, getDefaultStatus, localProject, sortTasks]);

  const handleDeleteTask = useCallback((taskId: string) => {
    deleteTaskMutation.mutate(taskId, {
      onSuccess: () => {
        setLocalTasks(prev => prev.filter(task => task.id !== taskId));
        setSelectedTaskId(null);
        setIsTaskDrawerOpen(false);
      },
      onError: (error) => {
        console.error('Failed to delete task:', error);
        alert('Failed to delete task. Please try again.');
      },
    });
  }, [deleteTaskMutation]);

  const { data: timeEntries, isLoading: isLoadingTimeEntries } = useQuery<TimeEntry[]>({
    queryKey: ['projectTimeEntries', id, organizationId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('time_entries')
        .select('*')
        .eq('project_id', id)
        .eq('organization_id', organizationId);
      if (error) throw error;
      return data;
    },
    enabled: !!id && !!organizationId,
  });

  const updateProjectMutation = useMutation<Project, Error, Partial<Project> & { id: string }>({
    mutationFn: async (updatedProject) => {
      const { id, ...updateData } = updatedProject;
      const { data, error } = await supabase
        .from("projects")
        .update(updateData)
        .eq("id", id)
        .select()
        .single();
      if (error) throw error;
      return data as Project;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["project", data.id, organizationId], data);
      queryClient.invalidateQueries({ queryKey: ["projects", organizationId] });
    },
  });

  const handleStatusChange = (statusId: string | null) => {
    if (localProject) {
      const updatedProject = { ...localProject, status: statusId };
      setLocalProject(updatedProject);
      debouncedUpdateProject({ status: statusId });
    }
  };

  const {
    isExpenseDrawerOpen,
    setIsExpenseDrawerOpen,
    editingExpense,
    handleEditExpense: handleEditExpenseFromHook,
    handleDeleteExpense,
    handleSaveExpense,
    handleCreateExpense,
  } = useExpenseOperations(id, undefined, organizationId);

  const handleClientChange = (clientId: string | null) => {
    if (localProject) {
      const updatedProject = { ...localProject, client_id: clientId };
      setLocalProject(updatedProject);
      debouncedUpdateProject({ client_id: clientId });
    } else {
      console.error("Local project is undefined");
    }
  };

  const debouncedUpdateProject = useCallback(
    debounce((updatedFields: Partial<Project>) => {
      console.log("Updating project:", updatedFields);
      if (id) {
        updateProjectMutation.mutate({ id, ...updatedFields });
      } else {
        console.error("Project ID is undefined");
      }
    }, 500),
    [updateProjectMutation, id]
  );

  const handleDueDateChange = (date: Date | null) => {
    if (localProject) {
      const updatedProject = {
        ...localProject,
        due_date: date ? date.toISOString().split('T')[0] : null,
      };
      setLocalProject(updatedProject);
      debouncedUpdateProject({ due_date: updatedProject.due_date });
    }
  };

  useEffect(() => {
    if (id && organizationId) {
      // Prefetch project data
      queryClient.prefetchQuery({
        queryKey: ["project", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("projects")
            .select("*")
            .eq("id", id)
            .eq("organization_id", organizationId)
            .single();
          if (error) throw error;
          return data as Project;
        },
      });

      // Prefetch invoices
      queryClient.prefetchQuery({
        queryKey: ["projectInvoices", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("invoices")
            .select("*")
            .eq("project_id", id)
            .eq("organization_id", organizationId);
          if (error) throw error;
          return data as Invoice[];
        },
      });

      // Prefetch expenses
      queryClient.prefetchQuery({
        queryKey: ["projectExpenses", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("expenses")
            .select("*")
            .eq("project_id", id)
            .eq("organization_id", organizationId);
          if (error) throw error;
          return data as Expense[];
        },
      });

      // Prefetch tasks
      queryClient.prefetchQuery({
        queryKey: ["projectTasks", id, organizationId],
        queryFn: async () => {
          const { data, error } = await supabase
            .from("tasks")
            .select("*")
            .eq("project_id", id)
            .eq("organization_id", organizationId);
          if (error) throw error;
          return data as Task[];
        },
      });
    }
  }, [id, queryClient, organizationId]);

  useEffect(() => {
    if (project) {
      setLocalProject((prevLocal) => ({
        ...project,
        ...prevLocal,
      }));
    }
  }, [project]);

  useEffect(() => {
    if (Object.keys(localChanges).length > 0) {
      setLocalProject(
        (prevLocal) =>
          ({
            ...prevLocal,
            ...localChanges,
          } as Project)
      );
      setLocalChanges({});
    }
  }, [localChanges]);


  const handleCloseTaskDrawer = useCallback(() => {
    setIsTaskDrawerOpen(false);
    setSelectedTaskId(null);
  }, []);

  const handleTaskClick = useCallback((task: Task) => {
    setSelectedTaskId(task.id);
    setIsTaskDrawerOpen(true);
  }, []);

  const handleDatePickerOpenChange = useCallback((isOpen: boolean) => {
    setIsDatePickerOpen(isOpen);
  }, []);

  const handleTitleChange = useCallback(() => {
    if (titleRef.current) {
      const newTitle = titleRef.current.textContent || '';
      setLocalProject(prev => prev ? { ...prev, name: newTitle } : null);
      updateProjectMutation.mutate({ id, name: newTitle });
    }
  }, [id, updateProjectMutation]);

  useEffect(() => {
    if (titleRef.current && localProject) {
      titleRef.current.textContent = localProject.name;
    }
  }, [localProject]);

  const handleDescriptionChange = useCallback((content: string) => {
    if (localProject) {
      const updatedProject = { ...localProject, description: content };
      setLocalProject(updatedProject);
      debouncedUpdateProject({ description: content });
    }
  }, [localProject, debouncedUpdateProject]);

  const handleAddNewTimeEntry = useCallback(() => {
    setSelectedTimeEntryId(null);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleTimeEntryRowClick = useCallback((timeEntry: TimeEntry) => {
    setSelectedTimeEntryId(timeEntry.id);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleSaveTimeEntry = useCallback(async () => {
    await stopTimer();
    queryClient.invalidateQueries({ queryKey: ['projectTimeEntries', id, organizationId] });
    setIsTimeEntryDrawerOpen(false);
    setSelectedTimeEntryId(null);
  }, [queryClient, id, organizationId, stopTimer]);

  const handleDeleteTimeEntry = useCallback(async (timeEntryId: string) => {
    try {
      await supabase.from('time_entries').delete().eq('id', timeEntryId);
      queryClient.invalidateQueries({ queryKey: ['projectTimeEntries', id, organizationId] });
    } catch (error) {
      console.error('Error deleting time entry:', error);
      alert('Failed to delete time entry. Please try again.');
    }
  }, [queryClient, id, organizationId]);

  const handleCreateTimeEntry = useCallback(async (timeEntryData: Partial<TimeEntry>): Promise<TimeEntry> => {
    const { data, error } = await supabase
      .from('time_entries')
      .insert({ ...timeEntryData, project_id: id, organization_id: organizationId })
      .select()
      .single();

    if (error) throw error;
    queryClient.invalidateQueries({ queryKey: ['projectTimeEntries', id, organizationId] });
    return data;
  }, [id, organizationId, queryClient]);

  const handleExpenseSelectionChange = useCallback((selectedIds: string[]) => {
    console.log('Selected expense IDs:', selectedIds);
    // Add any additional logic here if needed
  }, []);

  const handleTimeEntrySelectionChange = useCallback((selectedIds: string[]) => {
    console.log('Selected time entry IDs:', selectedIds);
    // Add any additional logic here if needed
  }, []);

  const renderActionButtons = useCallback(() => {
    switch (activeTab) {
      case "invoices":
        return <Button buttonType="secondary" onClick={handleCreateInvoice}>New Invoice</Button>;
      case "expenses":
        return <Button buttonType="secondary" onClick={() => setIsExpenseDrawerOpen(true)}>New Expense</Button>;
      case "tasks":
        return <Button buttonType="secondary" onClick={handleCreateTask}>New Task</Button>;
      case "time-entries":
        return <Button buttonType="secondary" onClick={handleAddNewTimeEntry}>New Time Entry</Button>;
      default:
        return null;
    }
  }, [activeTab, handleCreateInvoice, handleCreateTask, handleAddNewTimeEntry, setIsExpenseDrawerOpen]);

  const taskColumns: ColumnDef<Task>[] = useMemo(
    () => [
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        accessorKey: 'status_id',
        header: 'Status',
        cell: ({ row }) => {
          const status = taskStatuses?.find(s => s.id === row.original.status_id);
          return status ? status.name : 'Unknown';
        },
      },
      {
        accessorKey: 'due_date',
        header: 'Due Date',
        cell: ({ getValue }) => getValue() ? new Date(getValue() as string).toLocaleDateString() : 'No due date',
      },
      {
        accessorKey: 'user_id',
        header: 'Assignee',
        cell: ({ row }) => {
          const user = organizationUsers?.find(u => u.id === row.original.user_id);
          return user ? user.full_name || user.email : 'Unassigned';
        },
      },
    ],
    [taskStatuses, organizationUsers]
  );

  useEffect(() => {
    if (project && project.name === "") {
      setIsTitleFocused(true);
    }
  }, [project]);

  const taskEmptyState = useMemo(() => ({
    icon: <Tasks32 />,
    message: "No tasks for this project",
    subMessage: "Create your first task to get started",
    action: {
      label: "New Task",
      onClick: handleCreateTask
    }
  }), [handleCreateTask]);

  const handleInvoiceRowClick = useCallback((invoice: InvoiceData) => {
    setSelectedInvoice(invoice);
    setIsInvoiceDrawerOpen(true);
  }, []);

  const handleCloseInvoiceDrawer = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsInvoiceDrawerOpen(false);
      setSelectedInvoice(null);
    }
  }, [isAnyPopoverOpen]);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    if (!isAnyPopoverOpen && (event.target as HTMLElement).classList.contains('drawer-overlay')) {
      handleCloseInvoiceDrawer();
    }
  }, [handleCloseInvoiceDrawer, isAnyPopoverOpen]);

  useEffect(() => {
    setPageHeaderProps({
      parentPath: "/projects",
      parentName: "Projects",
      title: (
        <>
          <EditableTitleWrapper>
            <EditableTitle
              ref={titleRef}
              contentEditable
              suppressContentEditableWarning
              onInput={handleTitleChange}
              onBlur={handleTitleChange}
              data-placeholder="Enter project name"
              autoFocus={isTitleFocused}
              onFocus={() => setIsTitleFocused(true)}
            />
          </EditableTitleWrapper>
        </>
      )
    });
  }, [setPageHeaderProps, localProject, isTitleFocused]);

  if (!id) {
    console.error("Project ID is undefined");
    return <div>Error: Project not found</div>;
  }

  return (
    <ProjectPageWrapper ref={containerRef}>
      <ProjectMetaWrapper>
        <InputField>
          <EntityPicker
            selectedId={localProject?.client_id || null}
            onChange={handleClientChange}
            entities={clients.map(client => ({ id: client.id, name: client.full_name || client.email || 'Unknown Client' }))}
            label="Client"
            allowUnassigned={true}
            icon={<Client12 />}
            placement="bottom-start"
          />
        </InputField>
        <InputField>
          <EntityPicker
            selectedId={localProject?.status || null}
            onChange={handleStatusChange}
            entities={projectStatuses}
            label="Status"
            allowUnassigned={false}
            icon={<Close12 />}
            placement="bottom-start"
          />
        </InputField>
        <InputField>
          <DatePicker
            selectedDate={localProject?.due_date ? new Date(localProject.due_date) : null}
            onChange={handleDueDateChange}
            label="Due Date"
            id="project-due-date"
            onOpenChange={handleDatePickerOpenChange}
            icon={<Calendar12 />}
            placement="bottom-start"
            showClearDate={true}
          />
        </InputField>
      </ProjectMetaWrapper>
      <PageContent>
        <Section>

          <Tabs
            tabs={[
              { id: "overview", label: "Overview" },
              { id: "invoices", label: "Invoices" },
              { id: "expenses", label: "Expenses" },
              { id: "tasks", label: "Tasks" },
              { id: "time-entries", label: "Time" },
            ]}
            activeTab={activeTab}
            onTabChange={(tabId) => setActiveTab(tabId as typeof activeTab)}
            actionButtons={renderActionButtons()}
          />

          <TabContent>
            {activeTab === "overview" && (
              <OverviewContent>
                <InputField>
                  <NotesEditor
                    initialContent={localProject?.description || ""}
                    onUpdate={handleDescriptionChange}
                  />
                </InputField>
              </OverviewContent>
            )}

            {activeTab === "invoices" && (
              <InvoiceList
                invoices={projectInvoices}
                isLoading={isLoadingInvoices}
                onCreateInvoice={handleCreateInvoice}
                containerRef={containerRef}
                onSelectionChange={(selectedIds) => {
                  console.log('Selected invoice IDs in ProjectPage:', selectedIds);
                  // Add any additional logic here
                }}
                onRowClick={handleInvoiceRowClick}
                selectedInvoiceId={selectedInvoice?.id || null}
              />
            )}

            {activeTab === "expenses" && (
              <ExpenseList
                expenses={expenses}
                isLoading={isLoadingExpenses}
                onCreateExpense={() => setIsExpenseDrawerOpen(true)}
                onEditExpense={handleEditExpenseFromHook}
                containerRef={containerRef}
                onSelectionChange={handleExpenseSelectionChange}
              />
            )}

            {activeTab === "tasks" && (
              <TaskTable
                tasks={localTasks}
                columns={taskColumns}
                isLoading={isLoadingTasks}
                onRowClick={handleTaskClick}
                onImmediateUpdate={updateTask}
                getRowKey={(task) => task.id}
                clients={clients || []}
                projects={projects || []}
                organizationUsers={organizationUsers || []}
                taskStatuses={taskStatuses || []}
                selectedTaskId={selectedTaskId}
                onTasksUpdate={(updatedTasks) => setLocalTasks(updatedTasks)}
                containerRef={containerRef}
                emptyState={taskEmptyState}
                onDeleteTask={handleDeleteTask}
              />
            )}

            {activeTab === "time-entries" && (
              <TimeEntryList
                timeEntries={timeEntries}
                isLoading={isLoadingTimeEntries}
                onCreateTimeEntry={handleAddNewTimeEntry}
                onEditTimeEntry={handleTimeEntryRowClick}
                containerRef={containerRef}
                onSelectionChange={handleTimeEntrySelectionChange}
              />
            )}
          </TabContent>
        </Section>
      </PageContent>

      <AddExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        setIsOpen={setIsExpenseDrawerOpen}
        expense={editingExpense || undefined}
        onSave={handleSaveExpense}
        onDelete={handleDeleteExpense}
        projectId={id}
        onCreate={handleCreateExpense}
        organizationId={organizationId}
        defaultProjectId={id}
      />

      <TaskDrawer
        isOpen={isTaskDrawerOpen}
        setIsOpen={setIsTaskDrawerOpen}
        title="Task Details"
        selectedTask={localTasks.find(t => t.id === selectedTaskId) || null}
        onOverlayClick={handleCloseTaskDrawer}
      >
        {selectedTaskId && (
          <TaskDetails
            key={selectedTaskId}
            task={localTasks.find(t => t.id === selectedTaskId) || null}
            updateTask={updateTask}
            updateLocalTask={updateLocalTask}
            clients={clients}
            projects={projects || []}
            organizationUsers={organizationUsers || []}
            taskStatuses={taskStatuses || []}
            onPopoverOpenChange={handleDatePickerOpenChange}
            onDeleteTask={handleDeleteTask}
          />
        )}
      </TaskDrawer>

      <AddTimeEntryDrawer
        isOpen={isTimeEntryDrawerOpen}
        setIsOpen={setIsTimeEntryDrawerOpen}
        timeEntry={timeEntries?.find(entry => entry.id === selectedTimeEntryId) || null}
        onSave={handleSaveTimeEntry}
        onDelete={() => selectedTimeEntryId && handleDeleteTimeEntry(selectedTimeEntryId)}
        onCreate={handleCreateTimeEntry}
        organizationId={organizationId}
        defaultProjectId={id}
        defaultClientId={localProject?.client_id || undefined}
        onOverlayClick={() => setIsTimeEntryDrawerOpen(false)}
        queryKey={['projectTimeEntries', id, organizationId]}
        stopTimer={stopTimer}
      />

      <InvoiceDrawer
        isOpen={isInvoiceDrawerOpen}
        setIsOpen={setIsInvoiceDrawerOpen}
        selectedInvoice={selectedInvoice}
        onOverlayClick={handleOverlayClick}
        onPopoverOpenChange={setIsAnyPopoverOpen}
        title="Invoice Details"
      >
        {selectedInvoice && (
          <InvoiceDetails
            key={selectedInvoice.id}
            invoice={selectedInvoice}
            clients={clients || []}
            navigate={navigate}
            queryClient={queryClient}
            payments={[]} // You might need to fetch payments for the project
          />
        )}
      </InvoiceDrawer>
    </ProjectPageWrapper>
  );
};

export default ProjectPage;