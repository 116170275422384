import { SimpleHeader } from './SimpleHeader';
import { DetailedHeader } from './DetailedHeader';
import { ModernHeader } from './ModernHeader';
import { MinimalistHeader } from './MinimalistHeader';
import { GradientHeader } from './GradientHeader';
import { BoxedHeader } from './BoxedHeader';
import { SplitHeader } from './SplitHeader';
import { CircularHeader } from './CircularHeader';
import { CompactHeader } from './CompactHeader';

export const invoiceTemplates = {
  simple: SimpleHeader,
  detailed: DetailedHeader,
  modern: ModernHeader,
  minimalist: MinimalistHeader,
  gradient: GradientHeader,
  boxed: BoxedHeader,
  split: SplitHeader,
  circular: CircularHeader,
  compact: CompactHeader,
};