import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';

interface CreateOrganizationParams {
  name: string;
}

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: CreateOrganizationParams) => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      const { data: organization, error: organizationError } = await supabase
        .from('organizations')
        .insert([{ name: params.name }])
        .select()
        .single();

      if (organizationError) throw organizationError;

      // Add user as a member of the organization and mark as owner
      const { error: memberError } = await supabase
        .from('organization_members')
        .insert([{ 
          organization_id: organization.id, 
          user_id: user.id, 
          is_owner: true,  // Ensure is_owner is set to true
          role: 'admin'    // Set the role to 'owner'
        }]);

      if (memberError) {
        console.error('Error adding member to organization:', memberError);
        throw memberError;
      }

      // Set the new organization as the current organization
      const { error: updateError } = await supabase.auth.updateUser({
        data: { current_organization_id: organization.id }
      });

      if (updateError) {
        console.error('Error updating current organization:', updateError);
        throw updateError;
      }

      return organization;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization'] });
      queryClient.invalidateQueries({ queryKey: ['userOrganizations'] });
    },
  });
};