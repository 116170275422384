import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import styled from 'styled-components';

interface NotesEditorProps {
  initialContent: string;
  onUpdate: (content: string) => void;
}

const NotesEditor: React.FC<NotesEditorProps> = ({ initialContent, onUpdate }) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Start typing your notes here...',
      }),
    ],
    content: initialContent,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onUpdate(html);
    },
  });

  return (
    <EditorWrapper>
      <EditorContent editor={editor} />
    </EditorWrapper>
  );
};

const EditorWrapper = styled.div`
  .ProseMirror {
    min-height: 100px;
    outline: none;
    padding: 32px 76px 80px 76px;
    border: transparent;
    border-radius: 0;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

export default NotesEditor;