import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';
import '../../RightDrawer.css';
import { InvoiceData } from '../../types';
import { Close12 } from '../Icon';
import Button from '../Button';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../supabaseClient';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;

  @media (max-width: 768px) {
    max-width: 100%;
    height: 90vh;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  background-color: rgba(248, 247, 246, 1);
  
  height: 100%;
  overflow-y: scroll;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

interface InvoiceDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  title: string;
  selectedInvoice: InvoiceData | null;
  onOverlayClick: (event: React.MouseEvent) => void;
  onPopoverOpenChange: (isOpen: boolean) => void;
}

const InvoiceDrawer: React.FC<InvoiceDrawerProps> = ({
  isOpen,
  setIsOpen,
  children,
  onOverlayClick,
  selectedInvoice,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const queryClient = useQueryClient();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isDatePickerOpen || isAnyPopoverOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnInvoiceRow = (clickedElement as Element).closest('.invoice-row') !== null;
      const isClickOnPopover = (clickedElement as Element).closest('.entity-picker-popover') !== null;

      if (!isClickInsideDrawer && !isClickOnInvoiceRow && !isClickOnPopover) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isDatePickerOpen, isAnyPopoverOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const prefetchInvoice = useCallback(() => {
    console.log('Prefetch function called in InvoiceDrawer');
    if (selectedInvoice) {
      console.log('Attempting to prefetch invoice:', selectedInvoice.id);
      queryClient.prefetchQuery({
        queryKey: ['invoice', selectedInvoice.id],
        queryFn: async () => {
          console.log('Executing prefetch query function in InvoiceDrawer');
          const { data, error } = await supabase
            .from('invoices')
            .select('*')
            .eq('id', selectedInvoice.id)
            .single();
          if (error) {
            console.error('Error prefetching invoice in InvoiceDrawer:', error);
            throw error;
          }
          console.log('Successfully prefetched invoice data in InvoiceDrawer:', data);
          return data;
        },
        staleTime: 5000, // 5 seconds
      });
    } else {
      console.log('No selected invoice available for prefetching in InvoiceDrawer');
    }
  }, [queryClient, selectedInvoice]);

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction={isMobile ? "bottom" : "right"}
      modal={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={onOverlayClick} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef}>
            <DrawerHeader>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            </DrawerHeader>
            <div className="invoice-details drawer-text-content">
              {React.Children.map(children, child => 
                React.isValidElement(child) 
                  ? React.cloneElement(child as React.ReactElement<{ 
                      onDatePickerOpenChange: (isOpen: boolean) => void,
                      onPopoverOpenChange: (isOpen: boolean) => void,
                      autoFocus: boolean,
                      prefetchInvoice: () => void
                    }>, { 
                      onDatePickerOpenChange: setIsDatePickerOpen,
                      onPopoverOpenChange: setIsAnyPopoverOpen,
                      autoFocus: isOpen,
                      prefetchInvoice: prefetchInvoice
                    })
                  : child
              )}
            </div>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default InvoiceDrawer;