import React from 'react';
import styled from 'styled-components';
import { Payment } from '../types';

interface PaymentsTableProps {
  payments: Payment[];
  removePayment: (paymentId: string) => void;
}

const TableWrapper = styled.div`
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 12px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 8px 0;
  border-bottom: 1.5px solid rgba(0,0,0,0.1);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  color: rgba(0,0,0,0.5);
`;

const TableCell = styled.td`
  padding: 12px 0;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  font-size: 14px;
`;

const RemoveButton = styled.button`
  color: #4f46e5;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    color: #4338ca;
  }
`;

const NoPaymentsMessage = styled.div`
  color: rgba(0,0,0,0.5);
  font-style: italic;
  font-size: 14px;
`;

const PaymentsTable: React.FC<PaymentsTableProps> = ({ payments = [], removePayment }) => {
  if (!payments || payments.length === 0) {
    return <NoPaymentsMessage>No payments recorded.</NoPaymentsMessage>;
  }

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                       'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = date.getDate();
    const month = monthAbbr[date.getMonth()];
    const year = date.getFullYear();
    const suffixIndex = (day % 10 > 3 || day % 100 - day % 10 === 10) ? 0 : day % 10;
    const suffix = ['th', 'st', 'nd', 'rd'][suffixIndex] || 'th';
    return `${month} ${day}${suffix}, ${year}`;
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  };

  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <tr>
            <TableHeader>Date</TableHeader>
            <TableHeader>Amount</TableHeader>
            <TableHeader>Notes</TableHeader>
            <TableHeader>Actions</TableHeader>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.id}>
              <TableCell>{formatDate(payment.payment_date)}</TableCell>
              <TableCell>{formatCurrency(payment.amount)}</TableCell>
              <TableCell>{payment.notes || 'N/A'}</TableCell>
              <TableCell>
                <RemoveButton onClick={() => removePayment(payment.id)}>
                  Remove
                </RemoveButton>
              </TableCell>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};

export default PaymentsTable;