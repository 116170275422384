import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';

export const useUserOrganizations = () => {
  return useQuery({
    queryKey: ['userOrganizations'],
    queryFn: async () => {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) {
        console.error('Error fetching user:', userError);
        throw userError;
      }
      if (!user) throw new Error('User not authenticated');

      const { data, error } = await supabase
        .from('organization_members')
        .select(`
          organization_id, 
          organizations!fk_organization(
            id,
            name,
            logo_url
          )
        `)
        .eq('user_id', user.id);

      if (error) {
        console.error('Error fetching organizations:', error);
        throw error;
      }
      return data;
    },
    // Update caching options
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 10 * 60 * 1000, // 10 minutes
    // Remove or set to true if you want to refetch on window focus
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
  });
};